import { createFeature, createReducer, on } from '@ngrx/store';
import { getConfigCatFeatureFlagsSuccess } from './config-cat.actions';
import { ConfigCatFlagState } from './config-cat.model';

export const configCatFlagFeatureKey = 'configCatFlagState';

export const initialState: ConfigCatFlagState = {
    configCatFlags: [],
};

export const configCatFeature = createFeature({
    name: configCatFlagFeatureKey,
    reducer: createReducer(
        initialState,
        on(
            getConfigCatFeatureFlagsSuccess,
            (state, action): ConfigCatFlagState => ({
                ...state,
                configCatFlags: action.configCatFlagState.configCatFlags,
            }),
        ),
    ),
});
