import { createFeature, createReducer, on } from '@ngrx/store';
import { DailyBiteState } from '../../models/daily-bite.model';
import { getDailyBitesSuccess } from './daily-bite.actions';

const initialState: DailyBiteState = {
    dailyBites: [],
};

export const dailyBitesFeature = createFeature({
    name: 'dailyBites',
    reducer: createReducer(
        initialState,
        on(
            getDailyBitesSuccess,
            (state, { dailyBites }): DailyBiteState => ({
                ...state,
                dailyBites,
            }),
        ),
    ),
});
