import { IsEnum, IsOptional, IsString } from 'class-validator';
import { ConversationMessageRatingTypeID } from '../../../models/conversation/conversation.model';

export class DomainPatchConversationMessageCommand {
    @IsOptional()
    @IsString()
    conversationId?: string;

    @IsOptional()
    @IsString()
    content?: string;

    @IsOptional()
    @IsString()
    senderId?: string;

    @IsOptional()
    @IsString()
    streamChatMessageId?: string;

    @IsOptional()
    @IsEnum(ConversationMessageRatingTypeID)
    ratingId?: ConversationMessageRatingTypeID;
}
