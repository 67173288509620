import { StateType, StepProgressType } from '@shared/user-domain';

export interface ProgressDtoOld {
    itemId: number;
    timestamp: number;
    stateType: StateType;
    progressType: StepProgressType;
    repeatRank?: number;
    rating?: number;
}
