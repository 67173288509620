<ion-card
    class="generic-card-large {{ backgroundColor }}"
    [ngStyle]="{ 'background-image': backgroundUrl ? 'url(' + backgroundUrl + ')' : '' }"
    data-test="generic-card-large"
>
    <div class="generic-card-large__content-container">
        @if (label) {
            <p
                class="generic-card-large__label xs"
                [ngStyle]="{ color: 'var(--ion-color-' + backgroundColor + '-contrast)' }"
            >
                {{ label }}
            </p>
        }

        <section
            class="generic-card-large__text-container"
            [ngStyle]="{ 'row-gap': !callToActionText || !label ? '16px' : '0px' }"
        >
            <h2
                class="generic-card-large__title ignore-screen-based-font-scaling"
                [ngStyle]="{ color: 'var(--ion-color-' + backgroundColor + '-contrast)' }"
            >
                {{ title }}
            </h2>
            <p
                class="generic-card-large__description"
                [ngStyle]="{ color: 'var(--ion-color-' + backgroundColor + '-contrast)' }"
            >
                {{ description }}
            </p>
        </section>
        @if (callToActionText) {
            <ion-button
                (click)="clicked()"
                class="s ignore-screen-based-font-scaling generic-card-large__button"
                expand="block"
                color="snowy"
                data-test="generic-card-large-btn"
                >{{ callToActionText }}
            </ion-button>
        }
    </div>
    <img class="generic-card-large__image" [src]="imageUrl" [alt]="imageAltText" />
</ion-card>
