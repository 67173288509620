import { Color } from '@shared/utils/typescript';
import { Asset, Entry } from 'contentful';
import { TopicName } from '../../types/topic.types';
import { ContentfulStep } from './contentful-step.model';

export interface ContentfulTopic {
    id: number;
    title: TopicName;
    intro: string;
    image: Asset;
    color: Color[];
    steps: Entry<ContentfulStep>[];
}
