<ion-toolbar class="progress-header page-padding-sides">
    <ion-buttons slot="start">
        @if (showBackButton()) {
            <ion-button
                class="progress-header__button l"
                data-test="progress-header-back-button"
                [disabled]="isDisabled()"
                (click)="onClickBackButton()"
            >
                <app-icon color="white" iconName="arrow-left" sprite="regular" />
            </ion-button>
        }
    </ion-buttons>

    <div
        class="progress-header__progress-bar"
        [class.progress-header__progress-bar--has-back-button]="showBackButton()"
    >
        <ion-progress-bar [value]="progressRatio()" color="sophie" />
    </div>
</ion-toolbar>
