import { InternalFaqDto } from '../../dtos/internal-api';
import { ContentfulRichTextContentMock } from '../contentful/contentful-rich-text-content.mock';

export class InternalFaqDtoMock {
    private defaultValue: InternalFaqDto = {
        id: 1,
        rank: 1,
        title: 'Faq Title',
        content: new ContentfulRichTextContentMock().value,
        mixpanelId: 'mixpanel-id',
    };

    constructor(overrides?: Partial<InternalFaqDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalFaqDto {
        return this.defaultValue;
    }
}
