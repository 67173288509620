const householdsPath = 'v2/households';
const usersPath = 'v2/users';
const dogsPath = 'v2/dogs';
const userAppAccessGrantedPath = 'v2/users/user-app-access-granted';
const partnerPath = 'v2/partner';
const trainerPath = 'v2/trainer';
const agentsPath = 'v2/agents';
const chatConversationsPath = 'v2/chat/conversations';
const chatMessagesPath = 'v2/chat/messages';
const chatTagsPath = 'v2/chat-tags';
const pinnedTopicsPath = 'v2/pinned-topics';
const courseProgressPath = 'v2/course-progress';
const rescheduledStepPath = 'v2/rescheduled-step';
const stepProgressPath = 'v2/step-progress';
const stepProgressTypePath = 'v2/step-progress-type';
const todayProgressPath = 'v2/today-progress';
const wooCommerceUsersPath = 'v2/woo-commerce-users';

const householdsCollection = `/api/${householdsPath}`;
const usersCollection = `/api/${usersPath}`;
const dogsCollection = `/api/${dogsPath}`;
const userAppAccessGrantedCollection = `/api/${userAppAccessGrantedPath}`;
const partnerCollection = `/api/${partnerPath}`;
const trainerCollection = `/api/${trainerPath}`;
const agentsCollection = `/api/${agentsPath}`;
const chatConversationsCollection = `/api/${chatConversationsPath}`;
const chatMessagesCollection = `/api/${chatMessagesPath}`;
const chatTagsCollection = `/api/${chatTagsPath}`;
const pinnedTopicsCollection = `/api/${pinnedTopicsPath}`;
const courseProgressCollection = `/api/${courseProgressPath}`;
const rescheduledStepCollection = `/api/${rescheduledStepPath}`;
const stepProgressCollection = `/api/${stepProgressPath}`;
const stepProgressTypeCollection = `/api/${stepProgressTypePath}`;
const todayProgressCollection = `/api/${todayProgressPath}`;
const wooCommerceUsersCollection = `/api/${wooCommerceUsersPath}`;

export const v2UsersPaths = {
    usersPath,
    userAppAccessGrantedPath,
    partnerPath,
    trainerPath,
    agentsPath,
    chatTagsPath,
    chatConversationsPath,
    chatMessagesPath,
    pinnedTopicsPath,
    courseProgressPath,
    rescheduledStepPath,
    stepProgressPath,
    stepProgressTypePath,
    todayProgressPath,
    wooCommerceUsersPath,
    householdsPath,
    dogsPath,
};

export const v2UsersEndpoints = {
    usersCollection,
    userAppAccessGrantedCollection,
    partnerCollection,
    trainerCollection,
    agentsCollection,
    chatTagsCollection,
    chatConversationsCollection,
    chatMessagesCollection,
    pinnedTopicsCollection,
    courseProgressCollection,
    rescheduledStepCollection,
    stepProgressCollection,
    stepProgressTypeCollection,
    todayProgressCollection,
    wooCommerceUsersCollection,
    householdsCollection,
    dogsCollection,
};
