import { InjectionToken } from '@angular/core';

export interface FirebaseConfig {
    useFirebaseAuthMock: boolean;
    storageBucket: string;
    apiKey: string;
    messagingSenderId: string;
    appId: string;
    projectId: string;
    authDomain: string;
    databaseURL?: string;
}

export const AUTHENTICATION_CONFIG = new InjectionToken<FirebaseConfig>('AUTHENTICATION_CONFIG');
