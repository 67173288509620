import { GetContentBaseParams, InternalDevelopmentalWindowDto } from '@shared/content-api-interface';
import { createAction, props } from '@ngrx/store';

export const getDevelopmentalWindows = createAction(
    '[Content] Get Developmental Windows',
    props<{ params: GetContentBaseParams }>(),
);

export const getDevelopmentalWindowsSuccess = createAction(
    '[Content] Get Developmental Windows Success',
    props<{ developmentalWindows: InternalDevelopmentalWindowDto[] }>(),
);

export const getDevelopmentalWindowsFailure = createAction(
    '[Content] Get Developmental Windows Failure',
    props<{
        error: Error;
    }>(),
);
