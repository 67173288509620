import { ContentfulTagType } from '@shared/constants';
import { TopicName } from '../../types/topic.types';

export interface InternalCourseStepDto {
    id: number;
    title: string;
    topic: TopicName;
    content: {
        id: number;
        type: ContentfulTagType;
    };
}
