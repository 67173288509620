import { Gender } from '@shared/user-domain';
import { IsBoolean, IsInt, IsOptional, IsString, Min } from 'class-validator';

export class InternalPostAccountFromWebsiteCommand {
    @IsString()
    puppyName!: string;

    @IsString()
    puppyDateOfBirth!: string;

    @IsBoolean()
    puppyIsHome!: boolean;

    @IsString()
    puppyArrives!: string;

    @IsString()
    puppyGender!: Gender;

    @IsString()
    puppyBreed!: string;

    @IsBoolean()
    puppyRescued!: boolean;

    @IsString()
    email!: string;

    @IsString()
    password!: string;

    @IsString()
    dateOfOnboarding!: string;

    @IsString()
    ownerName!: string;

    @IsBoolean()
    notifications!: boolean;

    @IsString()
    countryCode!: string;

    @IsBoolean()
    purinaMarketingAccepted!: boolean;

    @IsInt()
    @Min(1)
    wooCommerceUserId!: number;

    @IsBoolean()
    @IsOptional()
    isApproximateDateOfBirth?: boolean;
}
