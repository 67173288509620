import { Color } from '@shared/utils/typescript';
import { InternalTopicDto } from '../../dtos/internal-api';
import { TopicName } from '../../types/topic.types';

export class InternalTopicDtoMock {
    private defaultValue: InternalTopicDto = {
        entityId: '1',
        id: 1,
        title: TopicName.SIT,
        intro: 'Intro 1',
        imageUrl: 'image-url-1',
        color: Color.Lola,
        pinned: false,
        stepIds: ['123'],
    };

    constructor(overrides?: Partial<InternalTopicDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalTopicDto {
        return this.defaultValue;
    }
}
