<section class="radio-with-logic__radio-container">
    @for (button of buttons(); track button.value) {
        <div
            class="radio-with-logic__button-container"
            [ngStyle]="{ top: button.value === selected?.value ? 0 : $index * 70 + 'px' }"
        >
            @if (selected === undefined || button.value === selected.value) {
                <ion-button
                    class="radio-with-logic__button"
                    [ngClass]="{
                        'radio-with-logic__button-onboarding': isNewOnboarding(),
                        'radio-with-logic__button-legacy': !isNewOnboarding(),
                    }"
                    [ngStyle]="{
                        '--background': button.value === selected?.value ? 'var(--ion-color-max)' : '',
                        '--border-color': button.value === selected?.value ? 'var(--ion-color-max)' : '',
                        '--color': button.value === selected?.value ? 'var(--ion-color-max-contrast)' : '',
                    }"
                    expand="block"
                    fill="solid"
                    [disabled]="isDisabled()"
                    [attr.data-test]="'radio-button-' + button.value"
                    (click)="clickButton(button)"
                >
                    {{ button.value }}
                </ion-button>
            }
        </div>
    }
</section>
