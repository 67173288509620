const accountPath = 'user/account';
const accountsPath = 'user/accounts';
const householdPath = 'user/household';
const adminsPath = 'admins';
const azureSecretsPath = 'azure/secrets';
const chatPath = 'chat';
const iterablePath = 'crm/iterable';
const progressPath = 'user/progress';
const rescheduledStepPath = 'user/rescheduled-step';
const stepProgressPath = 'user/step-progress';
const subscriptionsPath = 'user/subscriptions';
const geoLocationPath = 'user/geolocation';
const userAppAccessGrantedPath = 'user/user-app-access-granted';
const agentsPath = 'chat/agents';
const chatTagsPath = 'chat/tags';
const foodCalculatorPath = 'food-calculator';
const pinnedTopicsPath = 'user/pinned-topics';
const courseProgressPath = 'user/course-progress';
const saUserPath = 'separation-anxiety/user';

const accountCollection = `/api/${accountPath}`;
const accountsCollection = `/api/${accountsPath}`;
const householdCollection = `/api/${householdPath}`;
const adminsCollection = `/api/${adminsPath}`;
const azureSecretsCollection = `/api/${azureSecretsPath}`;
const chatCollection = `/api/${chatPath}`;
const iterableCollection = `/api/${iterablePath}`;
const progressCollection = `/api/${progressPath}`;
const rescheduledStepCollection = `/api/${rescheduledStepPath}`;
const stepProgressCollection = `/api/${stepProgressPath}`;
const subscriptionsCollection = `/api/${subscriptionsPath}`;
const geoLocationCollection = `/api/${geoLocationPath}`;
const agentsCollection = `/api/${agentsPath}`;
const chatTagsCollection = `/api/${chatTagsPath}`;
const foodCalculatorCollection = `/api/${foodCalculatorPath}`;
const pinnedTopicsCollection = `/api/${pinnedTopicsPath}`;
const courseProgressCollection = `/api/${courseProgressPath}`;
const saUserCollection = `/api/${saUserPath}`;
const userAppAccessGrantedCollection = `/api/${userAppAccessGrantedPath}`;

export const internalPaths = {
    accountPath,
    accountsPath,
    adminsPath,
    azureSecretsPath,
    chatPath,
    iterablePath,
    progressPath,
    rescheduledStepPath,
    stepProgressPath,
    subscriptionsPath,
    geoLocationPath,
    agentsPath,
    chatTagsPath,
    foodCalculatorPath,
    pinnedTopicsPath,
    courseProgressPath,
    saUserPath,
    userAppAccessGrantedPath,
    householdPath,
};

export const internalEndpoints = {
    accountCollection,
    accountsCollection,
    adminsCollection,
    azureSecretsCollection,
    chatCollection,
    iterableCollection,
    progressCollection,
    rescheduledStepCollection,
    stepProgressCollection,
    subscriptionsCollection,
    geoLocationCollection,
    agentsCollection,
    chatTagsCollection,
    foodCalculatorCollection,
    pinnedTopicsCollection,
    courseProgressCollection,
    saUserCollection,
    userAppAccessGrantedCollection,
    householdCollection,
};
