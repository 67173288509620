import { NgModule } from '@angular/core';
import { Mixpanel, MixpanelPeople } from '@awesome-cordova-plugins/mixpanel/ngx';
import { provideEffects } from '@ngrx/effects';
import { MixpanelPeopleServiceProvider } from './mixpanel-people-service.provider';
import { MixpanelServiceProvider } from './mixpanel-service.provider';
import { AnalyticsEffects } from './store/analytics.effects';

@NgModule({
    providers: [
        provideEffects([AnalyticsEffects]),
        Mixpanel,
        MixpanelPeople,
        MixpanelServiceProvider,
        MixpanelPeopleServiceProvider,
    ],
})
export class ZigzagFrontendDataAccessAnalyticsModule {}
