import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { StreamChatModule } from 'stream-chat-angular';
import { ChatApiEffects } from './chat-api/store/chat-api.effects';
import { CHAT_CONFIG } from './injection-tokens';
import { chatServiceFactory } from './stream-chat/services/chat-service.factory';
import { ChatService } from './stream-chat/services/chat.service';
import { ChatEffects } from './stream-chat/store/chat.effects';
import { chatFeature } from './stream-chat/store/chat.reducer';

@NgModule({
    imports: [StreamChatModule],
    providers: [
        provideState(chatFeature),
        provideEffects([ChatEffects, ChatApiEffects]),
        {
            provide: ChatService,
            useFactory: chatServiceFactory,
            deps: [CHAT_CONFIG],
        },
    ],
})
export class ZigzagFrontendDataAccessChatModule {}
