import { IsBoolean, IsNumber, IsString } from 'class-validator';

export class InternalPostUserAppAccessGrantedCommand {
    @IsString()
    accessTypeId!: string;

    @IsNumber()
    accessGrantedTimestamp!: number;

    @IsNumber()
    expiryTimeStamp!: number;

    @IsBoolean()
    openedAppAfterExpiry!: boolean;

    @IsNumber()
    numberOfDaysAccessGranted!: number;
}
