import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import { ChatChannelType } from '@shared/constants';
import { map, Observable } from 'rxjs';
import { PartialMessageUpdate } from 'stream-chat';

@Injectable({ providedIn: 'root' })
export class ChatApiService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);
    // todo needs testing
    public getUserToken(): Observable<string> {
        return this.http
            .get<{ token: string }>(`${this.environment.internalApiUrl}/api/stream-chat/token`, httpOptions)
            .pipe(map((tokenObject) => tokenObject.token));
    }

    public updateMessage(
        streamChatMessageId: string,
        streamChatUserId: string,
        payload: PartialMessageUpdate,
    ): Observable<void> {
        return this.http.patch<void>(
            `${this.environment.internalApiUrl}/api/stream-chat/message/${streamChatMessageId}/user/${streamChatUserId}`,
            payload,
            httpOptions,
        );
    }

    public getLatestMessage(channelType?: ChatChannelType): Observable<Date> {
        let params = new HttpParams();
        if (channelType) {
            params = params.set('channelType', channelType);
        }
        return this.http.get<Date>(`${this.environment.internalApiUrl}/api/stream-chat/last-message-date`, {
            ...httpOptions,
            params,
        });
    }
}
