import { Component, inject } from '@angular/core';
import { IonContent, IonRouterOutlet, ViewDidEnter } from '@ionic/angular/standalone';

@Component({
    standalone: true,
    selector: 'app-loading-screen',
    templateUrl: './loading-screen.component.html',
    styleUrls: ['./loading-screen.component.scss'],
    imports: [IonContent],
})
export class LoadingScreenComponent implements ViewDidEnter {
    private readonly routerOutlet = inject(IonRouterOutlet);

    ionViewDidEnter(): void {
        // Stop navigation with swipe back and back button
        this.routerOutlet.swipeGesture = false;
        window.onpopstate = () => {
            history.go(0);
        };
    }
}
