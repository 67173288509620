import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ContentService } from '../../content.service';
import {
    getDevelopmentalWindows,
    getDevelopmentalWindowsFailure,
    getDevelopmentalWindowsSuccess,
} from './developmental-window.actions';

@Injectable()
export class DevelopmentalWindowEffects {
    private readonly actions$ = inject(Actions);
    private readonly contentService = inject(ContentService);
    getDevelopmentalWindows$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getDevelopmentalWindows),
            switchMap(({ params }) => {
                return this.contentService.getDevelopmentalWindows(params).pipe(
                    map((developmentalWindows) => {
                        return getDevelopmentalWindowsSuccess({ developmentalWindows });
                    }),
                    catchError((error: Error) => {
                        console.error(error);
                        return of(getDevelopmentalWindowsFailure({ error }));
                    }),
                );
            }),
        );
    });
}
