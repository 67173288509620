import { DomainConversationMessageDto } from './domain-conversation-message.dto';

export interface DomainConversationDto {
    id: string;
    channelId: string;
    userId: string;
    conversationTypeId: string;
    creationDateTime: Date;
    messages: DomainConversationMessageDto[];
}
