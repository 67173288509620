import { DomainUserAppAccessGrantedDto } from '../../../dtos/domain-api/user/user/domain-user-app-access-granted.dto';

export class DomainUserAppAccessGrantedDtoMock {
    private defaultValue: DomainUserAppAccessGrantedDto = {
        id: 'id',
        userId: 'userId',
        accessTypeId: 'accessType',
        expiryTimestamp: 2024,
        openedAppAfterExpiry: false,
        numberOfDaysAccessGranted: 3,
    };

    constructor(overrides?: Partial<DomainUserAppAccessGrantedDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainUserAppAccessGrantedDto {
        return this.defaultValue;
    }
}
