import { InternalChatTileContentDto } from './internal-chat-tile-content.dto';
import { Color } from '@shared/utils/typescript';

export interface InternalChatTileTopicDto {
    id: string;
    title: string;
    image: string;
    colour: Color;
    chatTileContent: string[] | InternalChatTileContentDto[];
    ageBracket: string;
    isPupAtHome: boolean;
    isPupRescue: boolean;
    isHomeRecommendedContent: boolean;
}
