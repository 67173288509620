import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import {
    contentCollections,
    GetChatTileTopicsParams,
    GetContentBaseParams,
    GetCoursesParams,
    GetDailyBitesParams,
    GetStepsParams,
    InternalBreedGroupDto,
    InternalChatTileTopicDto,
    InternalCourseDto,
    InternalDailyBiteDto,
    InternalDevelopmentalWindowDto,
    InternalFaqDto,
    InternalPageTodayDto,
    InternalStepDto,
    InternalTopicDto,
} from '@shared/content-api-interface';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ContentService {
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);
    private readonly http = inject(HttpClient);

    getBreedGroups(getContentParams: GetContentBaseParams): Observable<InternalBreedGroupDto[]> {
        const params = new HttpParams().set('locale', getContentParams.locale);

        return this.http.get<InternalBreedGroupDto[]>(
            `${this.environment.internalApiUrl}${contentCollections.contentBreedGroupsCollection}`,
            {
                ...httpOptions,
                params,
            },
        );
    }

    getCourses(getContentParams: GetCoursesParams): Observable<InternalCourseDto[]> {
        const params = new HttpParams()
            .set('locale', getContentParams.locale)
            .set('breedId', getContentParams.breedId)
            .set('ageInWeeks', getContentParams.ageInWeeks)
            .set('hasArrived', getContentParams.hasArrived)
            .set('hasArrivedOnDateOfOnboarding', getContentParams.hasArrivedOnDateOfOnboarding)
            .set('isRescue', getContentParams.isRescue);

        return this.http.get<InternalCourseDto[]>(
            `${this.environment.internalApiUrl}${contentCollections.contentCoursesCollection}`,
            {
                ...httpOptions,
                params,
            },
        );
    }

    getDailyBites(getContentParams: GetDailyBitesParams): Observable<InternalDailyBiteDto[]> {
        const params = new HttpParams().set('locale', getContentParams.locale).set('breedId', getContentParams.breedId);

        return this.http.get<InternalDailyBiteDto[]>(
            `${this.environment.internalApiUrl}${contentCollections.contentDailyBitesCollection}`,
            {
                ...httpOptions,
                params,
            },
        );
    }

    getDevelopmentalWindows(getContentParams: GetContentBaseParams): Observable<InternalDevelopmentalWindowDto[]> {
        const params = new HttpParams().set('locale', getContentParams.locale);

        return this.http.get<InternalDevelopmentalWindowDto[]>(
            `${this.environment.internalApiUrl}${contentCollections.contentDevelopmentalWindowsCollection}`,
            {
                ...httpOptions,
                params,
            },
        );
    }

    getFaqs(getContentParams: GetContentBaseParams): Observable<InternalFaqDto[]> {
        const params = new HttpParams().set('locale', getContentParams.locale);

        return this.http.get<InternalFaqDto[]>(
            `${this.environment.internalApiUrl}${contentCollections.contentFaqsCollection}`,
            {
                ...httpOptions,
                params,
            },
        );
    }

    getPageToday(getContentParams: GetContentBaseParams): Observable<InternalPageTodayDto> {
        const params = new HttpParams().set('locale', getContentParams.locale);

        return this.http.get<InternalPageTodayDto>(
            `${this.environment.internalApiUrl}${contentCollections.contentPageTodayCollection}`,
            {
                ...httpOptions,
                params,
            },
        );
    }

    getSteps(getContentParams: GetStepsParams): Observable<InternalStepDto[]> {
        let params = new HttpParams()
            .set('locale', getContentParams.locale)
            .set('breedId', getContentParams.breedId)
            .set('ageInWeeks', getContentParams.ageInWeeks);

        if (getContentParams.isRescue !== undefined) {
            params = params.set('isRescue', getContentParams.isRescue);
        }

        return this.http.get<InternalStepDto[]>(
            `${this.environment.internalApiUrl}${contentCollections.contentStepsCollection}`,
            {
                ...httpOptions,
                params,
            },
        );
    }

    getTopics(getContentParams: GetContentBaseParams): Observable<InternalTopicDto[]> {
        const params = new HttpParams().set('locale', getContentParams.locale);

        return this.http.get<InternalTopicDto[]>(
            `${this.environment.internalApiUrl}${contentCollections.contentTopicsCollection}`,
            {
                ...httpOptions,
                params,
            },
        );
    }

    getChatTileTopics(
        getContentParams: GetChatTileTopicsParams,
        isHomeRecommended: boolean,
    ): Observable<InternalChatTileTopicDto[]> {
        const params = new HttpParams()
            .set('locale', getContentParams.locale)
            .set('ageInWeeks', getContentParams.ageInWeeks)
            .set('isRescue', getContentParams.isRescue ?? false)
            .set('hasArrived', getContentParams.hasArrived)
            .set('isHomeRecommended', isHomeRecommended);

        return this.http.get<InternalChatTileTopicDto[]>(
            `${this.environment.internalApiUrl}${contentCollections.contentChatTileTopicsCollection}`,
            {
                ...httpOptions,
                params,
            },
        );
    }
}
