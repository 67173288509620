import { Color } from '@shared/utils/typescript';
import { Asset, Entry, RichTextContent } from 'contentful';
import { ContentfulTopic } from './contentful-topic.model';

export interface ContentfulDevelopmentalWindow {
    title: string;
    color: Color;
    image: Asset;
    linkedTopics: Entry<ContentfulTopic>[];
    textMain: RichTextContent;
    textImportance: RichTextContent;
    textProgramme: RichTextContent;
    textCourses: RichTextContent;
    textNextCourse: RichTextContent;
    startAtDays: number;
    startAtText: string;
    endAtDays: number;
}
