import { DomainDogDto } from '../../../dtos/domain-api/user/dog/domain-dog.dto';
import { Gender } from '@shared/user-domain';

export class DomainDogDtoMock {
    private defaultValue: DomainDogDto = {
        breedId: '',
        dateOfArrival: '',
        dateOfBirth: '',
        dateOfOnboarding: '',
        gender: Gender.MALE,
        hasArrived: true,
        isApproximateDateOfBirth: false,
        isRescue: false,
        name: 'Bella',
        id: 'uuid-1234',
    };

    constructor(overrides?: Partial<DomainDogDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainDogDto {
        return this.defaultValue;
    }
}
