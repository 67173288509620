import { Pipe, PipeTransform } from '@angular/core';
import { getDayName } from '@shared/utils/typescript';
import { addDays } from 'date-fns';

@Pipe({
    name: 'weekday',
    standalone: true,
})
export class WeekdayPipe implements PipeTransform {
    transform(value: Date, index: number): string {
        const day = addDays(value, index);
        return getDayName(day, 'en-GB');
    }
}
