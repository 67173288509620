import { Color } from '@shared/utils/typescript';
import { RichTextContent } from 'contentful';
import { Topic } from './topic.model';

export interface DevelopmentalWindowState {
    developmentalWindows: DevelopmentalWindow[];
}

export interface DevelopmentalWindow {
    title: string;
    image: string;
    linkedTopics: Topic[];
    startAtDays: number;
    endAtDays: number;
    startAtText: string;
    color: Color;
    textMain: RichTextContent;
    textImportance?: RichTextContent;
    textProgramme?: RichTextContent;
    textCourses?: RichTextContent;
    textNextCourse?: RichTextContent;
}
