<ion-header>
    <ion-toolbar>
        @if (isMenuOpen) {
            <button class="zz-button menu-button-container" (click)="toggleMenu.emit(false)" slot="start">
                <i class="fa-xl fa-solid fa-x"></i>
            </button>
        } @else {
            <button class="zz-button menu-button-container" (click)="toggleMenu.emit(true)" slot="start">
                <i class="fa-xl fa-solid fa-bars"></i>
            </button>
        }
        <h3 data-test="portal-header-title" class="agent-header-title ion-text-center">Zigzag Expert Support</h3>
        <ion-button
            class="agent-header-logout-button"
            color="max"
            expand="block"
            data-test="logout-btn"
            slot="end"
            (click)="logoutAgent()"
            >Logout
        </ion-button>
    </ion-toolbar>
</ion-header>
