import { createSelector } from '@ngrx/store';
import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';

export const selectRouterState = (state: any): RouterReducerState => state.router;

export const { selectQueryParam, selectQueryParams, selectRouteParams, selectUrl, selectCurrentRoute } =
    getRouterSelectors(selectRouterState);

export const selectUrlLastChild = createSelector(selectUrl, (url) => {
    return url?.split('/')?.pop()?.split('?')?.at(0);
});

export const selectUrlWithoutQueryParams = createSelector(selectUrl, (url) => {
    return url?.split('?')?.shift();
});
