import { GetContentBaseParams, InternalTopicDto } from '@shared/content-api-interface';
import { createAction, props } from '@ngrx/store';

export const getTopics = createAction('[Content] Get Topics', props<{ params: GetContentBaseParams }>());

export const getTopicsSuccess = createAction('[Content] Get Topics Success', props<{ topics: InternalTopicDto[] }>());

export const getTopicsFailure = createAction(
    '[Content] Get Topics Failure',
    props<{
        error: Error;
    }>(),
);
