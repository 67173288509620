import { NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { IonButton, IonCard } from '@ionic/angular/standalone';

@Component({
    standalone: true,
    selector: 'app-generic-card-large',
    templateUrl: './generic-card-large.component.html',
    styleUrls: ['./generic-card-large.component.scss'],
    imports: [NgStyle, IonCard, IonButton],
})
export class GenericCardLargeComponent {
    @Input({ required: true }) title!: string;
    @Input({ required: true }) description!: string;
    @Input({ required: true }) backgroundColor!: Color;
    @Input({ required: true }) imageUrl!: string;
    @Input({ required: true }) imageAltText!: string;

    @Input() backgroundUrl: string | undefined;
    @Input() callToActionText: string | undefined;
    @Input() label: string | undefined;

    @Output() callToActionClicked = new EventEmitter<void>();

    public clicked() {
        this.callToActionClicked.emit();
    }
}
