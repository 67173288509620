import { InternalPageTodayDto } from '../../dtos/internal-api/internal-page-today.dto';

export class InternalPageTodayDtoMock {
    private defaultValue: InternalPageTodayDto = {
        complimentsFirst: ['compliment 1', 'compliment 2'],
        complimentsSecond: ['compliment 3', 'compliment 4'],
        complimentsOthers: ['compliment 5', 'compliment 6'],
        startedOnTrackMessage: 'started on track message',
        startedNotAtHomeMessage: 'started not at home message',
        startedNotOnTrackMessage: 'started not on track message',
        startedOver12WeeksMessage: 'started over 12 weeks message',
        startedOver6MonthsMessage: 'started over 6 months message',
        startedCloseToJuvenile: 'started close to juvenile message',
        newOnTrackMessage: 'new on track message',
        newNotAtHomeMessage: 'new not at home message',
        newOver12WeeksMessage: 'new over 12 weeks message',
    };

    constructor(overrides?: Partial<InternalPageTodayDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalPageTodayDto {
        return this.defaultValue;
    }
}
