@for (option of multiSelectOptions; track option.title; let i = $index) {
    <button
        [class]="'multi-select-button as-div ' + option.backgroundColor"
        [class.multi-select-button--selected]="option.selected"
        type="button"
        [id]="option.value"
        (click)="selectSpecialNeed(i)"
    >
        <p
            class="multi-select-button__text"
            [ngStyle]="{ color: 'var(--ion-color-' + option.backgroundColor + '-contrast)' }"
        >
            {{ option.title }}
        </p>

        <img class="multi-select-button__image" [src]="option.image" [alt]="option.alt" />

        @if (multiSelectOptions[i].selected) {
            <ion-icon class="multi-select-button__selected-icon" [name]="'checkmark-sharp'" />
        }
    </button>
}
