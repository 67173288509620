import { RescheduledStepType } from '@shared/user-domain';

export interface DomainRescheduledStepDto {
    id: string;
    userId: string;
    stepId: string;
    contentId: number;
    type: RescheduledStepType;
    isActive: boolean;
    timestamp: number;
}
