import { GetContentBaseParams, InternalFaqDto } from '@shared/content-api-interface';
import { createAction, props } from '@ngrx/store';

export const getFaqs = createAction('[Content] Get Faqs', props<{ params: GetContentBaseParams }>());

export const getFaqsSuccess = createAction('[Content] Get Faqs Success', props<{ faqs: InternalFaqDto[] }>());

export const getFaqsFailure = createAction(
    '[Content] Get Faqs Failure',
    props<{
        error: Error;
    }>(),
);
