import { Asset, Entry, RichTextContent } from 'contentful';
import { TopicName } from '../../types/topic.types';
import { ContentfulAuthor } from './contentful-author.model';
import { ContentfulBreedGroup } from './contentful-breed-group.model';
import { ContentfulBreed } from './contentful-breed.model';
import { ContentfulDynamicContentBlockEntry } from './contentful-dynamic-content-block-entry.model';
import { ContentfulTopic } from './contentful-topic.model';

export interface ContentfulLesson {
    id: number;
    title: string;
    topic: TopicName;
    topicRef: Entry<ContentfulTopic>;
    duration: number;
    videoId: string;
    introduction: string;
    introductionDynamicContent?: Entry<ContentfulDynamicContentBlockEntry>[];
    beforeYouStart: string;
    steps: string;
    stepsDynamicContent?: Entry<ContentfulDynamicContentBlockEntry>[];
    tips: string;
    tipsDynamicContent?: Entry<ContentfulDynamicContentBlockEntry>[];
    lowRatingAnimation: Asset;
    highRatingAnimation: Asset;
    lowRatingText: RichTextContent;
    highRatingText: RichTextContent;
    location: string;
    whatsNeededToys: string[];
    whatsNeededTreats: string[];
    whatsNeededEquipmentaccessories: string[];
    whatsNeededOther: string[];
    audio: Asset | undefined;
    breedGroup: Entry<ContentfulBreedGroup>[];
    breed: Entry<ContentfulBreed>[];
    lessonAuthor: Entry<ContentfulAuthor> | undefined;
}
