import { BLOCKS } from '@contentful/rich-text-types';
import { Entry, RichTextContent, RichTextData, RichTextDataTarget } from 'contentful';
import { ContentfulDynamicContentBlockEntry } from './contentful-dynamic-content-block-entry.model';

// modeled off of how the response comes from contentful when one of our Dynamic Content Blocks is found in RichTextContent
export interface RichContentWithEmbeddedBlock extends RichTextContent {
    nodeType: BLOCKS.EMBEDDED_ENTRY;
    data: RichTextData & {
        target: RichTextDataTarget & Entry<ContentfulDynamicContentBlockEntry>;
    };
}
