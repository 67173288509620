export interface BreedGroupState {
    breedGroups: BreedGroup[];
}

export interface ContentBreed {
    id: string;
    name: string;
}

export interface BreedInfo extends ContentBreed {
    breedGroup: Omit<BreedGroup, 'breeds'>;
}

export interface BreedGroup {
    id: string;
    name: string;
    breeds: ContentBreed[];
}
