const userPath = 'user';
const progressPath = 'progress';
const courseProgressPath = 'course-progress';
const todayProgressPath = 'today-progress';
const rescheduledStepPath = 'rescheduled-step';
const paymentPath = 'payment';
const chatPath = 'chat';
const agentPath = 'agent';

export const userPaths = {
    agentPath,
    userPath,
    progressPath,
    courseProgressPath,
    todayProgressPath,
    rescheduledStepPath,
    paymentPath,
    chatPath,
};
