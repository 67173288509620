import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GeoLocationState, geoLocationFeatureKey } from './geo-location.reducer';

export const selectGeoLocationState = createFeatureSelector<GeoLocationState>(geoLocationFeatureKey);
export const selectGeoLocationRequest = createSelector(selectGeoLocationState, (state) => state.geoLocation);

export const selectGeoLocation = createSelector(
    selectGeoLocationRequest,
    (geoLocationRequest) => geoLocationRequest.data,
);

export const selectCurrentIpCountryCode = createSelector(selectGeoLocation, (geoLocation) => geoLocation?.countryCode);
export const selectCurrentIp = createSelector(selectGeoLocation, (geoLocation) => geoLocation?.ip);

export const selectCurrentIpLocationLoading = createSelector(
    selectGeoLocationRequest,
    (geoLocationRequest) => geoLocationRequest.loading,
);
