import { Component, Input } from '@angular/core';
import { IonChip } from '@ionic/angular/standalone';

@Component({
    standalone: true,
    selector: 'app-author-chip',
    templateUrl: './author-chip.component.html',
    styleUrl: './author-chip.component.scss',
    imports: [IonChip],
})
export class AuthorChipComponent {
    @Input({ required: true }) authorImageUrl!: string;
    @Input({ required: true }) author!: string;
}
