import { ChatTileContent } from '../models/chat-tile-content.model';

export class ChatTileContentMock {
    private defaultValue: ChatTileContent = {
        id: '',
        shortQuestion: '',
        longQuestion: '',
    };

    constructor(overrides?: Partial<ChatTileContent>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): ChatTileContent {
        return this.defaultValue;
    }
}
