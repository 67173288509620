import { Pipe, PipeTransform } from '@angular/core';
import { Breed } from '@shared/user-domain';

@Pipe({
    name: 'breed',
    standalone: true,
})
export class BreedPipe implements PipeTransform {
    transform(value: keyof typeof Breed): string {
        return Breed[value];
    }
}
