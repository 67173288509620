import { InternalDailyBiteDto } from '../../dtos/internal-api';
import { ContentfulRichTextContentMock } from '../contentful/contentful-rich-text-content.mock';

export class InternalDailyBiteDtoMock {
    private defaultValue: InternalDailyBiteDto = {
        id: 1,
        title: 'Daily Bite Title',
        imageUrl: 'https://www.example.com/image.jpg',
        rank: 1,
        author: 'Daily Bite Author',
        tags: ['tag1', 'tag2'],
        content: 'Daily Bite Content',
        richContent: new ContentfulRichTextContentMock().value,
        dailyBiteAuthor: {
            authorName: 'Author name',
            authorImageUrl: 'Author image URL',
            authorBio: new ContentfulRichTextContentMock().value,
            authorCertifications: ['Author certification'],
            authorOrganisation: 'Author organisation',
        },
    };

    constructor(overrides?: Partial<InternalDailyBiteDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalDailyBiteDto {
        return this.defaultValue;
    }
}
