import { Injectable } from '@angular/core';
import { AdjustAttribution } from '@awesome-cordova-plugins/adjust';
import { AnalyticsProfile } from './store/analytics.model';

@Injectable()
export abstract class MixpanelPeopleService {
    public abstract setProfile(profile: AnalyticsProfile, profileId: string): Promise<void>;

    public abstract setAttributionData(attributionData: AdjustAttribution): void;
}
