import { IsBoolean, IsString } from 'class-validator';

export class DomainTrainerCommand {
    @IsString()
    firstName!: string;

    @IsString()
    lastName!: string;

    @IsString()
    companyName!: string;

    @IsString()
    email!: string;

    @IsString()
    postalAddress!: string;

    @IsString()
    websiteUrl!: string;

    @IsString()
    countryCode!: string;

    @IsBoolean()
    isPPGTrainer!: boolean;
}
