import { RescheduledStepType } from '@shared/user-domain';
import { IsBoolean, IsEnum, IsNotEmpty, IsNumber, IsOptional, IsPositive, IsString } from 'class-validator';

export class DomainPostRescheduledStepCommand {
    @IsString()
    @IsNotEmpty()
    userId!: string;

    @IsString()
    @IsNotEmpty()
    stepId!: string;

    @IsNumber()
    @IsPositive()
    contentId!: number;

    @IsEnum(RescheduledStepType)
    type!: RescheduledStepType;

    @IsBoolean()
    isActive!: boolean;

    @IsNumber()
    @IsOptional()
    timestamp?: number;
}
