import { createFeature, createReducer, on } from '@ngrx/store';
import { TopicState } from '../../models/topic.model';
import { getTopicsSuccess } from './topic.actions';

export const initialState: TopicState = {
    topics: [],
};

export const topicsFeature = createFeature({
    name: 'topics',
    reducer: createReducer(
        initialState,
        on(
            getTopicsSuccess,
            (state, { topics }): TopicState => ({
                ...state,
                topics,
            }),
        ),
    ),
});
