import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ContentService } from '../../content.service';
import { getDailyBites, getDailyBitesFailure, getDailyBitesSuccess } from './daily-bite.actions';

@Injectable()
export class DailyBitesEffects {
    private readonly actions$ = inject(Actions);
    private readonly contentService = inject(ContentService);
    getDailyBites$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getDailyBites),
            switchMap(({ params }) => {
                return this.contentService.getDailyBites(params).pipe(
                    map((dailyBites) => {
                        return getDailyBitesSuccess({ dailyBites });
                    }),
                    catchError((error: Error) => {
                        console.error(error);
                        return of(getDailyBitesFailure({ error }));
                    }),
                );
            }),
        );
    });
}
