import { createFeature, createReducer, on } from '@ngrx/store';
import { StepState } from '../../models/step.model';
import { getSteps, getStepsFailure, getStepsSuccess } from './step.actions';
import { LoadingState } from '@frontend/data-access/shared-models';

export const initialState: StepState = {
    steps: [],
    loading: LoadingState.INIT,
};

export const stepsFeature = createFeature({
    name: 'steps',
    reducer: createReducer(
        initialState,
        on(
            getSteps,
            (state): StepState => ({
                ...state,
                loading: LoadingState.LOADING,
            }),
        ),
        on(
            getStepsFailure,
            (state): StepState => ({
                ...state,
                loading: LoadingState.LOADED,
            }),
        ),

        on(
            getStepsSuccess,
            (state, { steps }): StepState => ({
                ...state,
                steps,
                loading: LoadingState.LOADED,
            }),
        ),
    ),
});
