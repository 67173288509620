import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { RouterEffects } from './store/router.effects';
import { provideState } from '@ngrx/store';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';

@NgModule({
    providers: [provideEffects([RouterEffects]), provideState('router', routerReducer), provideRouterStore()],
})
export class ZigzagFrontendDataAccessRouterModule {}
