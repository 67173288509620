import { Order } from '../database/pagination.model';

export interface DomainGetAllStepProgressFindOptionsWhere {
    userId?: string;
    stepId?: string;
    contentId?: number;
    progressTypeId?: string;
    rating?: number;
    timestamp?: number;
}

export interface DomainGetAllStepProgressQueryParams extends DomainGetAllStepProgressFindOptionsWhere {
    orderByDirection?: Order;
    orderBy?: keyof DomainGetAllStepProgressFindOptionsWhere;
}
