import { createSelector } from '@ngrx/store';
import { coursesFeature } from './course.reducer';

export const selectCourses = createSelector(coursesFeature.selectCoursesState, (state) =>
    state.courses.map((courses) => ({
        ...courses,
        steps: courses.steps.filter((step) => step.content.id !== -1),
    })),
);

export const selectCourseIds = createSelector(selectCourses, (courses) => {
    return courses.map((course) => {
        return course.id;
    });
});

export const selectCourseLoadingState = createSelector(coursesFeature.selectCoursesState, (state) => state.loading);
