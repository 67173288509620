import { Color } from '@shared/utils/typescript';
import { TopicName } from '@shared/content-api-interface';
import { Topic } from '../models/topic.model';

export class TopicMock {
    private defaultValue: Topic = {
        entityId: '123',
        title: TopicName.BITING,
        intro: 'an intro',
        imageUrl: 'some/url',
        color: Color.Lola,
        pinned: false,
        stepIds: ['123'],
    };

    constructor(overrides?: Partial<Topic>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): Topic {
        return this.defaultValue;
    }
}
