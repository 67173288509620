import { ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, input } from '@angular/core';

// TODO: NBSon - ask sam about this component when he is back, check about the usage of color as it's limited to white and black
@Component({
    selector: 'app-icon',
    standalone: true,
    styles: [
        `
            .white {
                color: white;
            }

            .black {
                color: black;
            }
        `,
    ],
    template: `<i class="fa-{{ sprite() }} fa-{{ iconName() }} {{ color() }}"></i>`,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
    sprite = input.required<string>();
    iconName = input.required<string>();
    color = input<'white' | 'black'>('black');

    constructor(cd: ChangeDetectorRef) {
        effect(() => {
            if (this.sprite() || this.iconName() || this.color()) {
                cd.detectChanges();
            }
        });
    }
}
