import { GetStepsParams, InternalStepDto } from '@shared/content-api-interface';
import { createAction, props } from '@ngrx/store';

export const getSteps = createAction('[Content] Get Steps', props<{ params: GetStepsParams }>());

export const getStepsSuccess = createAction('[Content] Get Steps Success', props<{ steps: InternalStepDto[] }>());

export const getStepsFailure = createAction(
    '[Content] Get Steps Failure',
    props<{
        error: Error;
    }>(),
);
