import { DomainPostHouseholdCommand } from '../../../commands/domain-api/user/household/domain-post-household.command';

export class DomainPostHouseholdCommandMock {
    private defaultValue: DomainPostHouseholdCommand = {
        name: 'household',
    };

    constructor(overrides?: Partial<DomainPostHouseholdCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainPostHouseholdCommand {
        return this.defaultValue;
    }
}
