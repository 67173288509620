import { NgClass, NgStyle } from '@angular/common';
import { Component, EventEmitter, input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonButton, IonContent, IonInput, IonItem, IonList } from '@ionic/angular/standalone';
import { RadioWithLogicItem, RadioWithLogicResult } from './radio-with-logic.model';

@Component({
    standalone: true,
    selector: 'app-radio-with-logic',
    templateUrl: 'radio-with-logic.component.html',
    styleUrl: 'radio-with-logic.component.scss',
    imports: [IonButton, IonContent, IonInput, IonItem, IonList, NgStyle, ReactiveFormsModule, NgClass],
})
export class RadioWithLogicComponent {
    public buttons = input.required<RadioWithLogicItem[]>();
    public isDisabled = input(false);
    public isNewOnboarding = input(false);

    @Output() selected!: RadioWithLogicItem | undefined;
    @Output() changeEvent = new EventEmitter<RadioWithLogicResult>();

    public clickButton(value: RadioWithLogicItem): void {
        if (this.isDisabled()) {
            return;
        }

        this.selected = !this.selected ? value : undefined;
        this.valueChange();
    }

    public valueChange() {
        const complete = !!this.selected;

        this.changeEvent.emit({
            complete,
            selected: this.selected,
        });
    }
}
