import { Entry } from 'contentful';
import { ContentfulArticle } from './contentful-article.model';
import { ContentfulLesson } from './contentful-lesson.model';
import { ContentfulTopic } from './contentful-topic.model';

export interface ContentfulStep {
    id: number;
    title: string;
    topic: Entry<ContentfulTopic>;
    lessons: Entry<ContentfulLesson>[];
    articles: Entry<ContentfulArticle>[];
}
