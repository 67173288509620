import { GetChatTileTopicsParams, InternalChatTileTopicDto } from '@shared/content-api-interface';
import { createAction, props } from '@ngrx/store';

export const getChatTileTopics = createAction(
    '[Content] Get Chat Tile Topics',
    props<{ params: GetChatTileTopicsParams }>(),
);

export const getChatTileTopicsSuccess = createAction(
    '[Content] Get Chat Tile Topics Success',
    props<{ chatTileTopics: InternalChatTileTopicDto[] }>(),
);

export const getChatTileTopicsFailure = createAction(
    '[Content] Get Chat Tile Topics Failure',
    props<{
        error: Error;
    }>(),
);
