import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, Router, RouteReuseStrategy } from '@angular/router';
import { BASE_ENVIRONMENT_CONFIG, ProjectEnvironment, WINDOW } from '@frontend/configuration';
import { MIXPANEL_FRONTEND_CONFIG, ZigzagFrontendDataAccessAnalyticsModule } from '@frontend/data-access/analytics';
import { CHAT_CONFIG, ZigzagFrontendDataAccessChatModule } from '@frontend/data-access/chat';
import { ZigzagFrontendDataAccessContentfulModule } from '@frontend/data-access/contentful';
import { ZigzagFrontendDataAccessRouterModule } from '@frontend/data-access/router';
import { ZigzagFrontendDataAccessSentryModule } from '@frontend/data-access/sentry';
import { ZigzagFrontendDataAccessTimeModule } from '@frontend/data-access/time';
import {
    AUTHENTICATION_CONFIG,
    ZigzagFrontendDataAccessUserAuthenticationModule,
} from '@frontend/data-access/user/authentication';
import { ZigzagFrontendDataAccessUserChatTagsModule } from '@frontend/data-access/user/chat-tags';
import {
    AgentChatPageEffects,
    AuthenticationInterceptor,
    ChatEffects,
    ChatTagsEffects,
    ClientInfoEffects,
    ProfileEffects,
    STREAM_CHAT_AGENT_TOKEN,
    supportPortalClientInfoFeature,
    supportPortalProfileInfoFeature,
    SupportRouterEffects,
} from '@frontend/zigzag-support';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { provideEffects } from '@ngrx/effects';
import { provideState, provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { StreamChatModule, StreamTextareaModule } from 'stream-chat-angular';
import { AppComponent } from './app/app.component';
import { expertSupportPortalRoutes } from './app/expert-support-portal.routes';
import { environment } from './environments/environment';

if (environment.baseEnvironmentConfig.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        provideFirebaseApp(() =>
            initializeApp({
                apiKey: environment.firebaseConfig.apiKey,
                authDomain: environment.firebaseConfig.authDomain,
                storageBucket: environment.firebaseConfig.storageBucket,
                messagingSenderId: environment.firebaseConfig.messagingSenderId,
                appId: environment.firebaseConfig.appId,
                projectId: environment.firebaseConfig.projectId,
                databaseURL: environment.firebaseConfig.databaseURL ?? '',
            }),
        ),
        provideAuth(() => getAuth()),
        importProvidersFrom(
            StreamChatModule,
            StreamTextareaModule,
            TranslateModule.forRoot(),
            StreamChatModule,
            StreamTextareaModule,
            ReactiveFormsModule,
            HttpClientModule,
            FormsModule,
            ZigzagFrontendDataAccessChatModule,
            ZigzagFrontendDataAccessTimeModule,
            ZigzagFrontendDataAccessContentfulModule,
            ZigzagFrontendDataAccessUserChatTagsModule,
            ZigzagFrontendDataAccessUserAuthenticationModule,
            ZigzagFrontendDataAccessAnalyticsModule,
            ZigzagFrontendDataAccessRouterModule,
            ...(environment.baseEnvironmentConfig.environment === ProjectEnvironment.PRODUCTION
                ? [ZigzagFrontendDataAccessSentryModule]
                : []),
        ),
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        provideIonicAngular({ mode: 'ios' }),
        provideStore(
            {},
            {
                runtimeChecks: {
                    strictStateImmutability: true,
                    strictActionImmutability: true,
                },
            },
        ),
        provideState(supportPortalClientInfoFeature),
        provideState(supportPortalProfileInfoFeature),
        provideEffects([
            ChatEffects,
            SupportRouterEffects,
            ProfileEffects,
            AgentChatPageEffects,
            ClientInfoEffects,
            ChatTagsEffects,
        ]),
        ...(environment.baseEnvironmentConfig.environment === ProjectEnvironment.DEVELOPMENT
            ? [provideStoreDevtools({ connectInZone: true })]
            : []),
        provideRouter(expertSupportPortalRoutes),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        { provide: AUTHENTICATION_CONFIG, useValue: environment.firebaseConfig },
        { provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true },
        { provide: BASE_ENVIRONMENT_CONFIG, useValue: environment.baseEnvironmentConfig },
        { provide: WINDOW, useValue: window },
        {
            provide: CHAT_CONFIG,
            useValue: {
                apiKey: environment.streamChatApiKey,
            },
        },
        {
            provide: STREAM_CHAT_AGENT_TOKEN,
            useValue: environment.streamChatAgentToken,
        },
        { provide: MIXPANEL_FRONTEND_CONFIG, useValue: environment.mixpanelConfig },
        ...(environment.baseEnvironmentConfig.environment === ProjectEnvironment.PRODUCTION
            ? [
                  {
                      provide: ErrorHandler,
                      useValue: Sentry.createErrorHandler({
                          showDialog: false,
                      }),
                  },
                  {
                      provide: Sentry.TraceService,
                      deps: [Router],
                  },
                  {
                      provide: APP_INITIALIZER,
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      useFactory: () => () => {},
                      deps: [Sentry.TraceService],
                      multi: true,
                  },
              ]
            : []),
    ],
}).catch((err) => console.error('error:', err));
