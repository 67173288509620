import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import { internalEndpoints, InternalGeoLocationDto } from '@shared/user-api-interface';
import { catchError, Observable, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GeoLocationService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    getGeoLocation(): Observable<InternalGeoLocationDto> {
        return this.getIpAddress().pipe(
            switchMap(({ ip }) => {
                return this.getCountryCodeFromIp(ip);
            }),
        );
    }

    public getIpAddress(): Observable<{ ip: string }> {
        return this.http
            .get<{ ip: string }>('https://api.ipify.org/?format=json', httpOptions)
            .pipe(catchError(() => this.http.get<{ ip: string }>('https://ipapi.co/json', httpOptions)));
    }

    private getCountryCodeFromIp(ip: string): Observable<InternalGeoLocationDto> {
        return this.http.get<InternalGeoLocationDto>(
            `${this.environment.internalApiUrl}${internalEndpoints.geoLocationCollection}/${ip}`,
            httpOptions,
        );
    }
}
