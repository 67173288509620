import { DomainPatchRescheduledStepCommand } from './domain-patch-rescheduled-step.command';

// TODO - check nested validation
export class DomainPatchManyRescheduledStepCommand {
    rescheduledSteps!: DomainUpdateManyRescheduledStep[];
}

export class DomainUpdateManyRescheduledStep {
    id!: string;

    updateRescheduledStepDto!: DomainPatchRescheduledStepCommand;
}
