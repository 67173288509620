import { GetDailyBitesParams, InternalDailyBiteDto } from '@shared/content-api-interface';
import { createAction, props } from '@ngrx/store';

export const getDailyBites = createAction('[Content] Get DailyBites', props<{ params: GetDailyBitesParams }>());

export const getDailyBitesSuccess = createAction(
    '[Content] Get DailyBites Success',
    props<{ dailyBites: InternalDailyBiteDto[] }>(),
);

export const getDailyBitesFailure = createAction(
    '[Content] Get DailyBites Failure',
    props<{
        error: Error;
    }>(),
);
