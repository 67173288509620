import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { BreedGroupEffects } from './store/breed-group/breed-group.effects';
import { CourseEffects } from './store/course/course.effects';
import { DailyBitesEffects } from './store/daily-bite/daily-bites.effects';
import { DevelopmentalWindowEffects } from './store/developmental-window/developmental-window.effects';
import { FaqEffects } from './store/faq/faq.effects';
import { PageEffects } from './store/pages/page.effects';
import { StepEffects } from './store/step/step.effects';
import { TopicEffects } from './store/topic/topic.effects';
import { dailyBitesFeature } from './store/daily-bite/daily-bite.reducer';
import { topicsFeature } from './store/topic/topic.reducer';
import { breedGroupFeature } from './store/breed-group/breed-group.reducer';
import { developmentalWindowsFeature } from './store/developmental-window/developmental-window.reducer';
import { pageFeature } from './store/pages/page.reducer';
import { stepsFeature } from './store/step/step.reducer';
import { coursesFeature } from './store/course/course.reducer';
import { faqsFeature } from './store/faq/faq.reducer';
import { chatTileTopicsFeature } from './store/chat-tile/chat-tile.reducer';
import { ChatTileEffects } from './store/chat-tile/chat-tile.effects';

@NgModule({
    providers: [
        provideState(breedGroupFeature),
        provideState(coursesFeature),
        provideState(dailyBitesFeature),
        provideState(developmentalWindowsFeature),
        provideState(faqsFeature),
        provideState(pageFeature),
        provideState(stepsFeature),
        provideState(topicsFeature),
        provideState(chatTileTopicsFeature),
        provideEffects([
            BreedGroupEffects,
            CourseEffects,
            DailyBitesEffects,
            DevelopmentalWindowEffects,
            FaqEffects,
            PageEffects,
            StepEffects,
            TopicEffects,
            ChatTileEffects,
        ]),
    ],
})
export class ZigzagFrontendDataAccessContentfulModule {}
