import { RichTextContent } from 'contentful';
import { StepType } from '../../types/step.types';
import { TopicName } from '../../types/topic.types';
import { InternalDynamicContentBlockDto } from './internal-dynamic-content-block.dto';

export interface InternalLessonDto {
    id: number;
    contentType: StepType.LESSON;
    duration: number;
    topic: TopicName;
    title: string;
    videoId: string;
    introduction: string;
    introductionDynamicContent: InternalDynamicContentBlockDto[];
    beforeYouStart: string[];
    steps: string[];
    stepsDynamicContent: InternalDynamicContentBlockDto[];
    tips: string[];
    tipsDynamicContent: InternalDynamicContentBlockDto[];
    lowRatingAnimation: string;
    highRatingAnimation: string;
    lowRatingHtml: string;
    highRatingHtml: string;
    location?: string;
    whatsNeeded?: { toys: string[]; treats: string[]; equipmentAccessories: string[]; other: string[] };
    audio:
        | {
              url: string;
              title: string;
          }
        | undefined;
    tags: string[];
    lessonAuthor:
        | {
              authorName: string;
              authorImageUrl: string;
              authorBio: RichTextContent;
              authorCertifications: string[];
              authorOrganisation: string;
          }
        | undefined;
}
