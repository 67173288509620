import { NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Color } from '@shared/utils/typescript';
import { IonButton, IonInput, IonItem } from '@ionic/angular/standalone';
import { RadioWithExplanationItem, RadioWithExplanationResult } from './radio-with-explanation.model';

@Component({
    standalone: true,
    selector: 'app-radio-with-explanation',
    templateUrl: 'radio-with-explanation.component.html',
    styleUrls: ['radio-with-explanation.component.scss'],
    imports: [IonButton, ReactiveFormsModule, NgStyle, IonItem, IonInput],
})
export class RadioWithExplanationComponent {
    @Input({ required: true }) buttons: RadioWithExplanationItem[] = [];

    @Input() color: Color = Color.Harley;

    @Output() changeEvent = new EventEmitter<RadioWithExplanationResult>();

    public explanationInput = new FormControl('');
    public selected: RadioWithExplanationItem | undefined;

    public clickButton(value: RadioWithExplanationItem): void {
        this.selected = this.selected === undefined ? value : undefined;
        this.valueChange();
    }

    public valueChange() {
        const complete =
            !!this.selected && (this.selected.explainMore === undefined || this.explanationInput.value !== '');

        if (!this.selected && this.explanationInput.value !== '') {
            this.explanationInput.reset();
        }

        this.changeEvent.emit({
            complete,
            answer: {
                value: this.selected?.value || '',
                explanation: this.explanationInput.value || '',
            },
        });
    }
}
