export enum ChatChannelType {
    AGENT = 'expertsupport',
    AI = 'ai-chat',
}

export enum StreamChatNotificationCommandType {
    MESSAGE_NEW = 'message.new',
    MESSAGE_UPDATED = 'message.updated',
}

export enum StreamChatUserRoles {
    Owner = 'owner',
    Admin = 'admin',
}

export const ZIGGY_AI_ID = 'Ziggy_AI';

export const AUTO_REPLY_USERNAME = 'PuppyBot';

export const PUPPY_COACH_ID = 'Puppy_Coach';

export const AI_CHAT_RESPONSE_TIMEOUT = 25; // seconds
