export enum TopicName {
    RECALL = 'Recall',
    SLEEP = 'Sleep',
    BEING_ALONE = 'Being alone',
    SOUNDS = 'Sounds',
    OTHER_DOGS = 'Other dogs',
    NEW_LOCATIONS = 'New locations',
    NOVELTY = 'Novelty',
    OTHER_PEOPLE = 'Other people',
    TOILET = 'Toilet',
    SIT = 'Sit',
    DOWN = 'Down',
    WAIT = 'Wait',
    FREE_PLAY = 'Free play',
    PLAY_TOGETHER = 'Play together',
    FOOD_GAMES = 'Food games',
    SCENT_GAMES = 'Scent games',
    RETRIEVE_GAMES = 'Retrieve games',
    BITING = 'Biting',
    BODY_LANGUAGE = 'Body language',
    GROOMING = 'Grooming',
    LEAD_WALKING = 'Lead walking',
    TRICKS = 'Tricks',
    ADOLESCENCE = 'Adolescence',
    NO_CATEGORY = 'Other',
}
