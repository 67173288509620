import { DomainPostUserAppAccessGrantedCommand } from '../../../commands/domain-api/user/user-app-access-granted/domain-post-user-app-access-granted.command';

export class DomainPostUserAppAccessGrantedCommandMock {
    private defaultValue: DomainPostUserAppAccessGrantedCommand = {
        userId: 'userId',
        accessTypeId: 'accessTypeId',
        accessGrantedTimestamp: 2014,
        expiryTimeStamp: 2024,
        openedAppAfterExpiry: false,
        numberOfDaysAccessGranted: 3,
    };

    constructor(overrides?: Partial<DomainPostUserAppAccessGrantedCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainPostUserAppAccessGrantedCommand {
        return this.defaultValue;
    }
}
