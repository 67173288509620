<section class="chat-message-rating-feedback-heading">
    <img
        class="chat-message-rating-feedback-heading__image"
        alt="Ziggy reading a book"
        [src]="'/assets/images/ziggy/ziggy-reading-close-up.svg'"
    />
    <h3 class="chat-message-rating-feedback-heading__title">Why did you choose this rating?</h3>
</section>

@if (aiChat) {
    <ng-container *ngTemplateOutlet="options"></ng-container>
} @else {
    <section class="chat-message-rating-feedback-icons">
        <button
            class="chat-message-rating-feedback-icons__thumbs-down"
            [ngClass]="{ active: thumbsDownRating === true }"
            (click)="onClickThumbRating(true)"
        >
            <i class="fa-light fa-thumbs-down"></i>
        </button>
        <button
            class="chat-message-rating-feedback-icons__thumbs-up"
            [ngClass]="{ active: thumbsDownRating === false }"
            (click)="onClickThumbRating(false)"
        >
            <i class="fa-light fa-thumbs-up"></i>
        </button>
    </section>
    @if (thumbsDownRating === true) {
        <ng-container *ngTemplateOutlet="options"></ng-container>
    }
}

<ng-template #options>
    <section class="chat-message-rating-feedback-options">
        @for (option of feedbackOptions; track option.key) {
            <button
                class="zz-button chat-message-rating-feedback-options__option"
                [ngClass]="{
                    'chat-message-rating-feedback-options__option--selected':
                        option.key === selectedFeedbackOption?.key,
                }"
                [attr.data-test]="'chat-message-rating-feedback-option-' + option.key"
                (click)="onClickFeedbackOption(option)"
            >
                {{ option.value }}
            </button>
        }

        <button
            class="zz-button chat-message-rating-feedback-options__option"
            data-test="chat-message-rating-feedback-option-other"
            [ngClass]="{
                'chat-message-rating-feedback-options__option--selected': shouldShowCommentInput,
            }"
            (click)="onSelectOtherFeedbackOption()"
        >
            {{ otherFeedbackOptionLabel }}
        </button>
    </section>
</ng-template>

@if (shouldShowCommentInput) {
    <ion-item class="chat-message-rating-feedback-comment">
        <ion-input
            id="comment-input-control"
            class="chat-message-rating-feedback-comment__input"
            autocapitalize="sentences"
            placeholder="Please add your comment"
            type="text"
            data-test="chat-message-rating-feedback-comment-input"
            [tabindex]="1"
            [formControl]="commentFormControl"
        >
        </ion-input>
    </ion-item>
}

@if (aiChat) {
    <section class="chat-message-rating-feedback-footer">
        <ion-button
            class="chat-message-rating-feedback-footer__done"
            color="max"
            size="small"
            expand="block"
            data-test="chat-message-rating-feedback-done"
            (click)="onClickDone()"
        >
            Done
        </ion-button>
    </section>
}
