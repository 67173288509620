import { ActionCreator } from '@ngrx/store';
import { Action } from '@ngrx/store/src/models';

export interface RequestState<T = undefined> {
    loading: boolean;
    error?: Error;
    data?: T;
}

export type RequestAction = ActionCreator<string, (props: any) => Action<string>>;

export type RequestActionSuccess<T> = ActionCreator<
    string,
    T extends void ? () => Action<string> : (props: { data: T }) => { data: T } & Action<string>
>;

export type RequestActionFailure = ActionCreator<
    string,
    (props: { error: Error }) => { error: Error } & Action<string>
>;
