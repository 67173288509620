import { ArticleProgressType, LessonProgressType } from '@shared/user-domain';
import {
    ValidationArguments,
    ValidationOptions,
    ValidatorConstraint,
    isEnum,
    registerDecorator,
} from 'class-validator';

@ValidatorConstraint({ name: 'isStepProgressType', async: false })
class IsStepProgressTypeConstraint {
    validate(value: unknown) {
        return isEnum(value, LessonProgressType) || isEnum(value, ArticleProgressType);
    }

    defaultMessage(args: ValidationArguments) {
        return `${args.property} must be of type StepProgressType`;
    }
}

export const IsStepProgressType = (validationOptions?: ValidationOptions) => (object: object, propertyName: string) => {
    registerDecorator({
        name: 'isStepProgressType',
        target: object.constructor,
        propertyName: propertyName,
        options: validationOptions,
        validator: IsStepProgressTypeConstraint,
    });
};
