import { Component, Input } from '@angular/core';
import { IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { checkmarkSharp } from 'ionicons/icons';
import { Bullet } from './bullet-list.model';

@Component({
    selector: 'app-bullet-list',
    templateUrl: './bullet-list.component.html',
    styleUrls: ['./bullet-list.component.scss'],
    standalone: true,
    imports: [IonIcon],
})
export class BulletListComponent {
    @Input({ required: true }) bullets!: Bullet[];

    constructor() {
        addIcons({ checkmarkSharp });
    }
}
