import { ContentfulLocale } from '@shared/constants';

export interface GetContentBaseParams {
    locale: ContentfulLocale;
}

export interface GetCoursesParams extends GetContentBaseParams {
    breedId: string;
    ageInWeeks: number;
    hasArrived: boolean;
    hasArrivedOnDateOfOnboarding: boolean;
    isRescue: boolean;
}

export interface GetDailyBitesParams extends GetContentBaseParams {
    breedId: string;
}

export interface GetStepsParams extends GetContentBaseParams {
    breedId: string;
    ageInWeeks: number;
    isRescue: boolean | undefined;
}

export interface GetChatTileTopicsParams extends GetContentBaseParams {
    hasArrived: boolean;
    ageInWeeks: number;
    isRescue?: boolean;
}
