const defaultErrorFactory = (actual: string, expected: string) =>
    new InvalidValueException(`Value ${actual} is not assignable to type ${expected}.`);

export const StringUnion = <UnionType extends string>(...values: UnionType[]) => {
    Object.freeze(values);

    const valueSet = new Set<string>(values);
    const guard = (value: string): value is UnionType => valueSet.has(value);
    const check = (
        value: string,
        errorFactory: (actual: string, expected: string) => Error = defaultErrorFactory,
    ): UnionType => {
        if (!guard(value)) {
            const actual = JSON.stringify(value);
            const expected = values.map((s) => JSON.stringify(s)).join(' | ');

            throw errorFactory(actual, expected);
        }
        return value;
    };
    const unionNamespace = { guard, check, values };

    return Object.freeze(unionNamespace as typeof unionNamespace & { type: UnionType });
};

export class InvalidValueException extends Error {}
