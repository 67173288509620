import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, take } from 'rxjs';
import { catchError, debounceTime, filter, map, switchMap, tap } from 'rxjs/operators';
import { MixpanelPeopleService } from '../mixpanel-people.service';
import { MixpanelService } from '../mixpanel.service';
import {
    initAnalytics,
    logoutAnalytics,
    logoutAnalyticsFailure,
    logoutAnalyticsSuccess,
    setAnalyticsProfile,
    trackEvent,
    trackLibrarySearch,
    updateAttributionData,
} from './analytics.actions';

@Injectable()
export class AnalyticsEffects {
    private readonly actions$ = inject(Actions);
    private readonly mixpanelPeopleService = inject(MixpanelPeopleService);
    private readonly mixpanelService = inject(MixpanelService);

    initAnalytics$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(initAnalytics),
                tap(() => {
                    this.mixpanelService.init();
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    logoutAnalytics$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(logoutAnalytics),
            switchMap(() =>
                this.mixpanelService.logout().pipe(
                    take(1),
                    map(() => logoutAnalyticsSuccess()),
                    catchError((error) => of(logoutAnalyticsFailure({ error }))),
                ),
            ),
        );
    });

    trackLibrarySearch$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(trackLibrarySearch),
            debounceTime(2000),
            filter((action) => !action.searchTerm.includes('@')),
            map(({ searchTerm, resultCount }) =>
                trackEvent({
                    eventName: 'Library search',
                    eventProperties: {
                        searchTerm,
                        resultCount,
                    },
                }),
            ),
        );
    });

    trackEvent$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(trackEvent),
                tap((action) => {
                    void this.mixpanelService.track(action.eventName, action.eventProperties);
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    setAnalyticsProfile$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(setAnalyticsProfile),
                tap(({ profile, id }) => {
                    if (profile && id) {
                        void this.mixpanelPeopleService.setProfile(profile, id);
                    }
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    updateMixpanelAttributionData$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(updateAttributionData),
                tap(({ attributionData }) => {
                    this.mixpanelPeopleService.setAttributionData(attributionData);
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );
}
