import { InternalPatchHouseholdCommand } from '../../../commands/internal-api/user/household/internal-patch-household.command';

export class InternalPatchHouseholdCommandMock {
    private defaultValue: InternalPatchHouseholdCommand = {
        name: 'household',
        userId: 'uuid-1234',
        dogId: '123',
    };

    constructor(overrides?: Partial<InternalPatchHouseholdCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalPatchHouseholdCommand {
        return this.defaultValue;
    }
}
