export enum TimeUnit {
    SECONDS = 'Seconds',
    MINUTES = 'Minutes',
    HOURS = 'Hours',
    DAYS = 'Days',
    WEEKS = 'Weeks',
    MONTHS = 'Months',
}

export const msInTimeUnit = new Map<TimeUnit, number>([
    [TimeUnit.SECONDS, 1000],
    [TimeUnit.MINUTES, 1000 * 60],
    [TimeUnit.HOURS, 1000 * 60 * 60],
    [TimeUnit.DAYS, 1000 * 60 * 60 * 24],
    [TimeUnit.WEEKS, 1000 * 60 * 60 * 24 * 7],
    [TimeUnit.MONTHS, 1000 * 60 * 60 * 24 * 30],
]);
