import { InternalIterablePostPushOpenEventCommand } from '../../../commands/internal-api/third-party/iterable/internal-iterable-post-push-open-event.command';

export class InternalIterablePostPushOpenEventCommandMock {
    private defaultValue: InternalIterablePostPushOpenEventCommand = {
        campaignId: 0,
        messageId: '',
        templateId: 0,
        userId: 'user-id',
        createdAt: 1,
    };

    constructor(overrides?: Partial<InternalIterablePostPushOpenEventCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalIterablePostPushOpenEventCommand {
        return this.defaultValue;
    }
}
