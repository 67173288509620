import { IsArray, IsString } from 'class-validator';
import { ConversationTypeID } from '../../../models/conversation/conversation.model';
import { InternalPostConversationMessage } from './internal-post-conversation-message.command';

export class InternalPostConversationCommand {
    @IsString()
    channelId!: string;

    @IsString()
    userId!: string;

    @IsString()
    conversationTypeId!: ConversationTypeID;

    @IsArray()
    messages!: InternalPostConversationMessage[];
}
