import { Injectable } from '@angular/core';
import { AdjustAttribution } from '@awesome-cordova-plugins/adjust';
import * as Mixpanel from 'mixpanel-browser';
import { MixpanelPeopleService } from './mixpanel-people.service';
import { AnalyticsProfile } from './store/analytics.model';

// override mixpanel because it behaves different in the zigzag app than the portal
const mixpanel = typeof Mixpanel.default === 'object' ? Mixpanel.default : Mixpanel;

@Injectable({
    providedIn: 'root',
})
export class MixpanelPeopleBrowserService extends MixpanelPeopleService {
    constructor() {
        super();
    }

    async setProfile(profile: AnalyticsProfile, profileId: string): Promise<void> {
        await mixpanel.identify(profileId);
        mixpanel.people.set({
            ...profile,
            USER_ID: profileId,
        });
    }

    // Add adjust campaign data profile
    setAttributionData(attributionData: AdjustAttribution): void {
        mixpanel.people.set(attributionData);
    }
}
