export interface PageContentState {
    today: PageTodayContent | undefined;
}

export interface PageTodayContent {
    complimentsFirst: string[];
    complimentsSecond: string[];
    complimentsOthers: string[];
    startedOnTrackMessage: string;
    startedNotAtHomeMessage: string;
    startedNotOnTrackMessage: string;
    startedOver12WeeksMessage: string;
    startedOver6MonthsMessage: string;
    startedCloseToJuvenile: string;
    newOnTrackMessage: string;
    newNotAtHomeMessage: string;
    newOver12WeeksMessage: string;
}
