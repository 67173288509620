<ion-searchbar
    class="searchbar"
    #searchBar
    autocapitalize="sentences"
    data-test="search-input"
    inputmode="search"
    [showCancelButton]="showCancelButton()"
    [placeholder]="placeholder()"
    (ionInput)="searchItems($event.target.value)"
    (ionFocus)="focus()"
    (ionCancel)="blur()"
>
    @if (isBasicPlan() && isLibraryPage()) {
        <app-generic-chip
            class="searchbar__premium-chip"
            text="Premium"
            icon="fa-regular fa-crown"
            backgroundColor="sophie"
            textColor="snowy"
            padding="4px 8px"
        />
    }
</ion-searchbar>

@if (isFocused) {
    @if (searchBar.value) {
        <ng-container
            *ngTemplateOutlet="
                hasResults() ? resultListTemplate() : noResultTemplate();
                context: { searchValue: searchBar.value }
            "
        >
        </ng-container>
    } @else {
        <ng-container *ngTemplateOutlet="instructionTemplate()"></ng-container>
    }
}
