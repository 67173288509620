import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ContentService } from '../../content.service';
import { getCourses, getCoursesFailure, getCoursesSuccess } from './course.actions';

@Injectable()
export class CourseEffects {
    private readonly actions$ = inject(Actions);
    private readonly contentService = inject(ContentService);
    getCourses$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getCourses),
            switchMap(({ params }) => {
                return this.contentService.getCourses(params).pipe(
                    map((courses) => {
                        return getCoursesSuccess({ courses });
                    }),
                    catchError((error: Error) => {
                        console.error(error);
                        return of(getCoursesFailure({ error }));
                    }),
                );
            }),
        );
    });
}
