import { Injectable, inject } from '@angular/core';
import { AdjustAttribution } from '@awesome-cordova-plugins/adjust';
import { Mixpanel, MixpanelPeople } from '@awesome-cordova-plugins/mixpanel/ngx';
import { MixpanelPeopleService } from './mixpanel-people.service';
import { AnalyticsProfile } from './store/analytics.model';

@Injectable({
    providedIn: 'root',
})
export class MixpanelPeopleAppService extends MixpanelPeopleService {
    private readonly mixpanelPeople = inject(MixpanelPeople);
    private readonly mixpanel = inject(Mixpanel);

    constructor() {
        super();
    }

    async setProfile(profile: AnalyticsProfile, profileId: string): Promise<void> {
        await this.mixpanel.identify(profileId, true);
        await this.mixpanelPeople.set({
            ...profile,
            USER_ID: profileId,
        });
        await this.mixpanel.flush();
    }

    // Add adjust campaign data profile
    setAttributionData(attributionData: AdjustAttribution): void {
        void this.mixpanelPeople.set(attributionData);
    }
}
