import { Component, input, output } from '@angular/core';
import { IonButton, IonButtons, IonProgressBar, IonToolbar } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';
import { addIcons } from 'ionicons';
import { arrowBackOutline } from 'ionicons/icons';
import { IconComponent } from '../icon/icon.component';

@Component({
    selector: 'app-progress-header',
    templateUrl: './progress-header.component.html',
    styleUrls: ['./progress-header.component.scss'],
    standalone: true,
    imports: [IonToolbar, IonButtons, IonButton, IconComponent, IonProgressBar],
})
export class ProgressHeaderComponent {
    public progressRatio = input(0);
    public showBackButton = input(true);
    public isDisabled = input(false);

    public backButtonClicked = output();

    protected readonly Color = Color;

    constructor() {
        addIcons({ arrowBackOutline });
    }

    public onClickBackButton(): void {
        if (this.isDisabled()) {
            return;
        }

        this.backButtonClicked.emit();
    }
}
