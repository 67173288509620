import { inject, Injectable } from '@angular/core';
import * as Mixpanel from 'mixpanel-browser';
import { Dict } from 'mixpanel-browser';
import { from } from 'rxjs';
import { MIXPANEL_FRONTEND_CONFIG, MixpanelFrontendConfig } from './mixpanel-config.token';
import { MixpanelService } from './mixpanel.service';

// override mixpanel because it behaves different in the zigzag app than the portal
const mixpanel = typeof Mixpanel.default === 'object' ? Mixpanel.default : Mixpanel;

@Injectable({
    providedIn: 'root',
})
export class MixpanelBrowserService extends MixpanelService {
    private readonly config = inject<MixpanelFrontendConfig>(MIXPANEL_FRONTEND_CONFIG);

    constructor() {
        super();
    }

    init(): void {
        mixpanel.init(this.config.projectToken, {
            loaded: (mixpanel) => {
                mixpanel.identify();
            },
        });
    }

    logout() {
        mixpanel.track('Logout Mixpanel');
        mixpanel.reset();
        return from('reset mixpanel');
    }

    track(eventName: string, eventProperties?: Dict): void {
        mixpanel.track(eventName, { ...eventProperties });
    }
}
