import { CourseProgressType } from '@shared/user-domain';

export interface DomainPostCourseProgressCommandOld {
    courseProgress: CreateCourseProgress[];
}

export interface CreateCourseProgress {
    courseId: string;
    progressType: CourseProgressType;
    timestamp: number;
}
