import { MIXPANEL_FRONTEND_CONFIG, MixpanelFrontendConfig } from './mixpanel-config.token';
import { MixpanelPeopleAppService } from './mixpanel-people-app.service';
import { MixpanelPeopleBrowserService } from './mixpanel-people-browser.service';
import { MixpanelPeopleService } from './mixpanel-people.service';

export const MixpanelPeopleServiceProvider = {
    provide: MixpanelPeopleService,
    useFactory: (config: MixpanelFrontendConfig) => {
        return config.platform === 'browser' ? new MixpanelPeopleBrowserService() : new MixpanelPeopleAppService();
    },
    deps: [MIXPANEL_FRONTEND_CONFIG],
};
