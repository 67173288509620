export interface FeedbackOption {
    key: string;
    value: string;
}

export interface FeedbackSubmittedEvent {
    thumbsDown: boolean;
    option?: FeedbackOption;
    comment?: string;
}
