import { createFeature, createReducer, on } from '@ngrx/store';
import { getProfileSuccess } from './profile.actions';
import { Profile } from './profile.model';

export interface ProfileState {
    profile: Profile;
}

export const initialProfile: Profile = {
    id: '',
    email: '',
    name: '',
    token: '',
};

export const initialState: ProfileState = {
    profile: initialProfile,
};

export const supportPortalProfileInfoFeature = createFeature({
    name: 'profile',
    reducer: createReducer(
        initialState,
        on(
            getProfileSuccess,
            (state, action): ProfileState => ({
                ...state,
                profile: action.profile,
            }),
        ),
    ),
});
