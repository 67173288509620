import { Injectable, inject } from '@angular/core';
import { activeChannelChanged } from '@frontend/data-access/chat';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ProfileService } from '../profile/profile.service';
import { getClientInfo, getClientInfoFailure, getClientInfoSuccess } from './client-info.actions';
import { mapBaseUserDtoToClientInfo } from './client-info.utils';

@Injectable()
export class ClientInfoEffects {
    private readonly actions$ = inject(Actions);
    private readonly profileService = inject(ProfileService);
    fetchClientInfoWhenChannelChanges$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(activeChannelChanged),
            filter(({ activeChannel }) => !!activeChannel),
            map(({ activeChannel }) => getClientInfo({ userId: activeChannel!.id })),
        );
    });

    getClientInfo$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getClientInfo),
            switchMap(({ userId }) =>
                this.profileService.getClientInfo(userId).pipe(
                    map((dto) => getClientInfoSuccess({ clientInfo: mapBaseUserDtoToClientInfo(dto) })),
                    catchError((error: Error) => of(getClientInfoFailure({ error }))),
                ),
            ),
        );
    });
}
