import { createAction, props } from '@ngrx/store';
import { User } from 'configcat-common/lib/User';
import { ConfigCatFlagState } from './config-cat.model';

export const getConfigCatFeatureFlags = createAction(
    '[ConfigCat] Get ConfigCat Feature Flags',
    props<{ userObject: User }>(),
);

export const getConfigCatFeatureFlagsSuccess = createAction(
    '[ConfigCat] Get ConfigCat Feature Flags Success',
    props<{ configCatFlagState: ConfigCatFlagState }>(),
);
export const getConfigCatFeatureFlagsFailure = createAction(
    '[ConfigCat] Get ConfigCat Feature Flags Failure',
    props<{ error: Error }>(),
);
