<div
    class="radio-with-explanation"
    data-test="radio-with-explanation"
    [ngStyle]="{ height: (selected ? 130 : buttons.length * 60) + 'px' }"
>
    <div class="radio-with-explanation__radio-container">
        @for (button of buttons; track button.title) {
            <div
                class="radio-with-explanation____explanation-button-container"
                [ngStyle]="{ top: button.value === selected?.value ? 0 : $index * 60 + 'px' }"
            >
                @if (selected === undefined || button.value === selected?.value) {
                    <ion-button
                        class="radio-with-explanation__explanation-button"
                        [class.unselected]="button.value !== selected?.value"
                        [ngStyle]="{
                            '--background':
                                button.value === selected?.value
                                    ? 'var(--ion-color-' + color + ')'
                                    : 'var(--ion-color-snowy)',
                            '--border-color':
                                button.value === selected?.value
                                    ? 'var(--ion-color-' + color + ')'
                                    : 'var(--ion-color-app-outline)',
                            '--color':
                                button.value === selected?.value
                                    ? 'var(--ion-color-' + color + '-contrast)'
                                    : 'var(--ion-color-jack)'
                        }"
                        (click)="clickButton(button)"
                        expand="block"
                        fill="solid"
                        [attr.data-test]="'radio-button-' + button.value"
                    >
                        {{ button.title }}
                    </ion-button>
                }
            </div>
        }
    </div>

    <div
        class="radio-with-explanation__explanation-input-container {{
            selected && selected.explainMore ? 'radio-with-explanation--show-explanation-input' : ''
        }}"
        [ngStyle]="{ top: (selected ? 70 : buttons.length * 60 + 20) + 'px' }"
        data-test="explanation-input-container"
    >
        <ion-item>
            <ion-input
                class="zz-input"
                [formControl]="explanationInput"
                id="explanation-input"
                [placeholder]="selected?.explainMore?.placeholder"
                type="text"
                (ionInput)="valueChange()"
                data-test="explanation-input"
                [tabindex]="1"
            ></ion-input>
        </ion-item>
    </div>
</div>
