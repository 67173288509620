import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { APP_VERSION_NUMBER } from '@shared/constants';
import { Mixpanel } from '@awesome-cordova-plugins/mixpanel/ngx';
import { from, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MIXPANEL_FRONTEND_CONFIG, MixpanelFrontendConfig } from './mixpanel-config.token';
import { MixpanelService } from './mixpanel.service';

@Injectable({
    providedIn: 'root',
})
export class MixpanelAppService extends MixpanelService {
    private readonly config = inject<MixpanelFrontendConfig>(MIXPANEL_FRONTEND_CONFIG);
    public readonly router = inject(Router);
    private readonly mixpanel = inject(Mixpanel);

    constructor() {
        super();
    }

    init(): void {
        this.mixpanel
            .init(this.config.projectToken)
            .then(() => {
                void this.mixpanel.track('device startup', { 'app-version': APP_VERSION_NUMBER, 'app-name': 'zigzag' });
            })
            .catch(() => console.error('MXP ERROR'));

        this.router.events
            .pipe(filter((e): e is NavigationEnd => e instanceof NavigationEnd))
            .subscribe((e: NavigationEnd) => {
                void this.mixpanel.track('navigateTo', {
                    page: e.url,
                });
            });
    }

    logout(): Observable<unknown> {
        void this.mixpanel.track('Logout Mixpanel');
        return from(this.mixpanel.reset());
    }

    track(eventName: string, eventProperties?: unknown): void {
        void this.mixpanel.track(eventName, eventProperties);
    }
}
