import { animate, sequence, state, style, transition, trigger } from '@angular/animations';

export const fadeInAnimation = (duration: string | number = '0.45s') =>
    trigger('fadeInAnimation', [
        state('in', style({ opacity: 1, display: 'block' })),
        state('out', style({ opacity: 0, display: 'none' })),
        transition('out => in', [
            sequence([
                style({ display: 'block', position: 'absolute' }),
                animate(`${duration} ease-in-out`, style({ position: 'relative' })),
                style({ opacity: 1 }),
            ]),
        ]),
    ]);
