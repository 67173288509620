import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { loginWithEmailAndPassword } from '@frontend/data-access/user/authentication';
import { InvalidInputErrorMessageComponent } from '@frontend/ui';
import { IonButton, IonContent, IonInput, IonItem, IonSpinner } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { selectLoginPageVm } from './login.page.selectors';
import { routeTo } from '@frontend/data-access/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.page.html',
    styleUrls: ['./login.page.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        InvalidInputErrorMessageComponent,
        AsyncPipe,
        IonContent,
        IonItem,
        IonInput,
        IonButton,
        IonSpinner,
    ],
})
export class LoginPageComponent {
    private readonly store = inject(Store);
    loginForm = new FormGroup({
        email: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
    });

    public vm$ = this.store.select(selectLoginPageVm);

    login(): void {
        this.store.dispatch(
            loginWithEmailAndPassword({
                email: this.loginForm.value.email as string,
                password: this.loginForm.value.password as string,
            }),
        );
    }

    forgotPassword(): void {
        void this.store.dispatch(routeTo({ commands: ['reset-password'] }));
    }
}
