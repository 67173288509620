import { IsNumber, IsOptional, IsString } from 'class-validator';

export class DomainPatchTodayProgressCommand {
    @IsOptional()
    @IsString()
    userId?: string;

    @IsOptional()
    @IsString()
    courseId?: string;

    @IsOptional()
    @IsNumber()
    numberOfStepsCompleted?: number;

    @IsOptional()
    @IsString()
    dogId?: string;
}
