import { ChatChannelType } from '@shared/constants';

export interface ChatConnectChannelParams {
    type: ChatChannelType;
    id: string; // identifier of the channel
    custom?: {
        image?: string;
        name?: string;
        members?: string[];
    };
}

export interface ChatChannelInfo {
    id: string;
    type: string;
}
