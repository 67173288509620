import { Color } from '@shared/utils/typescript';
import { InternalChatTileContentDto } from './internal-chat-tile-content.dto';

export interface InternalChatTileTopicDto {
    id: string;
    title: string;
    image: string;
    colour: Color;
    chatTileContent: string[] | InternalChatTileContentDto[];
    ageBracket: string;
    isPupAtHome: boolean;
    isPupRescue: boolean;

    //todo LB remove 1st November
    isHomeRecommendedContent?: boolean | undefined;
}
