import { AfterViewInit, Directive, ElementRef, inject, Input } from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';

@Directive({
    selector: '[appSwiper]',
    standalone: true,
})
export class SwiperDirective implements AfterViewInit {
    @Input()
    public config?: SwiperOptions;

    private readonly el = inject<ElementRef<SwiperContainer>>(ElementRef<SwiperContainer>);

    public ngAfterViewInit(): void {
        Object.assign(this.el.nativeElement, this.config);

        this.el.nativeElement.initialize();
    }
}
