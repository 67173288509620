@if (vm$ | async; as vm) {
    <ion-content class="chat-tags">
        @if (vm.selectedChannelId) {
            <ion-select
                class="zz-select"
                label="Add Tag"
                data-test="tag-dropdown"
                interface="popover"
                [(ngModel)]="value"
                (ionChange)="addTag($event, vm.selectedChannelId)"
                (ionDismiss)="reset()"
            >
                @for (tag of vm.inactiveChannelTags; track tag.id) {
                    <ion-select-option value="{{ tag.id }}">{{ tag.name }}</ion-select-option>
                }
            </ion-select>
            <div class="tag-container">
                @for (tag of vm.activeChannelTags; track tag.id) {
                    <button
                        data-test="tag-button"
                        class="zz-button s agent-chat-tag"
                        (click)="removeTag(tag.id, vm.selectedChannelId)"
                    >
                        {{ tag.name }}
                    </button>
                }
            </div>
        }
    </ion-content>
}
