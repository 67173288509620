import { ConversationMessageRatingTypeID } from '../../../models/conversation/conversation.model';

export interface DomainConversationMessageDto {
    id: string;
    conversationId: string;
    content: string;
    senderId: string;
    streamChatMessageId: string;
    ratingId: ConversationMessageRatingTypeID;
    creationDateTime: Date;
}
