import { Component, Input } from '@angular/core';
import { Color } from '@shared/utils/typescript';
import { IonCard } from '@ionic/angular/standalone';

@Component({
    selector: 'app-simple-text-card-slim',
    standalone: true,
    imports: [IonCard],
    templateUrl: './simple-text-card-slim.component.html',
    styleUrls: ['./simple-text-card-slim.component.scss'],
})
export class SimpleTextCardSlimComponent {
    @Input({ required: true }) title!: string;
    @Input({ required: true }) background!: Color;
    @Input({ required: true }) hasArrow!: boolean;
}
