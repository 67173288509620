import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BASE_ENVIRONMENT_CONFIG, BaseEnvironmentConfig } from '@frontend/configuration';
import { httpOptions } from '@frontend/utility/constants';
import { internalEndpoints, InternalUserDto } from '@shared/user-api-interface';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProfileService {
    private readonly http = inject(HttpClient);
    private readonly environment = inject<BaseEnvironmentConfig>(BASE_ENVIRONMENT_CONFIG);

    public getProfile(): Observable<InternalUserDto> {
        return this.http.get<InternalUserDto>(
            `${this.environment.internalApiUrl}${internalEndpoints.agentsCollection}`,
            httpOptions,
        );
    }

    public getClientInfo(userId: string): Observable<InternalUserDto> {
        return this.http.get<InternalUserDto>(
            `${this.environment.internalApiUrl}${internalEndpoints.accountsCollection}/${userId}`,
            httpOptions,
        );
    }
}
