import { Pipe, PipeTransform } from '@angular/core';
import {
    mapDateOfBirthToAgeInDays,
    mapDateOfBirthToAgeInMonths,
    mapDateOfBirthToAgeInWeeks,
} from '@shared/utils/typescript';

@Pipe({
    name: 'age',
    standalone: true,
})
export class AgePipe implements PipeTransform {
    transform(dateOfBirth: string | Date | undefined, dateOfReference?: Date): string {
        if (!dateOfBirth) {
            return '';
        }

        const ageInDays = mapDateOfBirthToAgeInDays(dateOfBirth, dateOfReference);

        if (ageInDays < 7) {
            return `${ageInDays} day${ageInDays === 1 ? '' : 's'}`;
        }

        const ageInWeeks = mapDateOfBirthToAgeInWeeks(dateOfBirth, dateOfReference);

        if (ageInWeeks < 17) {
            return `${ageInWeeks} week${ageInWeeks === 1 ? '' : 's'}`;
        }

        const ageInMonths = mapDateOfBirthToAgeInMonths(dateOfBirth, dateOfReference);

        return `${ageInMonths} month${ageInMonths === 1 ? '' : 's'}`;
    }
}
