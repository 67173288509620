import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { map, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ContentService } from '../../content.service';
import { getChatTileTopics, getChatTileTopicsFailure, getChatTileTopicsSuccess } from './chat-tile.actions';
import { selectIsHomeRecommendedContentEnabled } from '@frontend/data-access/user/config-cat';
import { Store } from '@ngrx/store';

@Injectable()
export class ChatTileEffects {
    private readonly store = inject(Store);
    private readonly actions$ = inject(Actions);
    private readonly contentService = inject(ContentService);

    getChatTileTopics$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getChatTileTopics),
            concatLatestFrom(() => [this.store.select(selectIsHomeRecommendedContentEnabled)]),
            switchMap(([{ params }, isHomeRecommended]) => {
                return this.contentService.getChatTileTopics(params, isHomeRecommended).pipe(
                    map((chatTileTopics) => {
                        return getChatTileTopicsSuccess({ chatTileTopics });
                    }),
                    catchError((error: Error) => {
                        return of(getChatTileTopicsFailure({ error }));
                    }),
                );
            }),
        );
    });
}
