import { InternalIterableRegisterForPushNotificationsCommand } from '../../../commands/internal-api/third-party/iterable/internal-iterable-register-for-push-notifications.command';
import { IterablePlatformType } from '../../../models/third-party/iterable/iterable-platform-type.model';

export class InternalIterableRegisterForPushNotificationsCommandMock {
    private defaultValue: InternalIterableRegisterForPushNotificationsCommand = {
        userId: 'user-id',
        device: {
            token: 'token',
            platform: IterablePlatformType.FIREBASE_MESSAGING,
            applicationName: 'zigzag',
        },
    };

    constructor(overrides?: Partial<InternalIterableRegisterForPushNotificationsCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalIterableRegisterForPushNotificationsCommand {
        return this.defaultValue;
    }
}
