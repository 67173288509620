import { InternalBreedGroupDto } from './internal-breed-group.dto';
import { InternalCourseDto } from './internal-course.dto';
import { InternalDailyBiteDto } from './internal-daily-bite.dto';
import { InternalDevelopmentalWindowDto } from './internal-developmental-window.dto';
import { InternalFaqDto } from './internal-faq.dto';
import { InternalPageTodayDto } from './internal-page-today.dto';
import { InternalStepDto } from './internal-step.dto';
import { InternalTopicDto } from './internal-topic.dto';
import { InternalChatTileContentDto } from './internal-chat-tile-content.dto';
import { InternalChatTileTopicDto } from './internal-chat-tile-topic.dto';

export interface InternalContentDto {
    breedGroups: InternalBreedGroupDto[];
    courses: InternalCourseDto[];
    dailyBites: InternalDailyBiteDto[];
    developmentalWindows: InternalDevelopmentalWindowDto[];
    faqs: InternalFaqDto[];
    pageToday: InternalPageTodayDto;
    steps: InternalStepDto[];
    topics: InternalTopicDto[];
    chatTileContent: InternalChatTileContentDto[];
    chatTileTopic: InternalChatTileTopicDto[];
}
