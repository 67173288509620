import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[appElementRerender]',
    standalone: true,
})
export class ElementRerenderDirective {
    //  Forces rerender of DOM elements to cover shortcomings with Angular change detection

    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
    ) {}

    @Input() public set appElementRerender(_val: unknown) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
    }
}
