import { InternalLessonDto } from '../../dtos/internal-api';
import { StepType } from '../../types/step.types';
import { TopicName } from '../../types/topic.types';
import { ContentfulRichTextContentMock } from '../contentful/contentful-rich-text-content.mock';

export class InternalLessonDtoMock {
    private defaultValue: InternalLessonDto = {
        id: 1,
        contentType: StepType.LESSON,
        duration: 1,
        topic: TopicName.SIT,
        title: 'Lesson Title',
        videoId: 'video-id',
        introduction: 'Lesson Introduction',
        introductionDynamicContent: [],
        beforeYouStart: ['Before You Start'],
        steps: ['Step 1', 'Step 2'],
        stepsDynamicContent: [],
        tips: ['Tip 1', 'Tip 2'],
        tipsDynamicContent: [],
        lowRatingAnimation: 'Low Rating Animation',
        highRatingAnimation: 'High Rating Animation',
        lowRatingHtml: 'Low Rating HTML',
        highRatingHtml: 'High Rating HTML',
        location: 'Location',
        whatsNeeded: {
            toys: ['Toy 1', 'Toy 2'],
            treats: ['Treat 1', 'Treat 2'],
            equipmentAccessories: ['Equipment 1', 'Equipment 2'],
            other: ['Other 1', 'Other 2'],
        },
        audio: { url: 'Audio URL', title: 'Audio Title' },
        tags: ['Tag 1', 'Tag 2'],
        lessonAuthor: {
            authorName: 'Author name',
            authorImageUrl: 'Author image URL',
            authorBio: new ContentfulRichTextContentMock().value,
            authorCertifications: ['Author certification'],
            authorOrganisation: 'Author organisation',
        },
    };

    constructor(overrides?: Partial<InternalLessonDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalLessonDto {
        return this.defaultValue;
    }
}
