import { createFeature, createReducer, on } from '@ngrx/store';
import { BreedGroupState } from '../../models/breed-group.model';
import { getBreedGroupsSuccess } from './breed-group.actions';

const initialState: BreedGroupState = {
    breedGroups: [],
};

export const breedGroupFeature = createFeature({
    name: 'breedGroups',
    reducer: createReducer(
        initialState,
        on(
            getBreedGroupsSuccess,
            (state, { breedGroups }): BreedGroupState => ({
                ...state,
                breedGroups,
            }),
        ),
    ),
});
