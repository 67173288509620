import { InternalDynamicContentBlockDto } from '../../dtos/internal-api';
import { ContentfulRichTextContentMock } from '../contentful/contentful-rich-text-content.mock';

export class InternalDynamicContentBlockDtoMock {
    private defaultValue: InternalDynamicContentBlockDto = {
        title: 'For your pup',
        content: new ContentfulRichTextContentMock().value,
    };

    constructor(overrides?: Partial<InternalDynamicContentBlockDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalDynamicContentBlockDto {
        return this.defaultValue;
    }
}
