export interface MixpanelConfig {
    projectToken: string;
    serviceAccountUserName: string;
}

export const mixpanelConfigTest: MixpanelConfig = {
    projectToken: '16c204f96c64f8c63c6c50c3752460fa',
    serviceAccountUserName: 'zigzag-domain-api.2e13e4.mp-service-account',
};

export const mixpanelConfigProd: MixpanelConfig = {
    projectToken: '2fa6a02dd19856344a0b934deed15a31',
    serviceAccountUserName: 'zigzag-domain-api.2e13e4.mp-service-account',
};
