import { Color } from '@shared/utils/typescript';
import { ChatTileTopic } from '../models/chat-tile-topic.model';
import { ChatTileContentMock } from './chat-tile-content.mock';

export class ChatTileTopicMock {
    private defaultValue: ChatTileTopic = {
        id: '',
        title: '',
        image: '',
        colour: Color.Max,
        chatTileContent: [new ChatTileContentMock().value],
        ageBracket: '',
        isPupAtHome: false,
        isPupRescue: false,
    };

    constructor(overrides?: Partial<ChatTileTopic>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): ChatTileTopic {
        return this.defaultValue;
    }
}
