import { DomainHouseholdDto } from '../../../dtos/domain-api/user/household/domain-household.dto';
import { DomainDogDtoMock } from '../dog/domain-dog.dto.mock';
import { DomainUserDtoMock } from '../user/domain-user.dto.mock';

export class DomainHouseholdDtoMock {
    private defaultValue: DomainHouseholdDto = {
        id: 'uuid-1234',
        name: 'household-123',
        users: [new DomainUserDtoMock().value],
        dogs: [new DomainDogDtoMock().value],
    };

    constructor(overrides?: Partial<DomainHouseholdDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainHouseholdDto {
        return this.defaultValue;
    }
}
