import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { requestPasswordReset } from '@frontend/data-access/user/authentication';
import { InvalidInputErrorMessageComponent } from '@frontend/ui';
import { IonButton, IonContent, IonInput, IonItem, IonSpinner } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { selectResetPasswordPageVm } from './reset-password.page.selectors';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.page.html',
    styleUrl: './reset-password.page.scss',
    standalone: true,
    imports: [
        IonInput,
        ReactiveFormsModule,
        InvalidInputErrorMessageComponent,
        AsyncPipe,
        IonContent,
        IonItem,
        IonButton,
        IonSpinner,
    ],
})
export class ResetPasswordPageComponent {
    private readonly store = inject(Store);
    requestPasswordResetForm = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
    });

    public vm$ = this.store.select(selectResetPasswordPageVm);

    send(): void {
        const email = this.requestPasswordResetForm.value.email as string;
        this.store.dispatch(requestPasswordReset({ email }));
    }

    showEmailError(): boolean {
        return (
            this.requestPasswordResetForm.controls.email.dirty && this.requestPasswordResetForm.controls.email.invalid
        );
    }
}
