import { ContentfulTagType } from '@shared/constants';
import { InternalCourseStepDto } from '../../dtos/internal-api';
import { TopicName } from '../../types/topic.types';

export class InternalCourseStepDtoMock {
    private defaultValue: InternalCourseStepDto = {
        id: 0,
        title: 'title',
        topic: TopicName.BITING,
        content: {
            id: 0,
            type: ContentfulTagType.LESSON,
        },
    };

    constructor(overrides?: Partial<InternalCourseStepDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalCourseStepDto {
        return this.defaultValue;
    }
}
