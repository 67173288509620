import { InternalArticleDto } from './internal-article.dto';
import { InternalLessonDto } from './internal-lesson.dto';
import { InternalTopicDto } from './internal-topic.dto';

export interface InternalStepDto {
    id: string;
    title: string;
    topic: InternalTopicDto; // todo should be topic ids
    content: InternalArticleDto | InternalLessonDto;
}
