import { Color } from '@shared/utils/typescript';
import { TopicName } from '../../types/topic.types';

export interface InternalTopicDto {
    entityId: string;
    id: number;
    title: TopicName;
    intro: string;
    imageUrl: string;
    color: Color;
    pinned: boolean;
    stepIds: string[];
}
