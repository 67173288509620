import { createFeature, createReducer, on } from '@ngrx/store';
import { getClientInfoSuccess } from './client-info.actions';
import { ClientInfo } from './client-info.model';

export const initialState: ClientInfo = {
    name: undefined,
    ownerName: undefined,
    dateOfBirth: undefined,
    gender: undefined,
    dateOfArrival: undefined,
    hasArrived: undefined,
    countryCode: undefined,
    firstDayAtHomeWhenOnboarding: undefined,
    breedId: undefined,
};

export const supportPortalClientInfoFeature = createFeature({
    name: 'clientInfo',
    reducer: createReducer(
        initialState,
        on(
            getClientInfoSuccess,
            (state, action): ClientInfo => ({
                ...state,
                ...action.clientInfo,
            }),
        ),
    ),
});
