import { ActionReducer, createReducer, on } from '@ngrx/store';
import { RequestAction, RequestActionFailure, RequestActionSuccess, RequestState } from './request-reducer.model';

export const createRequestReducer = <T>(
    [requestActions, requestActionsSuccess, requestActionsFailure]: [
        RequestAction[],
        RequestActionSuccess<T>[],
        RequestActionFailure[],
    ],
    initialStateLoading = false,
): ActionReducer<RequestState<T>> => {
    const initialState = {
        loading: initialStateLoading,
    };

    return createReducer(
        initialState,
        on(
            ...requestActions,
            (state: RequestState<T>): RequestState<T> => ({
                ...state,
                loading: true,
                error: undefined,
            }),
        ),
        on(
            ...requestActionsSuccess,
            (_, action): RequestState<T> =>
                'data' in action
                    ? {
                          loading: false,
                          error: undefined,
                          data: action.data,
                      }
                    : {
                          loading: false,
                          error: undefined,
                      },
        ),
        on(
            ...requestActionsFailure,
            (state, action): RequestState<T> => ({
                ...state,
                loading: false,
                error: action.error,
            }),
        ),
    );
};
