import { ChatChannelType } from '@shared/constants';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
    activeChannelChanged,
    connectAgentSuccess,
    destroyChat,
    destroyChatFailure,
    destroyChatSuccess,
    initChatUserSuccess,
    setMessageData,
    watchChannelSuccess,
} from './chat.actions';

export interface ChatState {
    chatType: ChatChannelType | undefined;
    channelMessageCount: number;
    channelInitialized: boolean;
    activeChannel:
        | {
              id: string;
              type: string;
          }
        | undefined;
    lastMessageDate: Date | undefined;
    lastMessageSender: string | undefined;
    isDestroying: boolean;
}

export const initialState: ChatState = {
    chatType: undefined,
    channelMessageCount: -1,
    channelInitialized: false,
    activeChannel: undefined,
    lastMessageDate: undefined,
    lastMessageSender: undefined,
    isDestroying: false,
};

export const chatFeature = createFeature({
    name: 'chat',
    reducer: createReducer(
        initialState,
        on(
            setMessageData,
            (state, action): ChatState => ({
                ...state,
                lastMessageDate: action.lastMessageDate,
                channelMessageCount: action.messageCount,
                lastMessageSender: action.lastMessageSender,
            }),
        ),
        on(
            watchChannelSuccess,
            connectAgentSuccess,
            (state): ChatState => ({
                ...state,
                channelInitialized: true,
            }),
        ),
        on(
            destroyChat,
            (state): ChatState => ({
                ...state,
                channelInitialized: false,
                chatType: undefined,
                isDestroying: true,
            }),
        ),
        on(
            destroyChatFailure,
            destroyChatSuccess,
            (state): ChatState => ({
                ...state,
                isDestroying: false,
            }),
        ),
        on(activeChannelChanged, (state, { activeChannel }): ChatState => ({ ...state, activeChannel })),
        on(
            initChatUserSuccess,
            (state, { chatType }): ChatState => ({
                ...state,
                chatType,
            }),
        ),
    ),
});
