import { Pipe, PipeTransform } from '@angular/core';
import { mapDateOfBirthToAgeInWeeks } from '@shared/utils/typescript';

@Pipe({
    name: 'ageInWeeks',
    standalone: true,
})
export class AgeInWeeksPipe implements PipeTransform {
    transform(dateOfBirth: string, dateOfReference: Date): unknown {
        return this.ageInWeeks(dateOfBirth, dateOfReference);
    }

    private ageInWeeks(dateOfBirth: string, dateOfReference: Date): string {
        const age = mapDateOfBirthToAgeInWeeks(dateOfBirth, dateOfReference);
        return `${age} ${age === 1 ? 'week' : 'weeks'}`;
    }
}
