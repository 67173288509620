import { NgStyle } from '@angular/common';
import { Component, input } from '@angular/core';
import { IonCard, IonChip } from '@ionic/angular/standalone';

@Component({
    standalone: true,
    selector: 'app-generic-chip',
    templateUrl: './generic-chip.component.html',
    styleUrl: './generic-chip.component.scss',
    imports: [IonChip, NgStyle, IonCard],
})
export class GenericChipComponent {
    public text = input.required<string>();
    public textColor = input<string>('sophie');
    public backgroundColor = input<string>('maggie');
    public icon = input<string>();
    public padding = input<string>('8px 20px');
}
