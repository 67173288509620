import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { IonButton, IonInput, IonItem } from '@ionic/angular/standalone';
import { FeedbackOption, FeedbackSubmittedEvent } from './feedback.model';
import { debounceTime, merge } from 'rxjs';

@Component({
    standalone: true,
    imports: [ReactiveFormsModule, IonItem, IonButton, IonInput, NgClass, NgTemplateOutlet],
    selector: 'app-feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
    @Input() aiChat = false;
    @Input() otherFeedbackOptionLabel = 'Something else';
    @Input() feedbackOptions: FeedbackOption[] = [
        {
            key: 'not-clear',
            value: 'Not clear',
        },
        {
            key: 'too-generic',
            value: 'Too generic',
        },
        {
            key: 'incorrect',
            value: 'Incorrect',
        },
    ];

    @Output() optionSelected: EventEmitter<FeedbackOption> = new EventEmitter<FeedbackOption>();
    @Output() feedbackSubmitted: EventEmitter<FeedbackSubmittedEvent> = new EventEmitter<FeedbackSubmittedEvent>();

    commentFormControl = new FormControl();
    thumbsDownFormControl = new FormControl();
    thumbsDownReasonFormControl = new FormControl();
    selectedFeedbackOption: FeedbackOption | undefined;
    shouldShowCommentInput = false;
    thumbsDownRating: boolean | undefined = undefined;

    private otherFeedbackOption: FeedbackOption = {
        key: 'other',
        value: this.otherFeedbackOptionLabel,
    };

    ngOnInit() {
        if (!this.aiChat) {
            merge(
                this.thumbsDownFormControl.valueChanges,
                this.thumbsDownReasonFormControl.valueChanges,
                this.commentFormControl.valueChanges,
            )
                .pipe(debounceTime(500))
                .subscribe(() => this.onClickDone());
        }
    }

    onClickFeedbackOption(option: FeedbackOption) {
        this.thumbsDownReasonFormControl.patchValue(option, { emitEvent: true });
        this.commentFormControl.reset(null, { emitEvent: false });
        this.selectedFeedbackOption = option;
        this.shouldShowCommentInput = false;

        this.optionSelected.emit(option);
    }

    onSelectOtherFeedbackOption() {
        this.selectedFeedbackOption = undefined;
        this.shouldShowCommentInput = true;
        setTimeout(() => {
            const elem = document.getElementById('comment-input-control');
            if (elem && elem instanceof HTMLElement) elem.scrollIntoView();
        });

        this.optionSelected.emit(this.otherFeedbackOption);
    }

    onClickDone() {
        this.feedbackSubmitted.emit({
            thumbsDown: this.thumbsDownFormControl?.value,
            option: this.shouldShowCommentInput ? this.otherFeedbackOption : this.selectedFeedbackOption,
            comment: this.commentFormControl?.value,
        });
    }

    onClickThumbRating(thumbsDown: boolean) {
        if (thumbsDown === this.thumbsDownRating) {
            this.thumbsDownRating = undefined;
            this.thumbsDownFormControl.patchValue(null, { emitEvent: false });
        } else {
            if (thumbsDown !== this.thumbsDownRating) this.shouldShowCommentInput = false;
            this.thumbsDownFormControl.patchValue(thumbsDown, { emitEvent: true });
            this.commentFormControl.reset(null, { emitEvent: false });
            this.thumbsDownRating = thumbsDown;
        }
    }
}
