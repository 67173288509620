import { createSelector } from '@ngrx/store';
import { BreedInfo } from '../../models/breed-group.model';
import { breedGroupFeature } from './breed-group.reducer';

export const selectBreeds = createSelector(breedGroupFeature.selectBreedGroups, (breedGroups): BreedInfo[] =>
    breedGroups
        .flatMap((breedGroup) =>
            breedGroup.breeds.map((breed) => {
                const { breeds, ...breedGroupWithoutBreeds } = breedGroup;
                return { ...breed, breedGroup: breedGroupWithoutBreeds };
            }),
        )
        .sort((a, b) => a.name.localeCompare(b.name)),
);
