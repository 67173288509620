import { ContentfulTagType } from '@shared/constants';
import { TopicName } from '@shared/content-api-interface';
import { Article } from './article.model';
import { Lesson } from './lesson.model';
import { Topic } from './topic.model';
import { LoadingState } from '@frontend/data-access/shared-models';

export interface StepState {
    steps: ContentStep[];
    loading: LoadingState;
}

export interface CourseStep {
    id: number;
    title: string;
    topic: TopicName;
    content: {
        id: number;
        type: ContentfulTagType;
    };
}

export interface ContentStep {
    id: string;
    title: string;
    topic: Topic;
    content: Article | Lesson;
}
