import { Component } from '@angular/core';

@Component({
    standalone: true,
    selector: 'app-endorsements',
    templateUrl: './endorsements.component.html',
    styleUrls: ['./endorsements.component.scss'],
    imports: [],
})
export class EndorsementsComponent {}
