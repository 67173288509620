import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'findAndReplace',
    standalone: true,
})
export class FindAndReplaceTextPipe implements PipeTransform {
    transform(value: string, ...args: { find: string; replace: string }[]): string {
        let result = value;
        args.forEach((arg) => {
            result = result.replace(arg.find, arg.replace);
        });

        return result;
    }
}
