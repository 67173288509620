import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller';
import { createAction, props } from '@ngrx/store';

export const routeTo = createAction(
    '[Router] Route to',
    props<{ commands: string | string[]; extras?: NavigationOptions; isAnimated?: boolean }>(),
);

export const routeToPrevious = createAction(
    '[Router] Route to Previous',
    props<{ commands?: string | string[]; extras?: NavigationOptions; isAnimated?: boolean }>(),
);

export const forceAppRefresh = createAction('[Router] Force App Refresh');

export const openPrivacy = createAction('[Router] Open Privacy', props<{ countryCode: string }>());

export const openTerms = createAction('[Router] Open Terms', props<{ countryCode: string }>());

export const openCookieNotice = createAction('[Router] Open Cookie Notice', props<{ countryCode: string }>());

export const openUrlWindow = createAction(
    '[Router] Open Url Window',
    props<{ url: string; openInNewWindow: boolean }>(),
);
