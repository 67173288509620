import { Gender } from '@shared/user-domain';

export class DomainDogDto {
    id!: string;
    name: string | null = null;
    dateOfBirth: string | null = null;
    dateOfArrival: string | null = null;
    hasArrived: boolean | null = null;
    gender: Gender | null = null;
    dateOfOnboarding: string | null = null;
    breedId: string | null = null;
    isRescue: boolean | null = null;
    isApproximateDateOfBirth: boolean | null = null;
}
