import { createFeature, createReducer, on } from '@ngrx/store';
import { CourseState } from '../../models/course.model';
import { getCourses, getCoursesFailure, getCoursesSuccess } from './course.actions';
import { LoadingState } from '@frontend/data-access/shared-models';

export const initialState: CourseState = {
    courses: [],
    loading: LoadingState.INIT,
};

export const coursesFeature = createFeature({
    name: 'courses',
    reducer: createReducer(
        initialState,
        on(
            getCourses,
            (state): CourseState => ({
                ...state,
                loading: LoadingState.LOADING,
            }),
        ),
        on(
            getCoursesFailure,
            (state): CourseState => ({
                ...state,
                loading: LoadingState.LOADED,
            }),
        ),
        on(
            getCoursesSuccess,
            (state, { courses }): CourseState => ({
                ...state,
                courses,
                loading: LoadingState.LOADED,
            }),
        ),
    ),
});
