import { BreedInfo } from '../models/breed-group.model';
import { ContentBreedMock } from './content-breed.mock';

export class BreedInfoMock {
    private defaultValue: BreedInfo = {
        ...new ContentBreedMock().value,
        breedGroup: {
            id: '',
            name: '',
        },
    };

    constructor(overrides?: Partial<BreedInfo>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): BreedInfo {
        return this.defaultValue;
    }
}
