<ion-item button="true" lines="none" class="card-item" [detail]="!content.hideRightArrow">
    <div class="card-item__row">
        <div class="card-item__image-container">
            <img
                class="card-item__img {{ content.rounded ? 'card-item--rounded' : '' }}"
                [src]="content.imageUrl"
                slot="start"
                alt=""
            />
        </div>
        <section class="card-item__text">
            <div class="card-item__title-container">
                <h3>{{ content.title }}</h3>
            </div>

            <div class="card-item__subtitle">
                <p class="s">
                    {{ content.subtitle }}
                </p>
            </div>
        </section>
    </div>
</ion-item>
