import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { checkmarkSharp } from 'ionicons/icons';
import { MultiSelectOption } from './multi-select.model';
import { NgStyle } from '@angular/common';

// TODO: NBSon - look into refactoring this component, too many outputs, should use ControlValueAccessor, should be generic if possible
@Component({
    selector: 'app-multi-select',
    templateUrl: './multi-select.component.html',
    styleUrls: ['./multi-select.component.scss'],
    standalone: true,
    imports: [IonIcon, NgStyle],
})
export class MultiSelectComponent {
    @Input() multiSelectOptions: MultiSelectOption[] = [];

    @Output() public valueChanged = new EventEmitter<MultiSelectOption[]>();
    @Output() public optionChanged = new EventEmitter<MultiSelectOption>();
    @Output() public itemClicked = new EventEmitter<string | number>();

    constructor() {
        addIcons({ checkmarkSharp });
    }

    selectSpecialNeed(i: number): void {
        this.multiSelectOptions[i].selected = !this.multiSelectOptions[i].selected;

        this.valueChanged.emit(this.multiSelectOptions);
        this.optionChanged.emit(this.multiSelectOptions[i]);
        this.itemClicked.emit(this.multiSelectOptions[i].value);
    }
}
