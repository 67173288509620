import { ContentBreed } from '../models/breed-group.model';

export class ContentBreedMock {
    private defaultValue: ContentBreed = {
        id: '',
        name: '',
    };

    constructor(overrides?: Partial<ContentBreed>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): ContentBreed {
        return this.defaultValue;
    }
}
