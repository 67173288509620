import { Injectable, inject } from '@angular/core';
import { logoutSuccess } from '@frontend/data-access/user/authentication';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { forceAppRefresh } from '@frontend/data-access/router';

@Injectable()
export class SupportRouterEffects {
    private readonly actions$ = inject(Actions);
    redirectToRoot$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(logoutSuccess),
            map(() => {
                return forceAppRefresh();
            }),
        );
    });
}
