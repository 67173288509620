import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

export function addTokenToHeader(
    token: Observable<string | null | undefined>,
    request: HttpRequest<unknown>,
    next: HttpHandler,
) {
    return token.pipe(
        take(1),
        switchMap((token) => {
            if (!token) {
                return next.handle(request);
            }

            return next.handle(
                request.clone({
                    headers: request.headers.set('Authorization', `Bearer ${token}`),
                }),
            );
        }),
    );
}

export const addApiKeyAndJwtToRequest = (
    token: Observable<string | null | undefined>,
    apiKey: string,
    request: HttpRequest<unknown>,
    next: HttpHandler,
) => {
    return token.pipe(
        take(1),
        switchMap((token) => {
            return next.handle(
                request.clone({
                    setHeaders: {
                        ...(token && { Authorization: `Bearer ${token}` }),
                        'X-API-KEY': apiKey,
                    },
                }),
            );
        }),
    );
};
