import { ChatTileContent } from './chat-tile-content.model';
import { Color } from '@shared/utils/typescript';

export interface ChatTileTopicsState {
    chatTileTopics: ChatTileTopic[];
}

export interface ChatTileTopic {
    id: string;
    title: string;
    image: string;
    colour: Color;
    chatTileContent: ChatTileContent[];
    ageBracket: string;
    isPupAtHome: boolean;
    isPupRescue: boolean;
}
