import { DomainPartnerDto } from '../partner/domain-partner.dto';
import { DomainBaseUserDto } from '../user/domain-user.dto';

export class DomainTrainerDto {
    id?: string;
    firstName?: string;
    lastName?: string;
    companyName?: string;
    email?: string;
    postalAddress?: string;
    logoUrl?: string;
    isActive?: boolean;
    websiteUrl?: string;
    partnerId?: string;
    termsAccepted?: boolean;
    termsAcceptedTimestamp?: string;
    countryCode?: string;
    isPPGTrainer?: boolean;
    usersAdded?: DomainBaseUserDto[];
    partner?: DomainPartnerDto;
}
