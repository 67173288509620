<app-agent-header [isMenuOpen]="isMenuOpen" (toggleMenu)="isMenuOpen = $event"></app-agent-header>

@if (vm$ | async; as vm) {
<ion-content [fullscreen]="true">
    <div id="root" class="agent-chat">
        @if (vm.initialized) {
        <ion-refresher slot="fixed" (ionRefresh)="handleRefresh($event)">
            <ion-refresher-content></ion-refresher-content>
        </ion-refresher>
        <stream-channel-list
            (click)="isMenuOpen = false"
            class="agent-chat-page__channel-list agent-chat-page__menu-{{ isMenuOpen ? 'open' : 'close' }}"
        >
        </stream-channel-list>
        } @else {
        <app-chat-channel-list-skeleton class="agent-chat-page__skeleton"></app-chat-channel-list-skeleton>
        } @if (vm.initialized) {
        <stream-channel class="agent-chat-page__channel">
            <stream-channel-header></stream-channel-header>
            @if (!isSidebarOpen) {
            <button class="zz-button agent-chat-page__sidebar-button-left" (click)="isSidebarOpen = true">
                <i class="fa-lg fa-solid fa-chevron-left"></i>
            </button>
            }
            <stream-message-list></stream-message-list>
            <stream-message-input class="agent-chat-page__input-{{ isMenuOpen ? 'open' : '' }}"></stream-message-input>
        </stream-channel>

        <div
            class="agent-chat-page__right-container agent-chat-page__right-container-{{ isSidebarOpen ? 'open' : 'close' }}"
        >
            @if (isSidebarOpen) {
            <button class="zz-button agent-chat-page__sidebar-button-right" (click)="isSidebarOpen = false">
                <i class="fa-lg fa-solid fa-chevron-right"></i>
            </button>
            }
            <app-user-information-sidebar></app-user-information-sidebar>
        </div>
        }
        <ng-template #emojiPickerTemplate let-emojiInput$="emojiInput$">
            <app-emoji-picker [emojiInput$]="emojiInput$"></app-emoji-picker>
        </ng-template>
    </div>
</ion-content>
}
