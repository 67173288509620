import { StepType, TopicName } from '@shared/content-api-interface';
import { Article } from '../models/article.model';
import { ContentfulRichTextContentMock } from '@shared/content-api-interface';

export class ArticleMock {
    private defaultValue: Article = {
        contentType: StepType.ARTICLE,
        id: 1,
        duration: 1,
        title: 'title',
        topic: TopicName.RECALL,
        imageUrl: '',
        authorImageUrl: '',
        content: '',
        richContent: undefined,
        author: 'Lorna',
        tags: [],
        articleAuthor: {
            authorName: 'author name',
            authorImageUrl: 'author URL',
            authorBio: new ContentfulRichTextContentMock().value,
            authorOrganisation: 'Author organisation',
            authorCertifications: ['Author certifications'],
        },
    };

    constructor(overrides?: Partial<Article>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): Article {
        return this.defaultValue;
    }
}
