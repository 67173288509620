import { createFeature, createReducer, on } from '@ngrx/store';
import { DevelopmentalWindowState } from '../../models/developmental-window.model';
import { getDevelopmentalWindowsSuccess } from './developmental-window.actions';

const initialState: DevelopmentalWindowState = {
    developmentalWindows: [],
};

export const developmentalWindowsFeature = createFeature({
    name: 'developmentalWindows',
    reducer: createReducer(
        initialState,
        on(
            getDevelopmentalWindowsSuccess,
            (state, { developmentalWindows }): DevelopmentalWindowState => ({
                ...state,
                developmentalWindows,
            }),
        ),
    ),
});
