import { StepType, TopicName } from '@shared/content-api-interface';
import { RichTextContent } from 'contentful';
import { DynamicContentBlock } from './dynamic-content-block.model';

export interface Lesson {
    id: number;
    contentType: StepType.LESSON;
    duration: number;
    topic: TopicName;
    title: string;
    videoId: string;
    introduction: string;
    introductionDynamicContent: DynamicContentBlock[];
    beforeYouStart: string[];
    steps: string[];
    stepsDynamicContent: DynamicContentBlock[];
    tips: string[];
    tipsDynamicContent: DynamicContentBlock[];
    lowRatingAnimation: string;
    highRatingAnimation: string;
    lowRatingHtml: string;
    highRatingHtml: string;
    location?: string; // todo make me a LessonLocation
    whatsNeeded?: { toys: string[]; treats: string[]; equipmentAccessories: string[]; other: string[] };
    audio:
        | {
              url: string;
              title: string;
          }
        | undefined;
    tags: string[];
    lessonAuthor:
        | {
              authorName: string;
              authorImageUrl: string;
              authorBio: RichTextContent;
              authorCertifications: string[];
              authorOrganisation: string;
          }
        | undefined;
}
