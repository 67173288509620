import { createFeature, createReducer, on } from '@ngrx/store';
import { setTime } from './time.actions';
import { AppTimeState } from './time.model';

export const timeFeatureKey = 'time';

const initialState: AppTimeState = {
    today: new Date(),
};

export const timeFeature = createFeature({
    name: timeFeatureKey,
    reducer: createReducer(
        initialState,
        on(
            setTime,
            (state, action): AppTimeState => ({
                ...state,
                today: action.currentTime,
            }),
        ),
    ),
});
