import { Transform } from 'class-transformer';
import { IsDate, IsInt } from 'class-validator';

export class DomainCreateWooCommerceUserCommand {
    @IsInt()
    wooCommerceUserId!: number;

    @IsDate()
    @Transform(({ value }) => new Date(value))
    date!: Date;
}
