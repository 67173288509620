import { Color } from '@shared/utils/typescript';
import { InternalDevelopmentalWindowDto } from '../../dtos/internal-api';
import { InternalTopicDtoMock } from './internal-topic-dto.mock';
import { ContentfulRichTextContentMock } from '../contentful/contentful-rich-text-content.mock';

export class InternalDevelopmentalWindowsDtoMock {
    private defaultValue: InternalDevelopmentalWindowDto = {
        title: 'Developmental Window',
        image: 'https://developmental-window-image.doesnotexist.com/funny-dog-laughing.jpg',
        textMain: new ContentfulRichTextContentMock().value,
        textImportance: new ContentfulRichTextContentMock().value,
        textProgramme: new ContentfulRichTextContentMock().value,
        textCourses: new ContentfulRichTextContentMock().value,
        textNextCourse: new ContentfulRichTextContentMock().value,
        startAtDays: 1,
        startAtText: 'This is the start text for the developmental window',
        endAtDays: 2,
        color: Color.Lola,
        linkedTopics: [new InternalTopicDtoMock({ id: 1 }).value, new InternalTopicDtoMock({ id: 2 }).value],
    };

    constructor(overrides?: Partial<InternalDevelopmentalWindowDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalDevelopmentalWindowDto {
        return this.defaultValue;
    }
}
