export interface NumericAlphabeticalItem {
    primary: number;
    secondary: string;
}

export function sortByNumericThenAlphabetical(a: NumericAlphabeticalItem, b: NumericAlphabeticalItem): number {
    if (a.primary === b.primary) {
        return a.secondary.localeCompare(b.secondary);
    } else {
        return b.primary - a.primary;
    }
}
