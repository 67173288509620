import { InternalIterableUpsertUserCommand } from '../../../commands/internal-api/third-party/iterable/internal-iterable-upsert-user.command';
import { InternalIterableDataFieldsMock } from './internal-iterable-data-fields.mock';

export class InternalIterableUpsertUserCommandMock {
    private defaultValue: InternalIterableUpsertUserCommand = {
        email: 'jasper@mattijs.milos',
        dataFields: new InternalIterableDataFieldsMock().value,
    };

    constructor(overrides?: Partial<InternalIterableUpsertUserCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalIterableUpsertUserCommand {
        return this.defaultValue;
    }
}
