import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IonIcon } from '@ionic/angular/standalone';
import { addIcons } from 'ionicons';
import { sadOutline } from 'ionicons/icons';

@Component({
    selector: 'app-invalid-input-error-message',
    templateUrl: './invalid-input-error-message.component.html',
    styleUrl: './invalid-input-error-message.component.scss',
    standalone: true,
    imports: [IonIcon],
})
export class InvalidInputErrorMessageComponent {
    @Input({ required: true }) errorMessage!: string;
    @Input() linkText: string | undefined = undefined;
    @Output() public linkClicked = new EventEmitter();

    constructor() {
        addIcons({ sadOutline });
    }
}
