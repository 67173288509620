<ion-chip
    class="generic-chip"
    [ngStyle]="{
        background: 'var(--ion-color-' + backgroundColor() + ')',
        color: 'var(--ion-color-' + textColor() + ')',
        padding: padding(),
    }"
>
    <p class="generic-chip__text s" [ngStyle]="{ color: 'var(--ion-color-' + textColor() + ')' }">{{ text() }}</p>
    @if (icon()) {
        <i class="{{ icon() }} generic-chip__icon"></i>
    }
</ion-chip>
