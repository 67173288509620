import { inject, Injectable } from '@angular/core';
import { connectAgent } from '@frontend/data-access/chat';
import { getBreedGroups } from '@frontend/data-access/contentful';
import { ContentfulLocale, PUPPY_COACH_ID } from '@shared/constants';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { zip } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { initAnalytics } from '@frontend/data-access/analytics';
import { getProfileSuccess } from '../../../data-access/profile/profile.actions';
import { STREAM_CHAT_AGENT_TOKEN } from '../../../injection-tokens';
import { chatPageInitialized } from './agent-chat.page.actions';

@Injectable()
export class AgentChatPageEffects {
    private readonly actions$ = inject(Actions);
    private readonly agentToken = inject<string>(STREAM_CHAT_AGENT_TOKEN);

    chatPageInitialized$ = createEffect(() => {
        return zip([
            this.actions$.pipe(ofType(chatPageInitialized)),
            this.actions$.pipe(ofType(getProfileSuccess)),
        ]).pipe(
            concatMap(() => {
                return [
                    initAnalytics(),
                    getBreedGroups({
                        params: {
                            locale: ContentfulLocale.EN_GB,
                        },
                    }),
                    connectAgent({
                        token: this.agentToken,
                        profileId: PUPPY_COACH_ID,
                    }),
                ];
            }),
        );
    });
}
