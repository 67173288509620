export interface RadioWithExplanationItem {
    title: string;
    value: string;
    explainMore?: {
        placeholder: string;
    };
}

export interface RadioWithExplanationResult {
    complete: boolean;
    answer: {
        value: string;
        explanation: string;
    };
}
