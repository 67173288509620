import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { GeoLocationEffects } from './geo-location.effects';
import { geoLocationFeatureKey, geoLocationReducer } from './geo-location.reducer';

@NgModule({
    providers: [provideState(geoLocationFeatureKey, geoLocationReducer), provideEffects([GeoLocationEffects])],
})
export class ZigzagFrontendDataAccessGeoLocationModule {}
