import { Asset, Entry, RichTextContent } from 'contentful';
import { ContentfulAuthor } from './contentful-author.model';

export interface ContentfulDailyBite {
    id: number;
    title: string;
    imageUrl: Asset;
    rank: number;
    author: string;
    content: RichTextContent;
    dailyBiteAuthor: Entry<ContentfulAuthor> | undefined;
}
