import { Component, Input } from '@angular/core';
import { IonItem } from '@ionic/angular/standalone';
import { CardItemModel } from './models/card-item.model';

@Component({
    standalone: true,
    selector: 'app-card-item',
    templateUrl: './card-item.component.html',
    styleUrls: ['./card-item.component.scss'],
    imports: [IonItem],
})
export class CardItemComponent {
    @Input({ required: true }) public content!: CardItemModel;
}
