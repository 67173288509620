import { StateType, StepProgressType } from '@shared/user-domain';

export interface CreateProgressCommand {
    userId: string;
    itemId: number;
    timestamp: number;
    stateType: StateType;
    progressType: StepProgressType;
    repeatRank: number | null;
    rating?: number;
}
