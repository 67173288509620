import { Color } from '@shared/utils/typescript';
import { RichTextContent } from 'contentful';
import { InternalCourseStepDto } from './internal-course-step.dto';
import { CourseType } from '../../types/course.type';

export interface InternalCourseDto {
    id: string;
    title: string;
    subTitle: string;
    rank: number;
    stepIds: string[];
    steps: InternalCourseStepDto[];
    splits: number[];
    introduction: string;
    explanation: RichTextContent;
    color: Color;
    imageUrl: string;
    type: CourseType;
}
