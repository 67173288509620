import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ContentService } from '../../content.service';
import { getFaqs, getFaqsFailure, getFaqsSuccess } from './faq.actions';

@Injectable()
export class FaqEffects {
    private readonly actions$ = inject(Actions);
    private readonly contentService = inject(ContentService);
    getFaqs$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getFaqs),
            switchMap(({ params }) => {
                return this.contentService.getFaqs(params).pipe(
                    map((faqs) => {
                        return getFaqsSuccess({ faqs });
                    }),
                    catchError((error: Error) => {
                        console.error(error);
                        return of(getFaqsFailure({ error }));
                    }),
                );
            }),
        );
    });
}
