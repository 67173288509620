import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { addTag, removeTag } from '@frontend/data-access/user/chat-tags';
import { IonContent, IonSelect, IonSelectOption } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { selectTagsVm } from './store/chat-tags.selectors';

@Component({
    selector: 'app-chat-tags',
    templateUrl: './chat-tags.component.html',
    styleUrl: './chat-tags.component.scss',
    standalone: true,
    imports: [ReactiveFormsModule, FormsModule, AsyncPipe, IonContent, IonSelect, IonSelectOption],
})
export class ChatTagsComponent {
    private readonly store = inject(Store);
    public value: string | undefined = undefined;
    public vm$ = this.store.select(selectTagsVm);

    removeTag(tagId: string, selectedChannelId: string): void {
        this.store.dispatch(removeTag({ id: tagId, channelId: selectedChannelId }));
    }

    addTag(event: any, selectedChannelId: string): void {
        if (event.detail.value !== '') {
            this.store.dispatch(addTag({ id: event.detail.value, channelId: selectedChannelId }));
        }
    }

    reset(): void {
        this.value = undefined;
    }
}
