import { createAction, props } from '@ngrx/store';

export const chatPageInitialized = createAction('[Agent Chat] Chat Page Initialized');

export const agentSentMessage = createAction(
    '[Agent Chat] Agent Sent Message',
    props<{
        imagesAttached: number;
        videosAttached: number;
        audioAttached: number;
    }>(),
);
