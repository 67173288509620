import { RichTextContent } from 'contentful';

export interface FaqState {
    faqs: Faq[];
}

export interface Faq {
    id: number;
    rank: number;
    title: string;
    content: RichTextContent;
    mixpanelId: string;
}
