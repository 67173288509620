import { Color } from '@shared/utils/typescript';
import { RichTextContent } from 'contentful';
import { DevelopmentalWindow } from '../models/developmental-window.model';

export class DevelopmentalWindowMock {
    private defaultValue: DevelopmentalWindow = {
        title: 'Juvenile period',
        image: '',
        linkedTopics: [],
        textMain: {} as RichTextContent,
        startAtDays: 84,
        endAtDays: 149,
        startAtText: '12 weeks old',
        color: Color.Bailey,
    };

    constructor(overrides?: Partial<DevelopmentalWindow>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DevelopmentalWindow {
        return this.defaultValue;
    }
}
