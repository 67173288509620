import { Injectable, inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    private readonly authenticationService = inject(AuthenticationService);
    private readonly router = inject(Router);

    canActivate(): Observable<boolean | UrlTree> {
        return this.authenticationService.isLoggedIn().pipe(
            take(1),
            map((loggedIn) => (loggedIn ? loggedIn : this.router.createUrlTree(['auth']))),
        );
    }
}
