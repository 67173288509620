import { InternalHouseholdDto } from '../../../dtos/internal-api/user/household/household.dto';
import { InternalUserDtoMock } from '../user/internal-user.dto.mock';
import { InternalDogDtoMock } from '../dog/internal-dog.dto.mock';

export class InternalHouseholdDtoMock {
    private defaultValue: InternalHouseholdDto = {
        id: 'uuid-1234',
        name: 'household-123',
        users: [new InternalUserDtoMock().value],
        dogs: [new InternalDogDtoMock().value],
    };

    constructor(overrides?: Partial<InternalHouseholdDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalHouseholdDto {
        return this.defaultValue;
    }
}
