import { InternalArticleDto } from '../../dtos/internal-api';
import { StepType } from '../../types/step.types';
import { TopicName } from '../../types/topic.types';
import { ContentfulRichTextContentMock } from '../contentful/contentful-rich-text-content.mock';

export class InternalArticleDtoMock {
    private defaultValue: InternalArticleDto = {
        id: 1,
        contentType: StepType.ARTICLE,
        title: 'Article Title',
        duration: 1,
        topic: TopicName.SIT,
        imageUrl: 'Image URL',
        author: 'Author',
        authorImageUrl: 'Author Image URL',
        tags: ['Tag 1', 'Tag 2'],
        content: 'Content',
        richContent: new ContentfulRichTextContentMock({ nodeType: 'embedded-entry-block' }).value,
        articleAuthor: {
            authorName: 'Author name',
            authorImageUrl: 'Author image URL',
            authorBio: new ContentfulRichTextContentMock().value,
            authorCertifications: ['Author certification'],
            authorOrganisation: 'Author organisation',
        },
    };

    constructor(overrides?: Partial<InternalArticleDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalArticleDto {
        return this.defaultValue;
    }
}
