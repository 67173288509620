import { inject, Injectable } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import {
    createUserWithEmailAndPassword,
    getAdditionalUserInfo,
    GoogleAuthProvider,
    idToken,
    indexedDBLocalPersistence,
    initializeAuth,
    OAuthProvider,
    sendPasswordResetEmail,
    signInWithCredential,
    signInWithEmailAndPassword,
    signOut,
    user,
} from '@angular/fire/auth';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Store } from '@ngrx/store';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { logInWithAppleOptions } from '../consts/login-with-apple-options.const';
import { watchForLoginStateChange } from '../store/authentication.actions';
import { mapFireBaseUserToUser, mapFireBaseUserWithAdditionalUserInfoToUser } from '../utils/authentication.map';
import { AuthenticationService } from './authentication.service';
import { User } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class FirebaseAuthenticationService extends AuthenticationService {
    private readonly app = inject(FirebaseApp);
    private readonly store = inject(Store);
    private auth = initializeAuth(this.app, {
        persistence: [indexedDBLocalPersistence],
    });

    public token$ = idToken(this.auth);
    public user$: Observable<User | undefined> = user(this.auth).pipe(
        map((user) => {
            if (!user || !user.email) {
                return undefined;
            }

            return {
                id: user.uid,
                email: user.email,
                signInProvider: user.providerData[0].providerId,
            };
        }),
    );

    private userSubject = new BehaviorSubject<User | undefined>(undefined);

    constructor() {
        super();
        this.user$.subscribe(this.userSubject);
    }

    public get user(): User | undefined {
        return this.userSubject.getValue();
    }

    public initialize(): void {
        this.store.dispatch(watchForLoginStateChange());
    }

    public signUpWithEmailAndPassword(email: string, password: string): Observable<{ user: User }> {
        return from(createUserWithEmailAndPassword(this.auth, email.trim(), password)).pipe(
            switchMap((credential) => of(mapFireBaseUserToUser(credential.user))),
        );
    }

    public loginWithEmailAndPassword(email: string, password: string): Observable<{ user: User }> {
        return from(signInWithEmailAndPassword(this.auth, email.trim(), password)).pipe(
            switchMap((credential) => of(mapFireBaseUserToUser(credential.user))),
        );
    }

    public loginWithGoogle() {
        return from(FirebaseAuthentication.signInWithGoogle()).pipe(
            switchMap((signInResponse) => {
                const credential = GoogleAuthProvider.credential(signInResponse.credential?.idToken);

                // Here, the additionalUserInfo returns isNewUser true for new users
                const additionalUserInfo = signInResponse.additionalUserInfo;

                return from(signInWithCredential(this.auth, credential)).pipe(
                    switchMap((credential) => {
                        // Here, the additionalUserInfo returns isNewUser false for new users, since it's created in the previous step

                        return of(mapFireBaseUserWithAdditionalUserInfoToUser(credential.user, additionalUserInfo!));
                    }),
                );
            }),
        );
    }

    public loginWithApple() {
        // authenticate the user in apple
        return from(SignInWithApple.authorize(logInWithAppleOptions)).pipe(
            switchMap((signInResponse) => {
                // sign the user up in firebase
                const provider = new OAuthProvider('apple.com');
                const credential = provider.credential({ idToken: signInResponse.response.identityToken });
                return from(signInWithCredential(this.auth, credential)).pipe(
                    switchMap((credential) => {
                        // return the user and additional info from Firebase
                        const additionalUserInfo = getAdditionalUserInfo(credential);
                        return of(mapFireBaseUserWithAdditionalUserInfoToUser(credential.user, additionalUserInfo!));
                    }),
                );
            }),
        );
    }

    public logout(): Observable<void> {
        return from(signOut(this.auth));
    }

    public isLoggedIn(): Observable<boolean> {
        return this.user$.pipe(map((user): boolean => !!user));
    }

    public requestPasswordReset(email: string): Observable<void> {
        return from(sendPasswordResetEmail(this.auth, email.trim()));
    }

    public getSignInProvider(): Observable<string> {
        return of(this.auth.currentUser!.providerData[0].providerId);
    }
}
