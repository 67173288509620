import { ChatChannelType } from '@shared/constants';
import { createAction, props } from '@ngrx/store';
import { ChatChannelInfo, ChatConnectChannelParams } from '../../models/chat.model';

export const initChatUser = createAction(
    '[Chat] Init Chat User',
    props<{ profileId: string; token: string; ownerName?: string; chatType: ChatChannelType }>(),
);
export const initChatUserSuccess = createAction(
    '[Chat] Init Chat User Success',
    props<{ channels: ChatChannelInfo[]; chatType: ChatChannelType }>(),
);
export const initChatUserFailure = createAction('[Chat] Init Chat User Failure', props<{ error: Error }>());

export const createChannel = createAction(
    '[Chat] Create Channel',
    props<{ params: ChatConnectChannelParams; chatType: ChatChannelType }>(),
);
export const createChannelSuccess = createAction(
    '[Chat] Create Channel Success',
    props<{ channelId: string; chatType: ChatChannelType }>(),
);
export const createChannelFailure = createAction('[Chat] Create Channel Failure', props<{ error: Error }>());

export const watchChannel = createAction(
    '[Chat] Watch Channel',
    props<{ channelId: string; chatType: ChatChannelType }>(),
);
export const watchChannelSuccess = createAction('[Chat] Watch Channel Success', props<{ chatType: ChatChannelType }>());
export const watchChannelFailure = createAction('[Chat] Watch Channel Failure', props<{ error: Error }>());

export const connectAgent = createAction('[Chat] Connect Agent', props<{ profileId: string; token: string }>());
export const connectAgentSuccess = createAction('[Chat] Connect Agent Success');
export const connectAgentFailure = createAction('[Chat] Connect Agent Failure', props<{ error: Error }>());

export const destroyChat = createAction('[Chat] Destroy');
export const destroyChatSuccess = createAction('[Chat] Destroy Success');
export const destroyChatFailure = createAction('[Chat] Destroy Failure', props<{ error: Error }>());

export const activeChannelChanged = createAction(
    '[Chat] Active Channel Changed',
    props<{
        activeChannel:
            | {
                  id: string;
                  type: ChatChannelType;
              }
            | undefined;
    }>(),
);

export const setMessageData = createAction(
    '[Chat] Set Message Data',
    props<{
        messageCount: number;
        lastMessageDate: Date;
        lastMessageSender: string | undefined;
        channelType: ChatChannelType | undefined;
    }>(),
);
