import { authenticationFeature, mapAuthenticationError } from '@frontend/data-access/user/authentication';
import { createSelector } from '@ngrx/store';

export const selectResetPasswordPageVm = createSelector(
    authenticationFeature.selectResetPasswordError,
    authenticationFeature.selectResetPasswordLoading,
    authenticationFeature.selectResetPasswordSuccess,
    (resetPasswordError, isResetPasswordLoading, isResetPasswordSuccess) => ({
        error: mapAuthenticationError(resetPasswordError?.code),
        isLoading: isResetPasswordLoading,
        shouldShowSuccessMessage: isResetPasswordSuccess,
    }),
);
