import { StepType, TopicName } from '@shared/content-api-interface';
import { RichTextContent } from 'contentful';

export interface Article {
    id: number;
    contentType: StepType.ARTICLE;
    title: string;
    duration: number;
    topic: TopicName;
    imageUrl: string;
    author: string;
    authorImageUrl: string;
    tags: string[];
    content?: string;
    richContent?: RichTextContent;
    articleAuthor:
        | {
              authorName: string;
              authorImageUrl: string;
              authorBio: RichTextContent;
              authorCertifications: string[];
              authorOrganisation: string;
          }
        | undefined;
}
