import { userPaths } from './user.paths';

const userCollection = `/api/${userPaths.userPath}`;
const progressCollection = `/api/${userPaths.progressPath}`;
const courseProgressCollection = `/api/${userPaths.courseProgressPath}`;
const rescheduledStepCollection = `/api/${userPaths.rescheduledStepPath}`;
const paymentCollection = `/api/${userPaths.paymentPath}`;
const chatCollection = `/api/${userPaths.chatPath}`;
const agentCollection = `/api/${userPaths.agentPath}`;
const progressById = (id: string): string => `${progressCollection}/${id}`;
const freeContentById = (id: string): string => `${paymentCollection}/free-content/${id}`;
export const userEndpoints = {
    userCollection,
    progressCollection,
    courseProgressCollection,
    rescheduledStepCollection,
    paymentCollection,
    chatCollection,
    agentCollection,
    progressById,
    freeContentById,
};
