import { SettingKeyValue } from 'configcat-js';
import { ConfigCatFlag, ConfigCatFlagState } from './config-cat.model';

export const mapConfigCatResponseToFlags = (response: SettingKeyValue[]): ConfigCatFlagState => {
    return {
        configCatFlags: response.map((entry) => ({
            name: entry.settingKey,
            flagValue: entry.settingValue,
        })) as ConfigCatFlag[],
    };
};

export const filterConfigCatStateFlag = (flagName: string, flags: ConfigCatFlag[]): boolean =>
    flags.filter((flag) => flag.name === flagName)[0]?.flagValue ?? false;
