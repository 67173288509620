import { Component } from '@angular/core';
import { IonSkeletonText } from '@ionic/angular/standalone';

@Component({
    standalone: true,
    imports: [IonSkeletonText],
    selector: 'app-chat-channel-list-skeleton',
    templateUrl: 'chat-channel-list-skeleton.component.html',
    styleUrl: 'chat-channel-list-skeleton.component.scss',
})
export class ChatChannelListSkeletonComponent {
    numberOfSkeletonMessages = [1, 2, 3, 4, 5, 6, 7, 8, 9];
}
