import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { logout } from '@frontend/data-access/user/authentication';
import { IonButton, IonHeader, IonTitle, IonToolbar } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';

@Component({
    selector: 'app-agent-header',
    templateUrl: './agent-header.component.html',
    styleUrl: 'agent-header.component.scss',
    standalone: true,
    imports: [IonHeader, IonToolbar, IonTitle, IonButton],
})
export class AgentHeaderComponent {
    private readonly store = inject(Store);
    @Input() isMenuOpen = false;
    @Output() toggleMenu = new EventEmitter<boolean>();

    public logoutAgent() {
        this.store.dispatch(logout());
    }
}
