import { BreedInfo } from '@frontend/data-access/contentful';
import { ClientInfo } from './client-info.model';
import { InternalUserDto } from '@shared/user-api-interface';

export const mapBaseUserDtoToClientInfo = (dto: InternalUserDto): ClientInfo => {
    return {
        firstDayAtHomeWhenOnboarding: dto.firstDayAtHomeWhenOnboarding,
        name: dto.name,
        ownerName: dto.ownerName,
        dateOfBirth: dto.dateOfBirth,
        breedId: dto.breedId,
        gender: dto.gender,
        dateOfArrival: dto.dateOfArrival,
        hasArrived: dto.hasArrived,
        countryCode: dto.countryCode,
    };
};

export const getBreedInfo = (clientInfo: ClientInfo, breeds: BreedInfo[]): BreedInfo | undefined => {
    return breeds.find((breed) => clientInfo.breedId === breed.id);
};
