import { DomainPatchUserAppAccessGrantedCommand } from '../../../commands/domain-api/user/user-app-access-granted/domain-patch-user-app-access-granted.command';

export class DomainPatchUserAppAccessGrantedCommandMock {
    private defaultValue: DomainPatchUserAppAccessGrantedCommand = {
        openedAppAfterExpiry: false,
    };

    constructor(overrides?: Partial<DomainPatchUserAppAccessGrantedCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainPatchUserAppAccessGrantedCommand {
        return this.defaultValue;
    }
}
