<div class="chat-channel-list-skeleton">
    @for (skeletonMessage of numberOfSkeletonMessages; track skeletonMessage) {
        <div class="chat-channel-list-skeleton__items">
            <div class="chat-channel-list-skeleton__user-image">
                <ion-skeleton-text [animated]="true"></ion-skeleton-text>
            </div>
            <div class="chat-channel-list-skeleton__text">
                <h3>
                    <ion-skeleton-text class="chat-channel-list-skeleton__user-name" [animated]="true">
                    </ion-skeleton-text>
                </h3>
                <p>
                    <ion-skeleton-text [animated]="true"> </ion-skeleton-text>
                </p>
            </div>
        </div>
    }
</div>
