import { AdditionalUserInfo } from '@angular/fire/auth';
import { createAction, props } from '@ngrx/store';
import { User } from '../models/user.model';
import { AuthError } from './authentication.reducer';

export const initializeAuthenticationService = createAction('[Authentication] Initialize Authentication Service');
export const initializeAuthenticationServiceFailure = createAction(
    '[Authentication] Initialize Authentication Service Failure',
    props<{ error: Error }>(),
);

export const signUpWithEmailAndPassword = createAction(
    '[Authentication] Sign Up With Email And Password',
    props<{ email: string; password: string }>(),
);
export const signUpWithEmailAndPasswordSuccess = createAction(
    '[Authentication] Sign Up With Email And Password Success',
    props<{ user: User }>(),
);
export const signUpWithEmailAndPasswordFailure = createAction(
    '[Authentication] Sign Up With Email And Password Failure',
    props<{
        error: AuthError;
    }>(),
);

export const loginWithEmailAndPassword = createAction(
    '[Authentication] Login With Email And Password',
    props<{ email: string; password: string }>(),
);
export const loginWithEmailAndPasswordSuccess = createAction(
    '[Authentication] Login With Email And Password Success',
    props<{ user: User }>(),
);
export const loginWithEmailAndPasswordFailure = createAction(
    '[Authentication] Login With Email And Password Failure',
    props<{
        error: AuthError;
    }>(),
);

export const loginWithGoogle = createAction('[Authentication] Login With Google');
export const loginWithGoogleSuccess = createAction(
    '[Authentication] Login With Google Success',
    props<{ user: User; additionalUserInfo?: AdditionalUserInfo }>(),
);
export const loginWithGoogleFailure = createAction(
    '[Authentication] Login With Google Failure',
    props<{ error: AuthError }>(),
);

export const loginWithApple = createAction('[Authentication] Login With Apple');
export const loginWithAppleSuccess = createAction(
    '[Authentication] Login With Apple Success',
    props<{ user: User; additionalUserInfo?: AdditionalUserInfo }>(),
);
export const loginWithAppleFailure = createAction(
    '[Authentication] Login With Apple Failure',
    props<{ error: AuthError }>(),
);

export const logout = createAction('[Authentication] Logout');
export const logoutSuccess = createAction('[Authentication] Logout Success');
export const logoutFailure = createAction('[Authentication] Logout Failure', props<{ error: AuthError }>());

export const requestPasswordReset = createAction('[Authentication] Request Password Reset', props<{ email: string }>());
export const requestPasswordResetSuccess = createAction(
    '[Authentication] Request Password Reset Success',
    props<{ email: string }>(),
);
export const requestPasswordResetFailure = createAction(
    '[Authentication] Request Password Reset Failure',
    props<{ error: AuthError }>(),
);

export const watchForLoginStateChange = createAction('[Authentication] Watch For Login State Change');

export const foundValidUserSession = createAction('[Authentication] Found Valid User Session');

export const noValidUserSession = createAction('[Authentication] No Valid User Session');

export const getUserSignInProvider = createAction('[Authentication] Get User Is SSO');

export const getUserSignInProviderSuccess = createAction(
    '[Authentication] Get User Is SSO Success',
    props<{ signInProvider: string }>(),
);

export const getUserSignInProviderFailure = createAction(
    '[Authentication] Get User Is SSO Failure',
    props<{ error: AuthError }>(),
);
