import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { ConfigCatEffects } from './config-cat.effects';
import { configCatFeature } from './config-cat.reducer';
import { CONFIGCAT_CONFIG } from './injection-tokens';
import { configCatServiceFactory } from './services/config-cat-service.factory';
import { ConfigCatService } from './services/config-cat.service';

@NgModule({
    providers: [
        provideState(configCatFeature),
        provideEffects([ConfigCatEffects]),
        {
            provide: ConfigCatService,
            useFactory: configCatServiceFactory,
            deps: [CONFIGCAT_CONFIG],
        },
    ],
})
export class ZigzagFrontendDataAccessUserConfigCatModule {}
