import { IsString } from 'class-validator';
import { IterablePlatformType } from '../../../../models/third-party/iterable/iterable-platform-type.model';

export class InternalIterableRegisterForPushNotificationsCommand {
    @IsString()
    userId!: string;

    device!: {
        token: string;
        platform: IterablePlatformType;
        applicationName: string;
    };
}
