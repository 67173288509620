import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { interval } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { setTime } from './time.actions';

@Injectable()
export class TimeEffects {
    private readonly TWO_MINUTES: number = 1000 * 60 * 2;
    private readonly pulse$ = interval(this.TWO_MINUTES).pipe(shareReplay(1));

    setTime$ = createEffect(() => {
        return this.pulse$.pipe(map(() => setTime({ currentTime: new Date() })));
    });
}
