import { Injectable } from '@angular/core';
import { SettingKeyValue } from 'configcat-js';
import { ConfigCatServiceAbstract } from './config-cat.service.abstract';

export function mapKeyValueToConfigCatValuePairs(keyValuePairs: Record<string, boolean>): SettingKeyValue[] {
    const keys = Object.keys(keyValuePairs);

    return keys.map((key) => ({
        settingKey: key,
        settingValue: keyValuePairs[key],
    }));
}

@Injectable({
    providedIn: 'root',
})
export class ConfigCatServiceMock implements ConfigCatServiceAbstract {
    fromLocalStorage = JSON.parse(localStorage.getItem('mock-config-cat') ?? '[]') as Record<string, boolean>;

    async getConfig(): Promise<SettingKeyValue[]> {
        console.log('[Mock Config Cat Service] Get Config', this.fromLocalStorage);

        return mapKeyValueToConfigCatValuePairs(this.fromLocalStorage);
    }
}
