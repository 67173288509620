@if (vm$ | async; as vm) {
<ion-content [fullscreen]="true" class="agent-page-content page-ion-content ion-text-center" color="harley">
    <div class="agent-login-content-container">
        <main class="page-content agent-login-content">
            <section>
                <h1 class="app-background">Log in to your Agent Account</h1>
                <p class="ion-padding-start ion-padding-end">Let’s start supporting!</p>
            </section>
            <form [formGroup]="loginForm" (keydown.enter)="login()">
                <ion-item>
                    <ion-input
                        class="zz-input"
                        data-test="email-input"
                        type="text"
                        placeholder="Email"
                        [formControlName]="'email'"
                        [tabindex]="0"
                    ></ion-input>
                </ion-item>
                <ion-item class="ion-margin-top">
                    <ion-input
                        class="zz-input"
                        data-test="password-input"
                        type="password"
                        placeholder="Password"
                        [formControlName]="'password'"
                        [tabindex]="1"
                    ></ion-input>
                </ion-item>
                @if (vm.error) {
                <app-invalid-input-error-message
                    [errorMessage]="vm.error.message"
                    [linkText]="vm.error.link?.text"
                ></app-invalid-input-error-message>
                }
            </form>
            <section class="agent-login-buttons-container">
                <button class="zz-button forgot-password" data-test="forgot-password" (click)="forgotPassword()">
                    Click here to reset your password
                </button>
                <ion-button
                    class="sign-in-button"
                    size="large"
                    color="max"
                    expand="block"
                    [disabled]="!loginForm.valid || vm.isLoading"
                    data-test="login-btn"
                    (click)="login()"
                >
                    @if (vm.isLoading) {
                    <ion-spinner class="agent-login-spinner" name="crescent"></ion-spinner>
                    } @else {
                    <span>Sign in</span>
                    }
                </ion-button>
            </section>
        </main>
    </div>
</ion-content>
}
