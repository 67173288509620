import { DomainPatchDogCommand } from '../../../commands/domain-api/user/dog/domain-patch-dog.command';

export class DomainPatchDogCommandMock {
    private defaultValue: DomainPatchDogCommand = {
        name: 'Bella',
    };

    constructor(overrides?: Partial<DomainPatchDogCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DomainPatchDogCommand {
        return this.defaultValue;
    }
}
