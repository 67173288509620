export enum Color {
    /** Red with contrast white */
    Max = 'max',
    /** Dark blue with contrast white */
    Harley = 'harley',
    /** Dark green with contrast white */
    Alvin = 'alvin',
    /** Dark orange/mustard with contrast white */
    Buddy = 'buddy',
    /** Salmon pink with contrast black */
    Luna = 'luna',
    /** Light blue with contrast black */
    Lola = 'lola',
    /** Light green with contrast black */
    Maggie = 'maggie',
    /** Tan / sand with contrast black */
    Bailey = 'bailey',
    /** Peach with contrast black */
    Bella = 'bella',
    /** Blue with contrast white */
    Charly = 'charly',
    /** Green with contrast white */
    Sophie = 'sophie',
    /** Yellow with contrast black */
    Oliver = 'oliver',
    /** Grey with contrast black */
    Disabled = 'disabled-color',
    /** Off-white with contrast black */
    AppBackground = 'app-background',
    /** Ivory with contrast black */
    AppOutline = 'app-outline',
    /** Black with contrast white */
    Jack = 'jack',
    /** White with contrast black */
    Snowy = 'snowy',
    /** Lighter black with contrast white */
    SemiJack = 'semi-jack',
    /** Dark grey with contrast white */
    Paragraph = 'paragraph-color',
    /** Lighter grey with contrast black */
    DisabledText = 'disabled-text-color',
    /** Light cyan with contrast black */
    Barney = 'barney',
}

export const darkContrastColors = [
    Color.Luna,
    Color.Lola,
    Color.Maggie,
    Color.Bailey,
    Color.Bella,
    Color.Oliver,
    Color.Disabled,
    Color.AppBackground,
    Color.AppOutline,
    Color.Snowy,
    Color.DisabledText,
    Color.Barney,
];
export const lightContrastColors = [
    Color.Max,
    Color.Harley,
    Color.Alvin,
    Color.Buddy,
    Color.Charly,
    Color.Sophie,
    Color.Jack,
    Color.SemiJack,
    Color.Paragraph,
];

export const isColorContrastLight = (color: Color): boolean => {
    return lightContrastColors.includes(color);
};

export function getTemplateThemeColor(color: Color, isContrast?: boolean, opacity = 1): string {
    return `rgba(var(--ion-color-${color}${isContrast ? '-contrast' : ''}-rgb), ${opacity})`;
}

export function setCourseBackgroundColor(color: Color, active = true) {
    return {
        'background-color': active ? getTemplateThemeColor(color) : getTemplateThemeColor(color, false, 0.5),
        color: getTemplateThemeColor(color, true),
    };
}

export function getSVGColour(colour: Color, whiteIcon: string, blackIcon: string): string {
    if (lightContrastColors.includes(colour)) {
        return whiteIcon;
    } else {
        return blackIcon;
    }
}
