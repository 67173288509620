import { createAction, props } from '@ngrx/store';
import { ChatTag } from '../models/chat-tag.model';

export const getActiveChannelTags = createAction('[Chat Tags] Get Active Channel Tags', props<{ channelId: string }>());
export const getActiveChannelTagsSuccess = createAction(
    '[Chat Tags] Get Active Channel Tags Success',
    props<{ tags: ChatTag[] }>(),
);
export const getActiveChannelTagsFailure = createAction(
    '[Chat Tags] Get Active Channel Tags Failure',
    props<{ error: Error }>(),
);

export const addTag = createAction('[Chat Tags] Add Tag', props<{ id: string; channelId: string }>());
export const addTagSuccess = createAction('[Chat Tags] Add Tag Success', props<{ tag: ChatTag }>());
export const addTagFailure = createAction('[Chat Tags] Add Tag Failure', props<{ error: Error }>());

export const removeTag = createAction('[Chat Tags] Remove Tag', props<{ id: string; channelId: string }>());
export const removeTagSuccess = createAction('[Chat Tags] Remove Tag Success', props<{ tag: ChatTag }>());
export const removeTagFailure = createAction('[Chat Tags] Remove Tag Failure', props<{ error: Error }>());

export const getAllChatTags = createAction('[Chat Tags] Get All Chat Tags');
export const getAllChatTagsSuccess = createAction(
    '[Chat Tags] Get All Chat Tags Success',
    props<{ tags: ChatTag[] }>(),
);
export const getAllChatTagsFailure = createAction('[Chat Tags] Get All Chat Tags Failure', props<{ error: Error }>());
