import { RichTextContent } from 'contentful';
import { DailyBite } from '../models/daily-bite.model';
import { ContentfulRichTextContentMock } from '@shared/content-api-interface';

export class DailyBiteMock {
    private defaultValue: DailyBite = {
        id: 1,
        title: 'a title',
        imageUrl: 'some/url',
        rank: 1,
        content: 'some content',
        richContent: 'some rich content' as unknown as RichTextContent,
        author: 'an author',
        tags: ['tag1', 'tag2'],
        dailyBiteAuthor: {
            authorName: 'author name',
            authorImageUrl: 'author URL',
            authorBio: new ContentfulRichTextContentMock().value,
            authorOrganisation: 'Author organisation',
            authorCertifications: ['Author certifications'],
        },
    };

    constructor(overrides?: Partial<DailyBite>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): DailyBite {
        return this.defaultValue;
    }
}
