import { inject, Injectable } from '@angular/core';
import { ChatChannelType } from '@shared/constants';
import { distinctUntilChanged, map, Subject, takeUntil } from 'rxjs';
import {
    ChannelService,
    ChatClientService,
    MessageService,
    StreamI18nService,
    ThemeService,
} from 'stream-chat-angular';
import { CHAT_CONFIG } from '../../injection-tokens';
import { ChatChannelInfo, ChatConnectChannelParams } from '../../models/chat.model';
import { getDateInStreamChatFormat } from '@shared/utils/typescript';

@Injectable({
    providedIn: 'root',
})
export class ChatService {
    private chatConfig = inject(CHAT_CONFIG);
    private chatClientService = inject(ChatClientService);
    private channelService = inject(ChannelService);
    private messageService = inject(MessageService);
    private streamI18nService = inject(StreamI18nService);
    private themeService = inject(ThemeService);

    private destroy$: Subject<boolean> = new Subject<boolean>();

    public async initUser(
        profileId: string,
        token: string,
        ownerName: string | undefined,
        channelType: ChatChannelType,
    ): Promise<ChatChannelInfo[]> {
        this.messageService.displayAs = channelType === ChatChannelType.AI ? 'html' : 'text';

        await this.chatClientService.init(
            this.chatConfig.apiKey,
            {
                id: profileId,
            },
            token,
            { trackPendingChannelInvites: false },
        );

        if (ownerName) {
            await this.chatClientService.chatClient.partialUpdateUser({
                id: profileId,
                set: {
                    name: ownerName, // will use our userId as the chat user id if we don't set this
                },
            });
        }

        this.themeService.theme$.next('light');
        this.streamI18nService.setTranslation();

        return await this.getChannels(profileId, channelType);
    }

    public async createChannel(params: ChatConnectChannelParams) {
        const channel = this.chatClientService.chatClient.channel(params.type, params.id, params.custom);

        await channel.watch();

        if (!channel.id) {
            throw new Error('Created chat ChannelId is undefined');
        }

        return channel.id;
    }

    public async watchChannel(channelId: string) {
        await this.channelService.init({
            id: channelId,
        });
    }

    public async connectAgent(profileId: string, token: string) {
        try {
            const twoDaysAgo = new Date();
            twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
            twoDaysAgo.setHours(0, 0, 0, 0);

            this.messageService.displayAs = 'text';
            await this.chatClientService.init(this.chatConfig.apiKey, profileId, token, {
                timeout: 30000,
                trackPendingChannelInvites: false,
            });
            await this.channelService.init(
                {
                    type: 'expertsupport',
                    last_message_at: { $gte: getDateInStreamChatFormat(twoDaysAgo) },
                },
                { has_unread: -1 },
                { limit: 10 },
                false,
            );

            this.themeService.theme$.next('light');
            this.streamI18nService.setTranslation();
        } catch (error: any) {
            console.error('Error connecting agent to chat portal: ', error);
        }
    }

    public async destroy(): Promise<void> {
        this.channelService.reset();
        await this.chatClientService.disconnectUser();
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    public activeChannelMessagesData() {
        return this.channelService.activeChannelMessages$.pipe(
            map((messages) => {
                const channelType = this.getChannelTypeFromChannelId(messages[0]?.cid);

                return {
                    channelType,
                    messageCount: messages.length,
                    lastMessageDate: messages[messages.length - 1]?.created_at,
                    lastMessageSender: messages[messages.length - 1]?.user?.id,
                };
            }),
            distinctUntilChanged((a, b) => a.messageCount === b.messageCount),
            takeUntil(this.destroy$),
        );
    }

    public getActiveChannelChanged() {
        return this.channelService.activeChannel$.pipe(
            map((channel) => {
                return channel?.id ? { id: channel.id, type: channel.type as ChatChannelType } : undefined;
            }),
            distinctUntilChanged(),
            takeUntil(this.destroy$),
        );
    }

    private async getChannels(profileId: string, channelType: ChatChannelType): Promise<ChatChannelInfo[]> {
        const channels = await this.chatClientService.chatClient.queryChannels({
            members: [profileId],
            type: channelType,
        });

        if (!channels) {
            return [];
        }

        return channels
            .filter((channel) => !!channel.id)
            .map((channel) => {
                return {
                    id: channel.id as string,
                    type: channel.type,
                };
            });
    }

    public getChannelTypeFromChannelId(cid: string | undefined): ChatChannelType | undefined {
        if (!cid) {
            return undefined;
        }

        const channelType = cid.split(':')[0]; // stream chat cid format is 'channel_type:id'

        if (channelType !== ChatChannelType.AGENT && channelType !== ChatChannelType.AI) {
            return undefined;
        }

        return channelType === ChatChannelType.AI ? ChatChannelType.AI : ChatChannelType.AGENT;
    }
}
