import { InternalPostHouseholdCommand } from '../../../commands/internal-api/user/household/internal-post-household.command';

export class InternalPostHouseholdCommandMock {
    private defaultValue: InternalPostHouseholdCommand = {
        name: 'household',
    };

    constructor(overrides?: Partial<InternalPostHouseholdCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalPostHouseholdCommand {
        return this.defaultValue;
    }
}
