import { MixpanelAppService } from './mixpanel-app.service';
import { MixpanelBrowserService } from './mixpanel-browser.service';
import { MIXPANEL_FRONTEND_CONFIG, MixpanelFrontendConfig } from './mixpanel-config.token';
import { MixpanelService } from './mixpanel.service';

export const MixpanelServiceProvider = {
    provide: MixpanelService,
    useFactory: (config: MixpanelFrontendConfig) => {
        return config.platform === 'browser' ? new MixpanelBrowserService() : new MixpanelAppService();
    },
    deps: [MIXPANEL_FRONTEND_CONFIG],
};
