import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { TimeEffects } from './time.effects';
import { timeFeature } from './time.reducer';

@NgModule({
    providers: [provideState(timeFeature), provideEffects([TimeEffects])],
})
export class ZigzagFrontendDataAccessTimeModule {}
