import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, from, map, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { getChatTokenSuccess } from '../../chat-api/store/chat-api.actions';
import { ChatService } from '../services/chat.service';
import {
    activeChannelChanged,
    connectAgent,
    connectAgentFailure,
    connectAgentSuccess,
    createChannel,
    createChannelFailure,
    createChannelSuccess,
    destroyChat,
    destroyChatFailure,
    destroyChatSuccess,
    initChatUser,
    initChatUserFailure,
    initChatUserSuccess,
    setMessageData,
    watchChannel,
    watchChannelFailure,
    watchChannelSuccess,
} from './chat.actions';

@Injectable()
export class ChatEffects {
    private readonly actions$ = inject(Actions);
    private readonly chatService = inject(ChatService);
    initUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(initChatUser),
            concatMap(({ profileId, token, ownerName, chatType }) => {
                return from(this.chatService.initUser(profileId, token, ownerName, chatType)).pipe(
                    map((channels) => initChatUserSuccess({ channels, chatType })),
                    catchError((error: Error) => of(initChatUserFailure({ error }))),
                );
            }),
        );
    });

    createChannel$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(createChannel),
            concatMap(({ params, chatType }) => {
                return from(this.chatService.createChannel(params)).pipe(
                    map((channelId) => createChannelSuccess({ channelId, chatType })),
                    catchError((error: Error) => of(createChannelFailure({ error }))),
                );
            }),
        );
    });

    watchChannel$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(watchChannel),
            concatMap(({ channelId, chatType }) => {
                return from(this.chatService.watchChannel(channelId)).pipe(
                    map(() => watchChannelSuccess({ chatType })),
                    catchError((error: Error) => of(watchChannelFailure({ error }))),
                );
            }),
        );
    });

    connectAgent$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(connectAgent),
            switchMap(({ profileId, token }) =>
                from(this.chatService.connectAgent(profileId, token)).pipe(
                    map(() => connectAgentSuccess()),
                    catchError((error: Error) => of(connectAgentFailure({ error }))),
                ),
            ),
        );
    });

    destroyChat$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(destroyChat),
            switchMap(() =>
                from(this.chatService.destroy()).pipe(
                    map(() => destroyChatSuccess()),
                    catchError((error: Error) => of(destroyChatFailure({ error }))),
                ),
            ),
        );
    });

    getActiveChannelId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(watchChannelSuccess, connectAgentSuccess),
            switchMap(() =>
                this.chatService
                    .getActiveChannelChanged()
                    .pipe(map((activeChannel) => activeChannelChanged({ activeChannel }))),
            ),
        );
    });

    initChannelMessageListener$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getChatTokenSuccess),
            switchMap(() =>
                this.chatService.activeChannelMessagesData().pipe(
                    map((messageData) => {
                        return setMessageData(messageData);
                    }),
                ),
            ),
        );
    });
}
