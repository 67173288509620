import { InternalUserDto } from '@shared/user-api-interface';
import { Profile } from './profile.model';

export function mapUserDtoToProfile(user: InternalUserDto): Profile {
    return {
        id: user.id,
        name: user.ownerName ?? '',
        email: user.mail ?? '',
        token: user.userToken ?? '',
    };
}
