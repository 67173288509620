import { Routes } from '@angular/router';
import { AuthGuard } from '@frontend/data-access/user/authentication';
import { AgentChatPageComponent, LoginPageComponent, ResetPasswordPageComponent } from '@frontend/zigzag-support';

export const expertSupportPortalRoutes: Routes = [
    {
        path: '',
        redirectTo: 'agent-chat',
        pathMatch: 'full',
    },
    {
        path: 'auth',
        component: LoginPageComponent,
        title: 'Agent Portal - Login',
    },
    {
        path: 'reset-password',
        component: ResetPasswordPageComponent,
        title: 'Agent Portal - Reset Password',
    },
    {
        path: 'agent-chat',
        component: AgentChatPageComponent,
        title: 'Agent Portal - Chat',
        canActivate: [AuthGuard],
    },
];
