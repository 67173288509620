import { Gender } from '@shared/user-domain';

export interface InternalDogDto {
    id: string;
    name: string | undefined;
    dateOfBirth: string | undefined;
    dateOfArrival: string | undefined;
    hasArrived: boolean | undefined;
    gender: Gender | undefined;
    dateOfOnboarding: string | undefined;
    breedId: string | undefined;
    isRescue: boolean | undefined;
    isApproximateDateOfBirth: boolean | undefined;
}
