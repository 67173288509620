import { GetCoursesParams, InternalCourseDto } from '@shared/content-api-interface';
import { createAction, props } from '@ngrx/store';

export const getCourses = createAction('[Content] Get Courses', props<{ params: GetCoursesParams }>());

export const getCoursesSuccess = createAction(
    '[Content] Get Courses Success',
    props<{ courses: InternalCourseDto[] }>(),
);

export const getCoursesFailure = createAction(
    '[Content] Get Courses Failure',
    props<{
        error: Error;
    }>(),
);
