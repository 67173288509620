export const removeDuplicates = <TValue>(list: TValue[], compareFn: (a: TValue, b: TValue) => boolean) => {
    if (!list) {
        return [];
    }
    return list.filter((obj, index) => list.findIndex((item) => compareFn(item, obj)) === index);
};

export const uniqByKeepLast = <T>(items: T[], keyExtractor: (item: T) => T[keyof T]) => {
    const map = new Map<T[keyof T], T>();

    for (let i = items.length - 1; i >= 0; i--) {
        const item = items[i];
        const key = keyExtractor(item);

        if (!map.has(key)) {
            map.set(key, item);
        }
    }

    return Array.from(map.values()).reverse();
};

export const addIf = <T>(condition: boolean, item: T, elseItem?: T): T[] => {
    return condition ? [item] : elseItem ? [elseItem] : [];
};

export const upsertArrayItem = <TItem>(
    existingItems: TItem[],
    newItems: TItem[],
    findFn: (itemA: TItem, itemB: TItem) => boolean,
): TItem[] => {
    const updatedItems = [...existingItems];

    newItems.forEach((item) => {
        const index = updatedItems.findIndex((existingItem) => findFn(existingItem, item));

        if (index !== -1) {
            // ...if the item is in the existing items, replace it with the updated item
            updatedItems[index] = item;
        } else {
            // ...if the item is not in the existing items, add it to the item array
            updatedItems.push(item);
        }
    });

    return updatedItems;
};
