import { InternalBreedGroupDto } from '../../dtos/internal-api';

export class InternalBreedGroupDtoMock {
    private defaultValue: InternalBreedGroupDto = {
        id: 'breed-group-1',
        name: 'Breed Group 1',
        breeds: [
            {
                id: 'breed-1',
                name: 'Breed 1',
            },
            {
                id: 'breed-2',
                name: 'Breed 2',
            },
        ],
    };

    constructor(overrides?: Partial<InternalBreedGroupDto>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalBreedGroupDto {
        return this.defaultValue;
    }
}
