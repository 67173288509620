// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `workspace.json`.

import { mixpanelConfigTest } from '@shared/config/mixpanel-config';
import { Environment } from './environment.model';
import { firebaseConfigDev } from './firebase.config';
import { ProjectEnvironment } from '@frontend/configuration';

export const environment: Environment = {
    baseEnvironmentConfig: {
        production: false,
        environment: ProjectEnvironment.ACCEPTANCE,
        internalApiUrl: 'https://zigzag-internal-traffic-manager-test.trafficmanager.net/',
        internalAuthSecret: 'NIDct6kA0I',
        applicationToInternalHashedApiKey:
            '$argon2id$v=19$m=4096,t=3,p=1$UGp0YUxkKjIweEFTNTFnZmEjUmVGaCZLQDZ4WkRuaSo$n2ojwADy07KyxvceM1C/KMUftcL9+STRUt9MWRmSstQ',
    },
    firebaseConfig: firebaseConfigDev,
    streamChatApiKey: 'zatrqnzwk4pd',
    streamChatAgentToken:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiUHVwcHlfQ29hY2gifQ.l_PUecHkXbIEVgUSxGjpkXCCZ2ZBwOQ9h-3y4cEjMnA',
    mixpanelConfig: {
        platform: 'browser',
        projectToken: mixpanelConfigTest.projectToken,
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
