import { IsNumber, IsString } from 'class-validator';

export class InternalIterablePostEventCommand {
    @IsString()
    userId!: string;

    @IsString()
    eventName!: string;

    @IsNumber()
    createdAt!: number;

    dataFields?: Record<string, unknown> | undefined;
}
