import { CourseProgressType } from '@shared/user-domain';
import { IsNumber, IsOptional, IsString } from 'class-validator';

export class InternalPostCourseProgressCommand {
    @IsString()
    courseId!: string;

    progressType!: CourseProgressType;

    @IsNumber()
    timestamp!: number;

    @IsString()
    @IsOptional()
    dogId?: string;
}
