import { filter } from 'rxjs';
import { Action } from '@ngrx/store';

export function ofSourceType(source: string) {
    return filter(({ type }: Action) => {
        return type.startsWith(source);
    });
}

export function notOfSourceType(source: string) {
    return filter(({ type }: Action) => {
        return !type.startsWith(source);
    });
}
