import { Color } from '@shared/utils/typescript';
import { RichTextContent } from 'contentful';
import { InternalTopicDto } from './internal-topic.dto';

export interface InternalDevelopmentalWindowDto {
    title: string;
    color: Color;
    image: string;
    textMain: RichTextContent;
    textImportance: RichTextContent;
    textProgramme: RichTextContent;
    textCourses: RichTextContent;
    textNextCourse: RichTextContent;
    startAtText: string;
    startAtDays: number;
    endAtDays: number;
    linkedTopics: InternalTopicDto[]; // todo should be topic ids
}
