import { animate, sequence, state, style, transition, trigger } from '@angular/animations';

export const collapseAnimation = (duration: string | number = '0.45s') =>
    trigger('collapseAnimation', [
        state('collapse', style({ maxHeight: '0px', opacity: 0, display: 'none', pointerEvents: 'none' })),
        state('expand', style({ maxHeight: '100%', opacity: 1, display: 'block', pointerEvents: 'auto' })),
        transition('expand => collapse', [
            sequence([
                style({ pointerEvents: 'none' }),
                animate(`${duration} ease-in-out`, style({ maxHeight: '0px', opacity: 0 })),
                style({ display: 'none' }),
            ]),
        ]),
    ]);
