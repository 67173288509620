import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ContentService } from '../../content.service';
import { getSteps, getStepsFailure, getStepsSuccess } from './step.actions';

@Injectable()
export class StepEffects {
    private readonly actions$ = inject(Actions);
    private readonly contentService = inject(ContentService);
    getSteps$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getSteps),
            switchMap(({ params }) => {
                return this.contentService.getSteps(params).pipe(
                    map((steps) => {
                        return getStepsSuccess({ steps });
                    }),
                    catchError((error: Error) => {
                        console.error(error);
                        return of(getStepsFailure({ error }));
                    }),
                );
            }),
        );
    });
}
