import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ContentService } from '../../content.service';
import { getBreedGroups, getBreedGroupsFailure, getBreedGroupsSuccess } from '../breed-group/breed-group.actions';

@Injectable()
export class BreedGroupEffects {
    private readonly actions$ = inject(Actions);
    private readonly contentService = inject(ContentService);
    getBreedGroups$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getBreedGroups),
            switchMap(({ params }) => {
                return this.contentService.getBreedGroups(params).pipe(
                    map((breedGroups) => {
                        return getBreedGroupsSuccess({ breedGroups });
                    }),
                    catchError((error: Error) => {
                        console.error(error);
                        return of(getBreedGroupsFailure({ error }));
                    }),
                );
            }),
        );
    });
}
