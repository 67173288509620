import { chatFeature } from '@frontend/data-access/chat';
import { chatTagsFeature } from '@frontend/data-access/user/chat-tags';
import { createSelector } from '@ngrx/store';

export const selectTagsVm = createSelector(
    chatTagsFeature.selectActiveChannelTags,
    chatTagsFeature.selectTags,
    chatFeature.selectActiveChannel,
    (activeChannelTags, tags, activeChannel) => {
        return {
            activeChannelTags: activeChannelTags.filter((tag) => !!tag),
            inactiveChannelTags: tags.filter(
                (tag) => !activeChannelTags.find((activeChannelTag) => activeChannelTag.id === tag.id),
            ),
            selectedChannelId: activeChannel?.id,
        };
    },
);
