import { createFeature, createReducer, on } from '@ngrx/store';
import { ChatTag } from '../models/chat-tag.model';
import { getActiveChannelTagsSuccess, getAllChatTagsSuccess } from './chat-tags.actions';

export interface ChatTagsState {
    activeChannelTags: ChatTag[];
    tags: ChatTag[];
}

export const initialState: ChatTagsState = {
    activeChannelTags: [],
    tags: [],
};

export const chatTagsFeature = createFeature({
    name: 'chatTags',
    reducer: createReducer(
        initialState,
        on(getAllChatTagsSuccess, (state, { tags }): ChatTagsState => ({ ...state, tags })),
        on(getActiveChannelTagsSuccess, (state, { tags }): ChatTagsState => ({ ...state, activeChannelTags: tags })),
    ),
});
