import { BreedGroup } from '../models/breed-group.model';

export class BreedGroupMock {
    private defaultValue: BreedGroup = {
        id: '',
        name: '',
        breeds: [],
    };

    constructor(overrides?: Partial<BreedGroup>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): BreedGroup {
        return this.defaultValue;
    }
}
