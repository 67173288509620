import { createFeature, createReducer, on } from '@ngrx/store';
import { PageContentState } from '../../models/page.model';
import { getPageTodaySuccess } from './page.actions';

export const initialState: PageContentState = { today: undefined };

export const pageFeature = createFeature({
    name: 'pages',
    reducer: createReducer(
        initialState,
        on(
            getPageTodaySuccess,
            (state, { pageToday }): PageContentState => ({
                ...state,
                today: pageToday,
            }),
        ),
    ),
});
