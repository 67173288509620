import { NgClass, NgStyle } from '@angular/common';
import { Component, input } from '@angular/core';
import { IonCard, IonIcon } from '@ionic/angular/standalone';
import { Color } from '@shared/utils/typescript';
import { addIcons } from 'ionicons';
import { lockClosed } from 'ionicons/icons';
import { GenericChipComponent } from '../../generic-chip/generic-chip.component';

@Component({
    standalone: true,
    selector: 'app-generic-card-slim',
    templateUrl: './generic-card-slim.component.html',
    styleUrl: './generic-card-slim.component.scss',
    imports: [NgClass, NgStyle, IonCard, IonIcon, GenericChipComponent],
})
export class GenericCardSlimComponent {
    public title = input.required<string>();
    public imageUrl = input.required<string>();
    public background = input.required<Color>();
    public unlocked = input.required<boolean>();
    public isBasicPlan = input<boolean>();

    public disabled = input<boolean>(false);
    protected readonly Color = Color;

    constructor() {
        addIcons({ lockClosed });
    }
}
