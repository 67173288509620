import { IsOptional, IsString } from 'class-validator';

export class DomainPartnerCommand {
    @IsString()
    name!: string;

    @IsString()
    apiKey!: string;

    @IsString()
    @IsOptional()
    partnerTypeId?: string;
}
