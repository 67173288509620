import { IsArray, IsString } from 'class-validator';
import { ConversationTypeID } from '../../../models/conversation/conversation.model';
import { DomainPostConversationMessage } from './domain-post-conversation-message.command';

export class DomainPostConversationCommand {
    @IsString()
    channelId!: string;

    @IsString()
    userId!: string;

    @IsString()
    conversationTypeId!: ConversationTypeID;

    @IsArray()
    messages!: DomainPostConversationMessage[];
}
