import { inject, Injectable } from '@angular/core';
import { ChatChannelType } from '@shared/constants';
import { of, Subject } from 'rxjs';
import { ChannelService, ChatClientService } from 'stream-chat-angular';
import { CHAT_CONFIG } from '../../injection-tokens';
import { ChatChannelInfo, ChatConnectChannelParams } from '../../models/chat.model';

@Injectable({
    providedIn: 'root',
})
export class ChatServiceMock {
    private chatConfig = inject(CHAT_CONFIG);
    private chatClientService = inject(ChatClientService);
    private channelService = inject(ChannelService);

    private destroy$: Subject<boolean> = new Subject<boolean>();

    async initUser(
        profileId: string,
        token: string,
        ownerName: string | undefined,
        channelType: ChatChannelType,
    ): Promise<ChatChannelInfo[]> {
        console.log('[Mock Chat Service] Init User', profileId, token, ownerName, channelType);

        await this.chatClientService.init(
            this.chatConfig.apiKey,
            {
                id: profileId,
            },
            token,
        );

        const channel = {
            id: 'Cypress_Test',
            type: ChatChannelType.AI,
        };

        return Promise.resolve([channel]);
    }

    /**
     * This method should never be called, as we would always want to connect to our Stream Chat Channel
     */
    async createChannel(params: ChatConnectChannelParams) {
        console.log('[Mock Chat Service] Create Channel', params);

        return Promise.resolve('mock-channel-id');
    }

    async watchChannel(channelId: string) {
        console.log('[Mock Chat Service] Watch Channel', channelId);

        await this.channelService.init({
            id: channelId,
        });
    }

    async destroy() {
        console.log('[Mock Chat Service] Destroy');

        this.channelService.reset();
        await this.chatClientService.disconnectUser();
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    activeChannelMessagesData() {
        console.log('[Mock Chat Service] Active Channel Message Data');

        return of({
            messageCount: 1,
            lastMessageData: new Date(),
            lastMessageSender: '',
        });
    }

    getActiveChannelChanged() {
        return of({
            id: 'Cypress_Test',
            type: ChatChannelType.AI,
        });
    }
}
