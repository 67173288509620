import { inject, Injectable } from '@angular/core';
import { timeFeature } from '@frontend/data-access/time';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { map, of } from 'rxjs';
import { catchError, concatMap, filter, switchMap } from 'rxjs/operators';
import { ChatTagsService } from '../services/chat-tags.service';
import {
    addTag,
    addTagFailure,
    addTagSuccess,
    getActiveChannelTags,
    getActiveChannelTagsFailure,
    getActiveChannelTagsSuccess,
    getAllChatTags,
    getAllChatTagsFailure,
    getAllChatTagsSuccess,
    removeTag,
    removeTagFailure,
    removeTagSuccess,
} from './chat-tags.actions';

@Injectable()
export class ChatTagsEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    private readonly chatTagService = inject(ChatTagsService);
    getActiveChannelTags$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getActiveChannelTags),
            filter((action) => !!action.channelId),
            switchMap((action) =>
                this.chatTagService.getActiveChannelTags(action.channelId).pipe(
                    map((tags) => getActiveChannelTagsSuccess({ tags })),
                    catchError((error: Error) => of(getActiveChannelTagsFailure({ error }))),
                ),
            ),
        );
    });

    addChannelTag$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(addTag),
            concatLatestFrom(() => this.store.select(timeFeature.selectToday)),
            concatMap(([{ id, channelId }, currentTime]) => {
                return this.chatTagService
                    .addChannelTag({
                        channelId,
                        tagId: id,
                        timeStamp: currentTime.getTime(),
                    })
                    .pipe(
                        map((tag) => addTagSuccess({ tag })),
                        catchError((error: Error) => of(addTagFailure({ error }))),
                    );
            }),
        );
    });

    removeChannelTag$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeTag),
            concatMap(({ id, channelId }) => {
                return this.chatTagService.findAndRemoveTag(channelId, id).pipe(
                    map((tag) => removeTagSuccess({ tag })),
                    catchError((error: Error) => of(removeTagFailure({ error }))),
                );
            }),
        );
    });

    getAllChatTags$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getAllChatTags),
            switchMap(() => {
                return this.chatTagService.getAllChatTags().pipe(
                    map((tags) => getAllChatTagsSuccess({ tags })),
                    catchError((error: Error) => of(getAllChatTagsFailure({ error }))),
                );
            }),
        );
    });
}
