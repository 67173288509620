<section #container class="emoji-picker">
    <button class="zz-button emoji-picker__toggle xl" data-test="emoji-picker-toggle" (click)="toggled()">
        <i class="emoji-picker__toggle-icon fa-regular fa-face-smile"></i>
    </button>
    @if (isOpened) {
        <emoji-mart
            class="emoji-picker__picker"
            color="var(--str-chat__primary-color)"
            emoji="point_up"
            data-test="emoji-picker-picker"
            [darkMode]="(theme$ | async) === 'dark'"
            (emojiSelect)="emojiSelected($event)"
        ></emoji-mart>
    }
</section>
