import { Gender } from '@shared/user-domain';

export const hisHer = (gender?: Gender): string => {
    if (gender === Gender.MALE) {
        return 'his';
    } else if (gender === Gender.FEMALE) {
        return 'her';
    }
    return 'their';
};

export const hisHers = (gender?: Gender): string => {
    if (gender === Gender.MALE) {
        return 'his';
    } else if (gender === Gender.FEMALE) {
        return 'hers';
    }
    return 'theirs';
};

export const himHer = (gender?: Gender): string => {
    if (gender === Gender.MALE) {
        return 'him';
    } else if (gender === Gender.FEMALE) {
        return 'her';
    }
    return 'them';
};

export const heShe = (gender?: Gender): string => {
    if (gender === Gender.MALE) {
        return 'he';
    } else if (gender === Gender.FEMALE) {
        return 'she';
    }
    return 'they';
};
