import { AdjustAttribution } from '@awesome-cordova-plugins/adjust';
import { createAction, props } from '@ngrx/store';
import { AnalyticsProfile } from './analytics.model';

export const initAnalytics = createAction('[Analytics] Init');

export const logoutAnalytics = createAction('[Analytics] Logout');
export const logoutAnalyticsSuccess = createAction('[Analytics] Logout Success');
export const logoutAnalyticsFailure = createAction('[Analytics] Logout Failure', props<{ error: Error }>());

export const setAnalyticsProfile = createAction(
    '[Analytics] Set Profile',
    props<{ profile: AnalyticsProfile; id: string }>(),
);

export const trackLibrarySearch = createAction(
    '[Analytics] Track Library Search',
    props<{ searchTerm: string; resultCount: number }>(),
);

export const trackEvent = createAction(
    '[Analytics] Track Event',
    props<{
        eventName: string;
        eventProperties?: Record<string, unknown>;
    }>(),
);

export const updateAttributionData = createAction(
    '[Subscription] Collect Device Identifiers',
    props<{ attributionData: AdjustAttribution }>(),
);
