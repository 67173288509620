import { RichTextContent } from 'contentful';

export class ContentfulRichTextContentMock {
    private defaultValue: RichTextContent = {
        data: {},
        marks: [],
        content: [
            {
                data: {},
                marks: [],
                content: [
                    {
                        data: {},
                        marks: [],
                        value: 'This is some content for [NAME] because [HIM',
                        nodeType: 'text',
                    },
                    {
                        data: {},
                        marks: [],
                        value: '/HER',
                        nodeType: 'text',
                    },
                    {
                        data: {},
                        marks: [],
                        value: '] is a [BREED',
                        nodeType: 'text',
                    },
                    {
                        data: {},
                        marks: [],
                        value: ']',
                        nodeType: 'text',
                    },
                ],
                nodeType: 'paragraph',
            },
        ],
        nodeType: 'document',
    };

    constructor(overrides?: Partial<RichTextContent>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): RichTextContent {
        return this.defaultValue;
    }
}
