import { StepType, TopicName } from '@shared/content-api-interface';
import { Lesson } from '../models/lesson.model';
import { ContentfulRichTextContentMock } from '@shared/content-api-interface';

export class LessonMock {
    private defaultValue: Lesson = {
        contentType: StepType.LESSON,
        id: 1,
        duration: 5,
        topic: TopicName.BITING,
        title: 'Biters',
        videoId: '1234',
        introduction: 'Intro to biters',
        introductionDynamicContent: [],
        beforeYouStart: [],
        steps: [],
        stepsDynamicContent: [],
        tips: [],
        tipsDynamicContent: [],
        lowRatingAnimation: 'animation_fail_1.webp',
        highRatingAnimation: 'animation_success_1.webp',
        lowRatingHtml: '',
        highRatingHtml: '',
        audio: { url: '', title: '' },
        tags: [],
        lessonAuthor: {
            authorName: 'author name',
            authorImageUrl: 'author URL',
            authorBio: new ContentfulRichTextContentMock().value,
            authorOrganisation: 'Author organisation',
            authorCertifications: ['Author certifications'],
        },
    };

    constructor(overrides?: Partial<Lesson>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): Lesson {
        return this.defaultValue;
    }
}
