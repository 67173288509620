import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { IonContent } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { ChatTagsComponent } from '../chat-tags/chat-tags.component';
import { selectUserInformationSidebarVm } from './user-information-sidebar.selectors';

@Component({
    selector: 'app-user-information-sidebar',
    templateUrl: './user-information-sidebar.component.html',
    styleUrl: './user-information-sidebar.component.scss',
    standalone: true,
    imports: [IonContent, ChatTagsComponent, AsyncPipe],
})
export class UserInformationSidebarComponent {
    private readonly store = inject(Store);
    public vm$ = this.store.select(selectUserInformationSidebarVm);
}
