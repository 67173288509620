<div class="slim-card">
    @if (isBasicPlan() && !unlocked()) {
        <app-generic-chip
            class="slim-card__chip"
            text="Premium"
            icon="fa-regular fa-crown"
            backgroundColor="sophie"
            textColor="snowy"
            padding="4px 8px"
        />
    }
    <ion-card [disabled]="disabled()" class="{{ background() }} slim-card__outer-container">
        <div
            class="slim-card__container"
            [ngClass]="{
                'slim-card__container--locked': !unlocked(),
            }"
        >
            <img class="slim-card__img" [src]="imageUrl()" />
            <h2 [ngStyle]="{ color: 'var(--ion-color-' + background() + '-contrast)' }">
                {{ title() }}
            </h2>
            @if (!unlocked() && !isBasicPlan()) {
                <ion-icon class="slim-card__locked-icon" name="lock-closed" data-test="slim-card-locked-icon" />
            }
        </div>
    </ion-card>
</div>
