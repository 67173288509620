import { createFeature, createReducer, on } from '@ngrx/store';
import {
    foundValidUserSession,
    getUserSignInProviderSuccess,
    loginWithApple,
    loginWithAppleFailure,
    loginWithAppleSuccess,
    loginWithEmailAndPassword,
    loginWithEmailAndPasswordFailure,
    loginWithEmailAndPasswordSuccess,
    loginWithGoogle,
    loginWithGoogleFailure,
    loginWithGoogleSuccess,
    noValidUserSession,
    requestPasswordReset,
    requestPasswordResetFailure,
    requestPasswordResetSuccess,
    signUpWithEmailAndPassword,
    signUpWithEmailAndPasswordFailure,
    signUpWithEmailAndPasswordSuccess,
} from './authentication.actions';

export const authenticationFeatureKey = 'authentication';

export interface AuthError extends Error {
    code: string;
}

export interface AuthState {
    signUpLoading: boolean;
    signUpError: AuthError | undefined;
    loginLoading: boolean;
    loginError: AuthError | undefined;
    loggedIn: boolean;
    resetPasswordLoading: boolean;
    resetPasswordError: AuthError | undefined;
    resetPasswordSuccess: boolean;
    signInProvider: string | undefined;
}

export const initialState: AuthState = {
    signUpLoading: false,
    signUpError: undefined,
    loginLoading: false,
    loginError: undefined,
    loggedIn: false,
    resetPasswordLoading: false,
    resetPasswordError: undefined,
    resetPasswordSuccess: false,
    signInProvider: undefined,
};

export const authenticationFeature = createFeature({
    name: authenticationFeatureKey,
    reducer: createReducer(
        initialState,
        on(
            loginWithEmailAndPassword,
            loginWithGoogle,
            loginWithApple,
            (state): AuthState => ({ ...state, loginLoading: true, loginError: undefined }),
        ),
        on(
            signUpWithEmailAndPasswordSuccess,
            loginWithEmailAndPasswordSuccess,
            loginWithGoogleSuccess,
            loginWithAppleSuccess,
            (state, { user }): AuthState => ({
                ...state,
                loginLoading: false,
                loginError: undefined,
                signInProvider: user.signInProvider,
            }),
        ),
        on(
            signUpWithEmailAndPasswordFailure,
            loginWithEmailAndPasswordFailure,
            loginWithGoogleFailure,
            loginWithAppleFailure,
            (state, action): AuthState => ({
                ...state,
                loginLoading: false,
                loginError: action.error,
            }),
        ),
        on(
            signUpWithEmailAndPassword,
            loginWithGoogle,
            loginWithApple,
            (state): AuthState => ({
                ...state,
                signUpLoading: true,
                signUpError: undefined,
            }),
        ),
        on(foundValidUserSession, (state): AuthState => ({ ...state, loggedIn: true })),
        on(noValidUserSession, (state): AuthState => ({ ...state, loggedIn: false })),
        on(
            requestPasswordReset,
            (state): AuthState => ({ ...state, resetPasswordLoading: true, resetPasswordError: undefined }),
        ),
        on(
            requestPasswordResetSuccess,
            (state): AuthState => ({
                ...state,
                resetPasswordLoading: false,
                resetPasswordError: undefined,
                resetPasswordSuccess: true,
            }),
        ),
        on(
            requestPasswordResetFailure,
            (state, action): AuthState => ({
                ...state,
                resetPasswordLoading: false,
                resetPasswordError: action.error,
                resetPasswordSuccess: false,
            }),
        ),
        on(getUserSignInProviderSuccess, (state, { signInProvider }) => ({
            ...state,
            signInProvider,
        })),
    ),
});
