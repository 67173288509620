import { NgModule } from '@angular/core';
import { ZigzagFrontendDataAccessTimeModule } from '@frontend/data-access/time';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { ChatTagsService } from './services/chat-tags.service';
import { ChatTagsEffects } from './store/chat-tags.effects';
import { chatTagsFeature } from './store/chat-tags.reducer';

@NgModule({
    imports: [ZigzagFrontendDataAccessTimeModule],
    providers: [provideState(chatTagsFeature), provideEffects([ChatTagsEffects]), ChatTagsService],
})
export class ZigzagFrontendDataAccessUserChatTagsModule {}
