export enum ConversationTypeID {
    EXPERT_SUPPORT = '1',
    AI_CHAT = '2',
}

export enum ConversationMessageRatingTypeID {
    POSITIVE = '1',
    NEGATIVE = '2',
    NONE = '3',
}

export enum ConversationMessageRatingType {
    POSITIVE = 'positive',
    NEGATIVE = 'negative',
    NONE = 'none',
}

export const conversationMessageRatingTypeToIdMap = new Map<
    ConversationMessageRatingType,
    ConversationMessageRatingTypeID
>([
    [ConversationMessageRatingType.POSITIVE, ConversationMessageRatingTypeID.POSITIVE],
    [ConversationMessageRatingType.NEGATIVE, ConversationMessageRatingTypeID.NEGATIVE],
    [ConversationMessageRatingType.NONE, ConversationMessageRatingTypeID.NONE],
]);
