import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-star-rating-component',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.scss'],
    standalone: true,
    imports: [],
})
export class StarRatingComponent implements OnInit {
    @Input({ required: true }) maxNumberOfStars!: number;

    @Input() starValueSelected = 0;

    @Output() ratingChanged = new EventEmitter<number>();

    numberOfStars: number[] = [];

    ngOnInit() {
        this.numberOfStars = [...Array(this.maxNumberOfStars).keys()];
    }

    ratingChecked(updatedRating: number): void {
        this.starValueSelected = updatedRating;
        this.ratingChanged.emit(updatedRating);
    }
}
