import { RescheduledStepType } from '@shared/user-domain';
import { Order } from '../database/pagination.model';

export interface DomainGetAllRescheduledStepFindOptionsWhere {
    userId?: string;
    stepId?: string;
    contentId?: number;
    type?: RescheduledStepType;
    isActive?: boolean;
    timestamp?: number;
}

export type DomainGetAllRescheduledStepOrderBy = keyof DomainGetAllRescheduledStepFindOptionsWhere;

export interface DomainGetAllRescheduledStepQueryParams extends DomainGetAllRescheduledStepFindOptionsWhere {
    orderByDirection?: Order;
    orderBy?: DomainGetAllRescheduledStepOrderBy;
}
