import { AgeAlternativeValues, Gender } from '@shared/user-domain';
import { InternalPartnerDto } from './partner/internal-partner.dto';

export class InternalBaseUserDto {
    name!: string | undefined;
    ownerName!: string | undefined;
    dateOfBirth!: string | AgeAlternativeValues | undefined;
    gender!: Gender | undefined;
    dateOfArrival!: string | undefined;
    hasArrived!: boolean | undefined;
    countryCode!: string | undefined;
    breedId!: string | undefined;
}

export class InternalUserWithoutTokenDto extends InternalBaseUserDto {
    id = '';
    accountId!: string | undefined;
    mail!: string;
    termsAndPrivacyAccepted!: boolean;
    notifications!: boolean;
    dateOfOnboarding!: string | undefined;
    partners!: InternalPartnerDto[];
    firstDayAtHomeWhenOnboarding?: boolean;
    purinaMarketingAccepted!: boolean | undefined;
    isRescuePup!: boolean | undefined;
    isApproximateDateOfBirth!: boolean | undefined;
}

export class InternalUserDto extends InternalUserWithoutTokenDto {
    userToken?: string;
}
