import { inject, Injectable } from '@angular/core';
import { activeChannelChanged, chatFeature } from '@frontend/data-access/chat';
import { addTagSuccess, getActiveChannelTags, removeTagSuccess } from '@frontend/data-access/user/chat-tags';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { filter, map, switchMap } from 'rxjs/operators';
import { trackEvent } from '@frontend/data-access/analytics';

@Injectable()
export class ChatTagsEffects {
    private readonly actions$ = inject(Actions);
    private readonly store = inject(Store);
    getActiveChannelTags$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(activeChannelChanged),
            filter(({ activeChannel }) => !!activeChannel),
            map(({ activeChannel }) => getActiveChannelTags({ channelId: activeChannel!.id })),
        );
    });

    removeTagSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(removeTagSuccess),
            concatLatestFrom(() => this.store.select(chatFeature.selectActiveChannel)),
            switchMap(([{ tag }, activeChannel]) => {
                const actions: Action[] = [
                    trackEvent({
                        eventName: 'tag-removed',
                        eventProperties: {
                            tagId: tag.id,
                            tagName: tag.name,
                            channelId: activeChannel?.id,
                        },
                    }),
                ];

                if (activeChannel) {
                    actions.push(getActiveChannelTags({ channelId: activeChannel.id }));
                }
                return actions;
            }),
        );
    });

    addTagSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(addTagSuccess),
            concatLatestFrom(() => this.store.select(chatFeature.selectActiveChannel)),
            switchMap(([action, activeChannel]) => {
                const actions: Action[] = [
                    trackEvent({
                        eventName: 'tag-added',
                        eventProperties: {
                            tagId: action.tag.id,
                            tagName: action.tag.name,
                            channelId: activeChannel?.id,
                        },
                    }),
                ];

                if (activeChannel) {
                    actions.push(getActiveChannelTags({ channelId: activeChannel.id }));
                }

                return actions;
            }),
        );
    });
}
