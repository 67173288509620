import { RescheduledStepType } from '@shared/user-domain';

export interface CreateRescheduledStepCommand {
    rescheduledSteps: CreateRescheduledStep[];
}

export interface CreateRescheduledStep {
    stepId: string;
    type: RescheduledStepType;
    isActive: boolean;
    timestamp: number;
}

export interface PatchRescheduledStepInactiveCommand {
    rescheduledSteps: PatchRescheduledStepAsInactive[];
}

export interface PatchRescheduledStepAsInactive {
    stepId: string;
    type: RescheduledStepType;
}
