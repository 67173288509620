<p class="invalid-input-error-left xs">
    <span class="invalid-input-error-left__message" data-test="error-text-area">{{ errorMessage }}</span>
    @if (linkText) {
        <span class="invalid-input-error-left__link" (click)="linkClicked.emit()" data-test="error-link-text">{{
            linkText
        }}</span>
    }
</p>
<span class="invalid-input-error-right">
    <ion-icon class="invalid-input-error-right__icon" name="sad-outline" />
</span>
