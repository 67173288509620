import { NgModule } from '@angular/core';
import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';
import { AUTHENTICATION_CONFIG } from './authentication-injection-tokens';
import { authenticationServiceFactory } from './authentication-service.factory';
import { AuthenticationService } from './services/authentication.service';
import { AuthenticationEffects } from './store/authentication.effects';
import { authenticationFeature } from './store/authentication.reducer';

@NgModule({
    providers: [
        provideState(authenticationFeature),
        provideEffects([AuthenticationEffects]),
        {
            provide: AuthenticationService,
            useFactory: authenticationServiceFactory,
            deps: [AUTHENTICATION_CONFIG],
        },
    ],
})
export class ZigzagFrontendDataAccessUserAuthenticationModule {}
