import { IPinfo } from 'node-ipinfo';

export const ipInfoCorrectResponseMock: IPinfo = {
    ip: '51.5074.0.1270',
    hostname: 'london-uk.example.com',
    bogon: false,
    anycast: false,
    city: 'London',
    region: 'England',
    country: 'United Kingdom',
    countryFlag: {
        emoji: '',
        unicode: '',
    },
    countryFlagURL: '',
    countryCurrency: {
        code: '',
        symbol: '',
    },
    continent: {
        code: '',
        name: '',
    },
    isEU: true,
    countryCode: 'GB',
    loc: '51.5074,-0.1270',
    org: 'Example Company Ltd',
    postal: 'EC1A 1BB',
    timezone: 'Europe/London',
    asn: {
        asn: 'AS12345',
        name: 'Example ISP',
        domain: 'example.com',
        route: '123.45.67.0/24',
        type: 'business',
    },
    company: {
        name: 'Example Company Ltd',
        domain: 'example.com',
        type: 'business',
    },
    carrier: {
        name: 'Example ISP',
        mcc: '123',
        mnc: '456',
    },
    privacy: {
        vpn: false,
        proxy: false,
        tor: false,
        relay: false,
        hosting: false,
        service: '',
    },
    abuse: {
        address: '123 Example St, London, UK',
        email: 'abuse@example.com',
        name: 'Abuse Desk',
        network: '123.45.67.0/24',
        phone: '+44 123 456 7890',
        country: '',
        countryCode: '',
    },
    domains: {
        ip: 'example.com',
        total: 1,
        domains: [''],
    },
};
