import { GetContentBaseParams } from '@shared/content-api-interface';
import { createAction, props } from '@ngrx/store';
import { PageTodayContent } from '../../models/page.model';

export const getPageToday = createAction('[Content] Get PageToday', props<{ params: GetContentBaseParams }>());

export const getPageTodaySuccess = createAction(
    '[Content] Get PageToday Success',
    props<{ pageToday: PageTodayContent }>(),
);

export const getPageTodayFailure = createAction(
    '[Content] Get PageToday Failure',
    props<{
        error: Error;
    }>(),
);
