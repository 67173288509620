import { GetContentBaseParams, InternalBreedGroupDto } from '@shared/content-api-interface';
import { createAction, props } from '@ngrx/store';

export const getBreedGroups = createAction('[Content] Get Breed Groups', props<{ params: GetContentBaseParams }>());

export const getBreedGroupsSuccess = createAction(
    '[Content] Get Breed Groups Success',
    props<{ breedGroups: InternalBreedGroupDto[] }>(),
);

export const getBreedGroupsFailure = createAction(
    '[Content] Get Breed Groups Failure',
    props<{
        error: Error;
    }>(),
);
