import { InternalPatchDogCommand } from '../../../commands/internal-api/user/dog/internal-patch-dog.command';

export class InternalPatchDogCommandMock {
    private defaultValue: InternalPatchDogCommand = {
        name: 'Bella',
    };

    constructor(overrides?: Partial<InternalPatchDogCommand>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalPatchDogCommand {
        return this.defaultValue;
    }
}
