import { IsBoolean, IsOptional, IsString } from 'class-validator';

export class DomainPatchDogCommand {
    @IsString()
    @IsOptional()
    name?: string;

    @IsString()
    @IsOptional()
    dateOfBirth?: string;

    @IsString()
    @IsOptional()
    dateOfArrival?: string;

    @IsBoolean()
    @IsOptional()
    hasArrived?: boolean;

    @IsString()
    @IsOptional()
    gender?: string;

    @IsString()
    @IsOptional()
    dateOfOnboarding?: string;

    @IsString()
    @IsOptional()
    breedId?: string;

    @IsBoolean()
    @IsOptional()
    isRescue?: boolean;

    @IsBoolean()
    @IsOptional()
    isApproximateDateOfBirth?: boolean;
}
