import { Pipe, PipeTransform } from '@angular/core';
import { Gender } from '@shared/user-domain';
import { hisHer } from '@frontend/utility/pronouns';

@Pipe({
    name: 'pronoun',
    standalone: true,
})
export class PronounPipe implements PipeTransform {
    transform(value: Gender): unknown {
        return hisHer(value);
    }
}
