export interface RadioWithLogicItem {
    value: string;
    domElement?: {
        name?: string;
        type?: string;
    };
}

export interface RadioWithLogicResult {
    complete: boolean;
    selected?: RadioWithLogicItem;
}
