import { createFeature, createReducer, on } from '@ngrx/store';
import { FaqState } from '../../models/faq.model';
import { getFaqsSuccess } from './faq.actions';

export const initialState: FaqState = {
    faqs: [],
};

export const faqsFeature = createFeature({
    name: 'faqs',
    reducer: createReducer(
        initialState,
        on(
            getFaqsSuccess,
            (state, { faqs }): FaqState => ({
                ...state,
                faqs,
            }),
        ),
    ),
});
