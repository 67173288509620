import { inject, Injectable } from '@angular/core';
import { WINDOW } from '@frontend/configuration';
import { isCountryCodeNorthAmerica } from '@frontend/data-access/geo-location';
import { NavController } from '@ionic/angular/standalone';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { isEmpty } from '@shared/utils/typescript';
import { filter, tap } from 'rxjs/operators';
import {
    forceAppRefresh,
    openCookieNotice,
    openPrivacy,
    openTerms,
    openUrlWindow,
    routeTo,
    routeToPrevious,
} from './router.actions';

const US_PRIVACY = 'https://zigzag.dog/en-us/privacy/';
const US_TERMS = 'https://zigzag.dog/en-us/terms/';
const US_COOKIE_NOTICE = 'https://zigzag.dog/en-us/cookie-notice/';
const REST_OF_WORLD_PRIVACY = 'https://zigzag.dog/privacy/';
const REST_OF_WORLD_TERMS = 'https://zigzag.dog/terms-and-conditions/';
const REST_OF_WORLD_COOKIE_NOTICE = 'https://zigzag.dog/cookie-notice/';

@Injectable()
export class RouterEffects {
    private readonly actions$ = inject(Actions);
    private readonly navController = inject(NavController);
    private readonly window = inject<Window>(WINDOW);

    routeTo$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(routeTo),
                tap(({ commands, extras, isAnimated }) => {
                    void this.navController.navigateForward(commands, { ...extras, animated: isAnimated ?? false });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    routeToPrevious$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(routeToPrevious),
                filter(({ commands }) => isEmpty(commands)),
                tap(({ isAnimated }) => {
                    void this.navController.back({ animated: isAnimated ?? true });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    routeToBackwards$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(routeToPrevious),
                filter(({ commands }) => !isEmpty(commands)),
                tap(({ commands, extras, isAnimated }) => {
                    void this.navController.navigateBack(commands!, { ...extras, animated: isAnimated ?? false });
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    openUrlWindow$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openUrlWindow),
                tap(({ url, openInNewWindow }) => {
                    if (openInNewWindow) {
                        this.window.open(url, '_blank');
                    } else {
                        this.window.open(url);
                    }
                }),
            );
        },
        { dispatch: false },
    );

    forceAppRefresh$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(forceAppRefresh),
                tap(() => {
                    this.window.location.href = new URL(this.window.location.href).origin;
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    openPrivacy$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openPrivacy),
                tap(({ countryCode }) => {
                    if (isCountryCodeNorthAmerica(countryCode)) {
                        this.window.open(US_PRIVACY);
                    } else {
                        this.window.open(REST_OF_WORLD_PRIVACY);
                    }
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    openTerms$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openTerms),
                tap(({ countryCode }) => {
                    if (isCountryCodeNorthAmerica(countryCode)) {
                        this.window.open(US_TERMS);
                    } else {
                        this.window.open(REST_OF_WORLD_TERMS);
                    }
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );

    openCookieNotice$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(openCookieNotice),
                tap(({ countryCode }) => {
                    if (isCountryCodeNorthAmerica(countryCode)) {
                        this.window.open(US_COOKIE_NOTICE);
                    } else {
                        this.window.open(REST_OF_WORLD_COOKIE_NOTICE);
                    }
                }),
                filter(() => false),
            );
        },
        { dispatch: false },
    );
}
