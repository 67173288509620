import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ChatApiService } from '../services/chat-api.service';
import {
    getChatToken,
    getChatTokenFailure,
    getChatTokenSuccess,
    getLatestMessageDate,
    getLatestMessageDateFailure,
    getLatestMessageDateSuccess,
} from './chat-api.actions';

@Injectable()
export class ChatApiEffects {
    private readonly actions$ = inject(Actions);
    private readonly chatApiService = inject(ChatApiService);
    getChatToken$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getChatToken),
            switchMap(({ chatType }) => {
                return this.chatApiService.getUserToken().pipe(
                    map((token) => getChatTokenSuccess({ token, chatType })),
                    catchError((error: Error) => of(getChatTokenFailure({ error }))),
                );
            }),
        );
    });

    getLatestMessageDate$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(getLatestMessageDate),
            switchMap(({ chatType }) => {
                return this.chatApiService.getLatestMessage(chatType).pipe(
                    map((date) => getLatestMessageDateSuccess({ date, chatType })),
                    catchError((error: Error) => of(getLatestMessageDateFailure({ error }))),
                );
            }),
        );
    });
}
