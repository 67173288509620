<div class="star-rating">
    @for (star of numberOfStars; track $index) {
        <div
            class="star-rating__star {{ $index + 1 <= starValueSelected ? 'star-rating--star-selected' : '' }}"
            (click)="ratingChecked($index + 1)"
            [attr.data-test]="'star-rating-' + ($index + 1)"
        >
            <i class="fa-solid fa-star fa-xl"></i>
        </div>
    }
</div>
