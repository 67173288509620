import { InternalIterableDogFields } from '../../../models/third-party/iterable/iterable-fields.model';

export class InternalIterableDogFieldsMock {
    private defaultValue: InternalIterableDogFields = {
        dogName: 'dog-name',
        breedGroup: 'breed-group',
        breed: 'breed',
        ageInWeeks: 1,
        gender: 'gender',
        dateOfBirth: '',
        dateOfArrival: '',
        isRescuePup: true,
        hasArrived: true,
        isApproximateDateOfBirth: false,
    };

    constructor(overrides?: Partial<InternalIterableDogFields>) {
        Object.assign(this.defaultValue, overrides);
    }

    public get value(): InternalIterableDogFields {
        return this.defaultValue;
    }
}
