import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';

@Injectable()
export abstract class AuthenticationService {
    abstract readonly token$: Observable<string | null>;

    abstract get user(): User | undefined;

    abstract initialize(): void;

    abstract signUpWithEmailAndPassword(email: string, password: string): Observable<{ user: User }>;

    abstract loginWithEmailAndPassword(email: string, password: string): Observable<{ user: User }>;

    abstract loginWithGoogle(): Observable<any>;

    abstract loginWithApple(): Observable<any>;

    abstract logout(): Observable<void>;

    abstract isLoggedIn(): Observable<boolean>;

    abstract requestPasswordReset(email: string): Observable<void>;

    abstract getSignInProvider(): Observable<string>;
}
