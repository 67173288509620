import { Color } from '@shared/utils/typescript';
import { TopicName } from '@shared/content-api-interface';

export interface TopicState {
    topics: Topic[];
}

export interface Topic {
    entityId: string;
    title: TopicName;
    intro: string;
    imageUrl: string;
    color: Color;
    pinned: boolean;
    stepIds: string[];
}
